import { Fragment, useMemo, useState, useEffect } from "react";
import { NavLink, useMatch } from "react-router-dom";
import {
  useIsAdmin,
  useIsDocumentControl,
  useIsHR,
  useIsQHSE,
  useResolveRootRoles,
} from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import { Button, Dropdown, Nav, Accordion, useAccordionButton } from "react-bootstrap";
import { FormsIcon, OverviewIcon, FillAFormIcon, FormsIcon2, SystemFormIcon, RequestAndApprovalsIcon, FormAllIcon, FormStarredIcon, FormRecievedIcon, FormRejectedIcon, FormCompletedIcon, FormSentIcon, FormAwaitingIcon, FormAwaitingRecipientIcon, FilterCollapseIcon } from "../Icons";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import eventBus from "../../utils/EventBus";
import { useLocation, useNavigate } from "react-router-dom";
import { toLower } from "lodash";
import RequisitionLabels from "./RequisitionLabels";
import { styleHelper } from "../../utils/helpers";
import { useIsFetching } from "react-query";

function Sidebar({ items, SideBarToggle, activeKey, queryParams }) {
  return <div className="sticky-sider">
    <div className="menu">
      {/*  <span className="title">MENU</span> */}
      <div className="">
        {/*  {SideBarToggle ? SideBarToggle : null} */}
      </div>

      <Nav variant="pills" className="flex-column">
        {items?.map((item, index) => (
          <Fragment key={index}>
            <Nav.Item>
              <NavLink
                to={item?.to}
                className="links"
                title={item.name}
              /*    onClick={() => item.name === "Request and Approvals" && handleToggle(item.name)} */
              >
                {item.icon} <span className="link-name">{item.name}</span>
              </NavLink>
            </Nav.Item>
            {item?.subActions && (
              <Accordion activeKey={activeKey} className="sub">
                <Accordion.Collapse eventKey={item.name}>
                  <div>
                    {item?.subActions.map((el, i) => (
                      <Nav.Item key={i}>
                        <NavLink to={el.to} title={el.name} className={`sub-links ${queryParams?.is === toLower(el.name) ? 'active_sub' : ''}`}>
                          {el.icon} <span className="link-name">{el.name}</span>
                        </NavLink>
                      </Nav.Item>
                    ))}
                  </div>
                </Accordion.Collapse>
              </Accordion>
            )}
          </Fragment>
        ))}
      </Nav>
    </div>


    <RequisitionLabels />
  </div >
}

export default function RequisitionSideNav(props) {
  const { user: authUser } = useAuth();
  const [activeKey, setActiveKey] = useState("Request and Approvals")
  const isAdmin = useIsAdmin();
  const isDocumentControl = useIsDocumentControl();
  const isQhse = useIsQHSE();
  const isHR = useIsHR();
  const { getRootRoles } = useResolveRootRoles();
  const location = useLocation();
  const navigate = useNavigate()
  const isFetching = useIsFetching();


  const triggerFilter = ({ is }) => {
    // console.log(location.search)
    eventBus.dispatch("REQUISITION_FILTER_PARAMS", { is });
  }

  const items = useMemo(() => {
    const navItems = [
      ...(isAdmin || isDocumentControl || isQhse
        ? [
          {
            icon: <FormsIcon2 />,
            name: "Forms",
            to: "forms/company",
          },
        ]
        : []),
      {
        icon: <RequestAndApprovalsIcon />,
        name: "Request and Approvals",
        to: "request",
        subActions: [{
          icon: <FormAllIcon />,
          name: "All",
          action: () => triggerFilter({ is: 'all' }),
          to: "/requisition/request?is=all"
        }, {
          icon: <FormStarredIcon />,
          name: "Starred",
          action: () => triggerFilter({ is: 'starred' }),
          to: "/requisition/request?is=starred"
        }, {
          icon: <FormSentIcon />,
          name: "Sent",
          action: () => triggerFilter({ is: 'sent' }),
          to: "/requisition/request?is=sent&status=Approved & Sent"
        }, {
          icon: <FormRecievedIcon />,
          name: "Received",
          action: () => triggerFilter({ is: 'received' }),
          to: "/requisition/request?is=received&status=Approved & Sent"
        }, {
          icon: <FormRejectedIcon />,
          name: "Rejected",
          action: () => triggerFilter({ is: 'rejected' }),
          to: "/requisition/request?is=rejected&status=Disapproved"
        }, {
          icon: <FormCompletedIcon />,
          name: "Completed",
          action: () => triggerFilter({ is: 'completed' }),
          to: "/requisition/request?is=completed&status=Approved & Closed"
        }, {
          icon: <FormAwaitingRecipientIcon />,
          name: "Awaiting Recipient",
          action: () => triggerFilter({ is: 'awaiting recipient' }),
          to: "/requisition/request?is=awaiting recipient&status=Pending"
        }, {
          icon: <FormAwaitingIcon />,
          name: "Awaiting Action",
          action: () => triggerFilter({ is: 'awaiting action' }),
          to: "/requisition/request?is=awaiting action&status=Approved & Sent"
        },]
      },
      ...(isAdmin
        ? [
          {
            icon: <FormsIcon />,
            name: "Fund Request Approval",
            to:
              "fund-request-approval?page=1&limit=40&q=&status=&type=Fund+Request&sortByDate=dateModified&FormID=&showAll=true",
          },
          {
            icon: <SystemFormIcon />,
            name: "System Forms",
            to: "system-form",
          },
        ]
        : []),
      {
        icon: <FillAFormIcon />,
        name: "Fill a Form",
        to: "fill-a-form/company",
      },
    ];

    return getRootRoles({
      navItems,
    });
  }, [isAdmin, getRootRoles, isDocumentControl, isQhse,]);

  const handleToggle = (itemName) => {
    setActiveKey(prevKey => (prevKey === itemName ? null : itemName));
  };

  const activeItem = useMemo(() => {
    return items.find(item => item.to === location.pathname.replace('/requisition/', ''))
  }, [location?.pathname, items])


  return (
    styleHelper.isMobile ?
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="bg-white">{activeItem?.name || "Request and Approvals"}</Accordion.Header>
          <Accordion.Body>
            <Sidebar {...{ items, SideBarToggle: props?.SideBarToggle, activeKey, queryParams: props?.queryParams }} />
          </Accordion.Body>
        </Accordion.Item >
      </Accordion > : <Sidebar {...{ items, SideBarToggle: props?.SideBarToggle, activeKey, queryParams: props?.queryParams }} />
  );
}
