import { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MaintenanceIcon, ProcurementIcon, ProductionIcon, RequisitionNavControllerIcon, RequisitionNavControllerSmallIcon, SearchIconV2, ToolsIcon } from './Icons';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Dropdown, Form, Row, Col } from 'react-bootstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { useIsAdmin } from '../utils/hooks';
import { backendApis } from '../config';
import { useAuth } from '../hooks/useAuth';
import { Link } from 'react-router-dom';
import { toLower, truncate } from 'lodash';
import { fetchActionsUtil, styleHelper } from '../utils/helpers';
import { useQuery } from 'react-query';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

function RequisitionNavBar({
  /*   show = true, setShow */
  links,
  switchCompany, resolveName
}) {
  const [show, setShow] = useState(false);
  const isAdmin = useIsAdmin()
  const { user: authUser, backendUrl } = useAuth()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [childRoutes, setChildRoutes] = useState([])
  const [selectedRoute, setSelectedRoutes] = useState()
  const [searchString, setSearchString] = useState("")

  const { data, refetch } = useQuery(
    ["FETCH COMPANY"],
    () => fetchActionsUtil(`${backendUrl}/api/users/company`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  const renderIcon = (name) => {
    switch (name) {
      case "Vendor Management":
        return <ProcurementIcon />;
      case "Procurement":
        return <ProcurementIcon />;
      case "Production":
        return <ProductionIcon />;
      case "Tools":
        return <ToolsIcon />;
      case "Maintenance":
        return <MaintenanceIcon />;
      case "Reports":
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M6.20011 2.0018V2H12.7989C13.1301 2 13.4001 2.273 13.4001 2.5952V13.4048C13.4 13.5627 13.3371 13.7141 13.2254 13.8257C13.1137 13.9373 12.9622 14 12.8043 14H3.19591C3.11712 13.9995 3.03921 13.9834 2.96662 13.9527C2.89403 13.9221 2.8282 13.8774 2.77287 13.8213C2.71755 13.7652 2.67382 13.6987 2.64417 13.6257C2.61453 13.5527 2.59956 13.4746 2.60011 13.3958V5.6L6.20011 2.0018ZM4.29811 5.6H6.20011V3.698L4.29811 5.6ZM7.40011 3.2V6.2C7.40011 6.35913 7.3369 6.51174 7.22438 6.62426C7.11186 6.73679 6.95924 6.8 6.80011 6.8H3.80011V12.8H12.2001V3.2H7.40011Z" fill="#2F3037" />
        </svg>;
      case "Account Setup":
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M3.2002 13.9999C3.2002 12.7269 3.70591 11.506 4.60608 10.6058C5.50626 9.70562 6.72716 9.1999 8.0002 9.1999C9.27323 9.1999 10.4941 9.70562 11.3943 10.6058C12.2945 11.506 12.8002 12.7269 12.8002 13.9999H11.6002C11.6002 13.0451 11.2209 12.1294 10.5458 11.4543C9.87065 10.7792 8.95498 10.3999 8.0002 10.3999C7.04542 10.3999 6.12974 10.7792 5.45461 11.4543C4.77948 12.1294 4.4002 13.0451 4.4002 13.9999H3.2002ZM8.0002 8.5999C6.0112 8.5999 4.4002 6.9889 4.4002 4.9999C4.4002 3.0109 6.0112 1.3999 8.0002 1.3999C9.9892 1.3999 11.6002 3.0109 11.6002 4.9999C11.6002 6.9889 9.9892 8.5999 8.0002 8.5999ZM8.0002 7.3999C9.3262 7.3999 10.4002 6.3259 10.4002 4.9999C10.4002 3.6739 9.3262 2.5999 8.0002 2.5999C6.6742 2.5999 5.6002 3.6739 5.6002 4.9999C5.6002 6.3259 6.6742 7.3999 8.0002 7.3999Z" fill="#2F3037" />
        </svg>;
      case "Banking":
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M2 12.8H14V14H2V12.8ZM3.2 8H4.4V12.2H3.2V8ZM6.2 8H7.4V12.2H6.2V8ZM8.6 8H9.8V12.2H8.6V8ZM11.6 8H12.8V12.2H11.6V8ZM2 5L8 2L14 5V7.4H2V5ZM3.2 5.7416V6.2H12.8V5.7416L8 3.3416L3.2 5.7416ZM8 5.6C7.84087 5.6 7.68826 5.53679 7.57574 5.42426C7.46321 5.31174 7.4 5.15913 7.4 5C7.4 4.84087 7.46321 4.68826 7.57574 4.57574C7.68826 4.46321 7.84087 4.4 8 4.4C8.15913 4.4 8.31174 4.46321 8.42426 4.57574C8.53679 4.68826 8.6 4.84087 8.6 5C8.6 5.15913 8.53679 5.31174 8.42426 5.42426C8.31174 5.53679 8.15913 5.6 8 5.6Z" fill="#2F3037" />
        </svg>;
      case "Forms & Approvals":
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M12.8001 14H3.2001C3.04097 14 2.88836 13.9368 2.77583 13.8243C2.66331 13.7117 2.6001 13.5591 2.6001 13.4V2.6C2.6001 2.44087 2.66331 2.28826 2.77583 2.17574C2.88836 2.06321 3.04097 2 3.2001 2H12.8001C12.9592 2 13.1118 2.06321 13.2244 2.17574C13.3369 2.28826 13.4001 2.44087 13.4001 2.6V13.4C13.4001 13.5591 13.3369 13.7117 13.2244 13.8243C13.1118 13.9368 12.9592 14 12.8001 14ZM12.2001 12.8V3.2H3.8001V12.8H12.2001ZM5.6001 5H10.4001V6.2H5.6001V5ZM5.6001 7.4H10.4001V8.6H5.6001V7.4ZM5.6001 9.8H8.6001V11H5.6001V9.8Z" fill="#2F3037" />
        </svg>;
      default:
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M8 2.5L12.75 5.25V10.75L8 13.5L3.25 10.75V5.25L8 2.5ZM4.74694 5.53885L8.00005 7.4222L11.2531 5.53887L8 3.6555L4.74694 5.53885ZM4.25 6.40664V10.1735L7.50005 12.055V8.28825L4.25 6.40664ZM8.50005 12.055L11.75 10.1735V6.40668L8.50005 8.28825V12.055Z" fill="#2F3037" />
        </svg>;
    }
  };

  return (
    <>
      <Button variant="outline-dark" onClick={handleShow}>
        <RequisitionNavControllerIcon />
      </Button>

      <Offcanvas show={show} onHide={handleClose} style={{ zIndex: '1050', width: '37rem', background: "transparent", border: 'none' }}>
        <Row className='w-100 m-0 requisitionNavBar'>
          <Col className='border-right p-0 h-100 bg-white vh-100'>
            <Offcanvas.Header className='border-bottom' closeButton={styleHelper.isMobile}>
              <Offcanvas.Title class="d-flex align-items-center gap-3 fs-6 fw-semibold"><RequisitionNavControllerSmallIcon onClick={handleClose} /> Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-3'>
              <div>
                <div className="global-search-area w-100 mb-3">
                  <Form.Control
                    className=""
                    name="Description"
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    placeholder="Search"
                  />
                  <SearchIconV2 />
                </div>

                {links.filter(el => !searchString ? true : toLower(el.name).includes(searchString)).map((item, index) =>
                  <Fragment key={index}>
                    {!item.childRoutes ?
                      <Link to={item?.to ? item.to : ""} className='d-flex gap-2'>
                        <div className="icon">{renderIcon(item.name)}</div>

                        <span className='flex-grow-1'> {resolveName(item.name)}</span>

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path d="M6 12L10 8L6 4" stroke="#2F3037" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </Link> :

                      <Button variant='' className='w-100 d-flex gap-2 align-items-center justify-content-between text-start'
                        onClick={() => setSelectedRoutes(item)}
                     /*    onMouseEnter={() => setSelectedRoutes(item)} */>
                        <div className="icon">{renderIcon(item.name)}</div>

                        <span className='flex-grow-1'> {resolveName(item.name)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path d="M6 12L10 8L6 4" stroke="#2F3037" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </Button>}


                  </Fragment>)}


                {isAdmin ? (
                  <Dropdown alignRight drop="end">
                    <Dropdown.Toggle variant="white" bsPrefix={`company-toggle mt-4`}>

                      <div className='rounded overflow-hidden'>
                        <img
                          src={`${backendUrl}/images/company-logo.png`}
                          alt="Company Logo"
                          width={36}
                        />
                      </div>
                      <div className='flex-grow-1'>

                        <span class='d-block'>{truncate(data?.data?.company[0]?.CompName
                          ? data?.data?.company[0]?.CompName
                          : "", { length: 18 })}</span>
                        <span className='dim'>{truncate(data?.data?.company[0]?.Email ? data?.data?.company[0]?.Email : "", {
                          length: 18
                        })}</span>
                      </div>

                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M4 6L8 10L12 6" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>


                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                    >
                      {backendApis
                        .filter((el) => !el.viewOnly)
                        .map((el) => (
                          <Dropdown.Item
                            key={el.name}
                            as="button"
                            className={`${authUser?.company === el.name ? "active" : ""
                              }`}
                            onClick={() => switchCompany(el)}
                          >
                            {el.name}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}

              </div>
            </Offcanvas.Body>
          </Col>
          <Col className='p-0 vh-100 sub-links' style={{ background: '#F4F7FA', opacity: selectedRoute?.childRoutes ? "1" : "0" }} onClick={() => !selectedRoute?.childRoutes && handleClose()}>
            <Offcanvas.Header className='border-bottom'>
              <Offcanvas.Title class="d-flex align-items-center gap-3 fs-6 fw-semibold px-3">{selectedRoute?.name}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='p-0'>
              <div style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }} className='p-3'>
                {selectedRoute?.childRoutes.map((el, index) => <Link variant='' className='btn w-100 d-flex align-items-center justify-content-between text-start'
                  to={
                    !el?.to?.includes("http")
                      ? `${selectedRoute.to ? `/${selectedRoute.to}` : ""}/${el.to}`
                      : el.to
                  }
                  target={
                    el?.to?.includes("http") ? "_blank" : ""
                  }
                  onClick={() => setShow(false)}>
                  <span className='flex-grow-1'> {el.name}</span>  </Link>)}
              </div>
            </Offcanvas.Body>
          </Col>
        </Row>
      </Offcanvas>
    </>
  );
}

export default RequisitionNavBar;
