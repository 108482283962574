export const documentNumberingSystemItem = [
  {
    name: "System",
    to: "system",
  },
  {
    name: "Built Forms",
    to: "forms",
  },
];

export const salarySystemItem = [
  {
    name: "Unpaid Salary",
    to: "unpaid-salary",
  },
  {
    name: "Paid Salary",
    to: "paid-salary",
  },
  {
    name: "Unpaid Allowance",
    to: "unpaid-allowance",
  },
  {
    name: "Paid Allowance",
    to: "paid-allowance",
  },
  {
    name: "Deductions",
    to: "deductions",
  },
];
