import { Link, useLocation } from "react-router-dom";
import { appSettings } from "../../config";
import { useMemo } from "react";
import { useStoreState } from "easy-peasy";
import { Accordion } from "react-bootstrap";
import "../../assets/scss/custom-invoice-sidebar.scss";
import {
  CustomCustomerInvoiceIcon,
  CustomInvoiceLegdgerIcon,
  CustomInvoiceProfileIcon,
  CustomOrderIcon,
} from "../Icons";

export const CustomerCustomSidebar = () => {
  const location = useLocation();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const sideBars = useMemo(
    () => [
      ...(generalSettings?.customerCanEditPriceInCart
        ? [
            {
              name: "Quotes",
              link: `${appSettings.storeBaseUrl}/account/quotes`,
            },
          ]
        : []),
      // {
      //   name: "Orders",
      //   link: `${appSettings.storeBaseUrl}/account/orders`,
      // },
      // {
      //   name: "Invoices",
      //   link: `${appSettings.storeBaseUrl}/account/invoices`,
      // },
      {
        name: "Payments",
        link: `${appSettings.storeBaseUrl}/account/payments`,
        icon: <CustomInvoiceLegdgerIcon />,
      },
      {
        name: "Ledger",
        link: `${appSettings.storeBaseUrl}/account/ledger`,

        icon: <CustomInvoiceLegdgerIcon />,
      },
      {
        name: "Profile",
        link: `${appSettings.storeBaseUrl}/account/profile`,
        icon: <CustomInvoiceProfileIcon />,
      },
    ],
    [generalSettings?.customerCanEditPriceInCart]
  );
  const sideBarInvoiceNav = useMemo(
    () => [
      ...(generalSettings?.customerCanEditPriceInCart
        ? [
            {
              name: "Quotes",
              link: `${appSettings.storeBaseUrl}/account/quotes`,
            },
          ]
        : []),

      {
        name: "Paid",
        link: `${appSettings.storeBaseUrl}/account/paid-invoice`,
      },
      {
        name: "Overdue",
        link: `${
          appSettings.storeBaseUrl
        }/account/overdue-invoice`,
      },
      {
        name: "UnPaid",
        link: `${
          appSettings.storeBaseUrl
        }/account/unpaid-invoice`,
      },
      {
        name: "Credited",
        link: `${
          appSettings.storeBaseUrl
        }/account/credited-invoice`,
      },
    ],
    [generalSettings?.customerCanEditPriceInCart]
  );

  return (
    <div className=" mx-2">
      {/* <div className="">
        <div className="text-center py-3">
          <h4>My Account</h4>
        </div>
      </div> */}

      <div
        className={`p-3 py-4 cursor btn-items ${
          location.pathname.startsWith(
            `${appSettings.storeBaseUrl}/account/orders`
          )
            ? "active-bg"
            : ""
        }`}
      >
        <Link to={`${appSettings.storeBaseUrl}/account/orders`}>
          <p className=" d-flex gap-2 align-items-center">
            <CustomOrderIcon />
            <p> Orders</p>
          </p>
        </Link>
      </div>

      <Accordion
        defaultActiveKey="0"
        className="custom-accordion border-0"
        flush
      >
        <Accordion.Item eventKey="1" className=" border-0">
          <Accordion.Header className="custom-header fw-normal">
            <div className="custom-header-content">
              <p
                className=" align-items-center
              d-flex   gap-2"
              >
                <CustomCustomerInvoiceIcon /> <p>My Invoices</p>
              </p>
            </div>
          </Accordion.Header>
          <Accordion.Body className="custom-body">
            <div className="  accordeion-item-body">
              {sideBarInvoiceNav?.map((nav, i) => (
                <div
                  key={i}
                  className={`custom-body-item ${
                    window.location.href.includes(nav.link) ? "active-bg" : ""
                  }`}
                >
                  <Link to={nav.link}>
                    <p>
                      <p>{nav.name}</p>
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {sideBars.map((el, i) => (
        <div
          key={i}
          className={`p-3 py-4 btn-items cursor ${
            location.pathname.startsWith(`${el.link}`) ? "active-bg" : ""
          }`}
        >
          <Link to={el.link}>
            <p className=" d-flex gap-2 align-items-center">
              {el?.icon}
              <p>{el.name}</p>
            </p>
          </Link>
        </div>
      ))}
    </div>
  );
};
