import Table from "react-bootstrap/Table";
import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { indexOf } from "lodash";

function UserDevices() {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeItem, setActiveItem] = useState("1")
  const { backendUrl, user, deviceID } = useAuth();

  useEffect(() => {
    try {
      setLoading(true);
      // Fetching user devices data from API
      fetch(`${backendUrl}/api/users/logged-in-devices/${user.Staff_ID}`)
        .then((response) => response.json())
        .then((result) => {
          if (!result.success) throw new Error(result.message)
          setDevices(result.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error.message)
      toast.error(error.message)
      setLoading(false)
    }
  }, []);

  const handleDelete = async (deviceId) => {
    try {
      setLoading(true)
      setActiveItem("0")
      // Delete device from API
      fetch(`${backendUrl}/api/users/logged-in-devices/${user.Staff_ID}/${deviceId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`
        }
      })
        .then((response) => response.json())
        .then((result) => {
          if (!result.success) throw new Error(result.message)
          setDevices(devices.filter((device) => device.deviceId !== deviceId))

          toast.success("Device deleted successfully")
          setLoading(false)
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Failed to delete device")
          setLoading(false)
        });
    } catch (error) {
      console.log(error.message)
      toast.error(error.message)
      setLoading(false)

    }
  }


  console.log("user: ", user)
  function DevicesTable() {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{" "}</th>
            <th>Device Name</th>
            <th>Last Login</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device, index) => {
            let lastLogin = device.Last_Login.split(".")[0].replace("T", " / ");
            return (

              <tr key={index}>
                <td>{index + 1}</td>
                <td>{device.deviceName} {device.deviceId === deviceID && <span className="currentDevice">Current Device</span>}</td>
                <td>{lastLogin}</td>
                <td>
                  <button onClick={() => handleDelete(device.deviceId)} className="btn delete btn-sm  ml-2">Delete</button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    );
  }

  function DeviceAccordion() {
    return (
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey={activeItem}>
          <Accordion.Header>Manage Devices</Accordion.Header>
          <Accordion.Body>
            <DevicesTable />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }
  return (


    <div className="devicesList">
      {loading && <div className="loading text-center">Loading...</div>}
      <DeviceAccordion />
    </div>
  )

}

export default UserDevices;
