import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./../../assets/scss/scoped/login.scoped.scss";
import "./../../assets/scss/fileModal.scss";

// import { useBackendUrl } from "../utils/hooks";


const OtpModal = ({
  open,
  setOpen,
  submitOTP,
  confirmLoading,
  title,
  setLoginAction,
  userEmail,
  handleResendOTP
}) => {
  // const [confirmLoading, setConfirmLoading] = useState(false);
  const [inputText, setInputText] = useState({});
  const [canClose, setCanClose] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(true);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = async () => {
    await submitOTP({ otp: inputText, rememberDevice: rememberDevice });
    // setConfirmLoading(false)
  };



  function obfuscateEmail(email) {
    const [localPart, domain] = email.split("@");
    const lengthToObfuscate = Math.min(4, Math.max(1, Math.floor(localPart.length / 3)));
    const obfuscatedLocalPart =
      localPart.slice(0, localPart.length - lengthToObfuscate) +
      "*".repeat(lengthToObfuscate);

    return ` ${obfuscatedLocalPart}@${domain} `;
  }

  const handleCancel = () => {
    if (inputText.length === 6 || canClose) {
      setOpen(false);
      setInputText("");
      setLoginAction("login");
      return;
    }
    toast.warning("You haven't entered your OTP");
    setCanClose(true);
  };

  return (
    <Modal
      // {...props}
      show={open}
      onHide={handleCancel}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ marginBottom: "1rem" }}>
          <p style={{ marginBottom: "1rem" }}>
            An OTP have been sent to <b>{obfuscateEmail(userEmail)}</b>.
          </p>
          <Form.Control
            placeholder="Your OTP"
            onChange={(e) => setInputText(e.target.value)}
          />
          <br />
          {title !== "Two Factor Authentication" && (
            <Form.Check
              defaultChecked={rememberDevice}
              onClick={(e) => setRememberDevice(e.target.checked)}
              type={"checkbox"}
              id={`remember-checkbox`}
              label={`Remember this device`}
            />
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={confirmLoading} onClick={handleOk}>
          {confirmLoading ? "Please Wait..." : "Submit"}
        </Button>
        <ResendOTPButton onResend={handleResendOTP} />
      </Modal.Footer>
    </Modal>
  );
};
export default OtpModal;
// 385 + 175

function ResendOTPButton({ onResend }) {
  const [timer, setTimer] = useState(60); // 1-minute countdown
  const [isDisabled, setIsDisabled] = useState(true);

  let interval = null;
  useEffect(() => {

    if (isDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsDisabled(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleResend = async () => {
    try {

      setIsDisabled(true);
      const result = await onResend()
      if (result) {
        setTimer(60);
      } // only count down when OTP is successfully sent
      else {
        setIsDisabled(false);
        clearInterval(interval)
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button
      variant="outline-primary"
      onClick={handleResend}
      disabled={isDisabled}
      style={{
        cursor: isDisabled ? "not-allowed" : "pointer",
      }}
    >
      {isDisabled ? `Resend OTP in ${timer}s` : "Resend OTP"}
    </Button>
  );
}
