import { useState } from 'react';
import { CloseButton, Form, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../hooks/useAuth';
import { useQuery } from 'react-query';
import Avatar from './Avatar';
import CachedIcon from 'mdi-react/CachedIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import queryString from "query-string";
import { SearchIconV2 } from '../Icons';
import useDebounce from '../../utils/hooks';
import { isEmpty } from 'lodash';
import NoTableItem from './NoTableItem';

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function StaffInviteModal({
  show = true, setShow, isMutiple = true, handleSelectionCompleted, handleSelectedEmail
}) {
  const { backendUrl, user: authUser } = useAuth()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 1000,
    q: "",
  });

  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getUsersInSystem = async (debouncedqueryParams) => {
    // Personal user 1
    const apiUrl = authUser.account_type === 1 ? `${backendUrl}/api/users/friends` : `${backendUrl}/api/users`

    let response = await fetch(`${apiUrl}?${queryString.stringify(debouncedqueryParams)}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    // console.log(data)
    data.allUsersInSystem = [

      ...data.staff.map((el) => ({
        ...el,
        label: el.Name,
        value: el.Staff_ID,
      })),
    ];

    return data;
  };
  const {
    data: { allUsersInSystem } = { allUsersInSystem: [] },
    isFetching: isFetchingUsers, refetch
  } = useQuery(["GET_USERS_IN_SYSTEM_FOR_INVITE", debouncedqueryParams], () => getUsersInSystem(debouncedqueryParams), {});

  const formik = useFormik({
    initialValues: {
      selectedUsers: [], // Array to store selected user objects
    },
    onSubmit: (values) => {
      console.log('Selected Users:', values.selectedUsers);
      handleSelectionCompleted(values.selectedUsers)
      setShow(false)
    },
  });

  const handleRowClick = (user) => {
    const currentSelection = formik.values.selectedUsers;
    const isSelected = currentSelection.some((item) => item.Staff_ID === user.Staff_ID);

    if (isSelected) {
      // Remove from selection
      formik.setFieldValue(
        'selectedUsers',
        currentSelection.filter((item) => item.Staff_ID !== user.Staff_ID)
      );
    } else {
      // Add to selection
      if (!isMutiple) {
        formik.setFieldValue('selectedUsers', [user]);
        return
      }
      formik.setFieldValue('selectedUsers', [...currentSelection, user]);
    }
  };

  const isSelected = (user) =>
    formik.values.selectedUsers.some((item) => item.Staff_ID === user.Staff_ID);

  const addMail = () => {
    handleSelectedEmail(queryParams.q)
    setShow(false)
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose} scrollable animation={false} dialogClassName="modal-h-fullscreen">
        <Modal.Header className='align-item-start'>
          <div className='w-100 position-relative'>
            <CloseButton className="position-absolute top-0 end-0" onClick={handleClose} />
            <Modal.Title className='h5'>{isMutiple ? "Add Signatories" : "Add Signatory"}
              <Button variant='' onClick={() => refetch()}><CachedIcon /></Button> </Modal.Title>

            <div className='my-3'>
              <div className="global-search-area">
                <SearchIconV2 />
                <input
                  className="form-control search-input"
                  name='q'
                  value={queryParams.q}
                  onChange={(e) =>
                    setQueryParams({
                      ...queryParams,
                      page: 1,
                      [e.target.name]: e.target.value,
                    })
                  }
                  placeholder="Search or enter Email..."
                  autoFocus
                  autoComplete="off"
                  type={"text"}
                />
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              {!validateEmail(queryParams.q) ? (
                <>
                  <Table striped responsive hover borderless>
                    <thead>
                      <tr>
                        <th scope='col' className='col-1'> </th>
                        <th scope='col' className='col-1'> </th>
                        <th scope='col' > </th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray
                        name="selectedUsers"
                        render={() =>
                          allUsersInSystem.map((el) => (
                            <tr
                              key={el.Staff_ID}
                              className="align-middle"
                              onClick={() => handleRowClick(el)}
                              style={{ cursor: 'pointer' }}
                            >
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  checked={isSelected(el)}
                                  onChange={() => handleRowClick(el)}
                                  onClick={(e) => e.stopPropagation()} // Prevent the row click from firing when the checkbox is clicked
                                />
                              </td>
                              <td>
                                <Avatar
                                  key={el.Staff_ID}
                                  name={el?.Name}
                                  userId={el?.Staff_ID}
                                  style={{ width: '2rem', height: '2rem' }}
                                />
                              </td>
                              <td>
                                <div>
                                  <p className="mb-0">{el.Name}</p>
                                  <p className="text-light fs-6 m-0">{el.username}</p>
                                </div>
                              </td>
                            </tr>
                          ))
                        }
                      />
                    </tbody>
                  </Table>
                  {isEmpty(allUsersInSystem) && !isFetchingUsers ? <div><NoTableItem /> </div> : null}
                </>
              ) : (
                <div className="d-flex flex-column align-items-center gap-4 my-4">
                  <div className='text-center fs-6'>
                    <span>
                      Do you want to add this email address?
                    </span>
                    <br />
                    "<em className="text-primary">{queryParams.q}</em>"
                  </div>
                  <Button
                    variant='primary'
                    onClick={() => addMail()}
                  >
                    Yes please
                  </Button>
                </div>
              )}

            </Form>
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={formik.submitForm}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default StaffInviteModal;
