import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { fetchActionsUtil, paginationOptions } from "../../utils/helpers";
import Select from "react-select";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { useStoreState } from "easy-peasy";

export default function ItemsTable({
  handleSelectedItem,
  hideItemsNotInStock = false,
  model = "Items",
  type = "",
  getItemFrom = "Items",
  withVendorUnitCost = false,
  Vendor_ID = "",
  defaultSort,
  ItemStatus,
  serialNumbers,
  mustFind = [],
  searchString = "",
  hideSearchBar = false,
}) {
  const generalSettings = useStoreState((state) => state?.generalSettings);

  const { backendUrl } = useAuth();
  const [itemsOptions] = useState([
    {
      label: "Stock Inventory",
      value: "Items",
    },
    {
      label: "Equipment",
      value: "Equipment",
    },
    {
      label: "Consumables",
      value: "Consumables",
    },
  ]);

  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: searchString,
    model,
    getItemFrom,
    withVendorUnitCost,
    Vendor_ID,
    ItemStatus: generalSettings?.itemStatusSettings ? ItemStatus : null,
    ...(defaultSort ? { sort: defaultSort } : {}),
    mustFind: mustFind.map((el) => el).join(","),
    isRemoved: "Not Sold",
  });

  const debouncedqueryParams = useDebounce(queryParams, 500);
  const debouncedSearchString = useDebounce(searchString, 500);
  // const [searchString, setSearchString] = useState("");
  // const debouncedSearchString = useDebounce(searchString, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, q: debouncedSearchString });
  }, [debouncedSearchString]);

  const getItems = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items?${queryString.stringify(debouncedqueryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [] } } = useQuery(
    [queryActions.GET_ITEMS, debouncedqueryParams],
    () =>
      serialNumbers
        ? fetchActionsUtil(
            `${backendUrl}/api/items/all-serial-item?&${queryString.stringify(
              queryParams
            )}`
          )
        : getItems(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  return (
    <div className="items-table-area rounded">
      <div className="search-area m-3 gap-3">
        {!hideSearchBar && (
          <>
            <MagnifyIcon />
            <input
              className="form-control search-input"
              name="q"
              value={queryParams.q}
              onChange={(e) =>
                setQueryParams({
                  ...queryParams,
                  page: 1,
                  [e.target.name]: e.target.value,
                })
              }
              placeholder="Search item..."
              autoFocus
              autoComplete="off"
              type={"text"}
            />
          </>
        )}
        {!isEmpty(type) && (
          <div className={hideSearchBar ? "w-100" : "w-50"}>
            <Select
              placeholder="Select..."
              options={itemsOptions}
              value={itemsOptions.find(
                (el) => el.value === queryParams.getItemFrom
              )}
              onChange={(selection) =>
                setQueryParams({
                  ...queryParams,
                  getItemFrom: selection?.value,
                })
              }
            />
          </div>
        )}
      </div>

      <div className="content">
        {serialNumbers ? (
          <Table borderless hover striped className="product-table">
            <thead className="sticky text-nowrap">
              <tr>
                <th scope="col">Serial Number</th>
                <th scope="col">Item Code</th>
                <th scope="col">Item Name</th>
                <th scope="col">Unit Cost</th>
                {/* <th scope="col">Unit Price</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.mustFindItems &&
                data?.mustFindItems
                  .filter((el) =>
                    hideItemsNotInStock
                      ? Number(el?.Items_Serial?.Quantity) > 0
                      : el
                  )
                  .map((el, index) => (
                    <tr
                      className="p-cursor correct"
                      key={index}
                      onClick={() =>
                        handleSelectedItem && handleSelectedItem(el)
                      }
                    >
                      <td>{el?.serialNum}</td>
                      <td>{el?.Items_Serial?.Bar_Code}</td>
                      <td title={el?.Items_Serial?.Quantity}>
                        {el?.Items_Serial?.Item_Name}
                      </td>
                      <td>
                        {currency(el?.Batch_Serial?.UnitCost, {
                          precision: 2,
                          symbol: "",
                        }).format()}
                      </td>
                    </tr>
                  ))}

              {data?.stockCounts &&
                data?.stockCounts
                  .filter((el) =>
                    hideItemsNotInStock
                      ? Number(el?.Items_Serial?.Quantity) > 0
                      : el
                  )
                  .map((el, index) => (
                    <tr
                      className="p-cursor"
                      key={index}
                      onClick={() =>
                        handleSelectedItem && handleSelectedItem(el)
                      }
                    >
                      <td>{el?.serialNum}</td>
                      <td>{el?.Items_Serial?.Bar_Code}</td>
                      <td title={el?.Items_Serial?.Quantity}>
                        {el?.Items_Serial?.Item_Name}
                      </td>
                      <td>
                        {currency(el?.Batch_Serial?.UnitCost, {
                          precision: 2,
                          symbol: "",
                        }).format()}
                      </td>
                      {/* <td>
                        {currency(el?.Items_Serial?.UnitPrice, {
                          precision: 2,
                          symbol: "",
                        }).format()}
                      </td> */}
                    </tr>
                  ))}
            </tbody>
          </Table>
        ) : (
          <Table borderless hover striped className="product-table">
            <thead className="sticky text-nowrap">
              <tr>
                <th scope="col">Item Code</th>
                <th scope="col">Item Name</th>
                <th scope="col">Unit Cost</th>
                <th scope="col">Unit Price</th>
              </tr>
            </thead>
            <tbody>
              {data.items &&
                data.items
                  .filter((el) =>
                    hideItemsNotInStock ? Number(el.Quantity) > 0 : el
                  )
                  .map((el, index) => (
                    <tr
                      className="p-cursor"
                      key={index}
                      onClick={() =>
                        handleSelectedItem &&
                        handleSelectedItem({
                          ...el,
                          TypeWarehouse: queryParams.getItemFrom,
                        })
                      }
                    >
                      <td>{el.Bar_Code}</td>
                      <td title={el?.Quantity}>{el.Item_Name}</td>
                      <td>
                        {currency(el.UnitCost, {
                          precision: 2,
                          symbol: "",
                        }).format()}
                      </td>
                      <td>
                        {currency(el.UnitPrice, {
                          precision: 2,
                          symbol: "",
                        }).format()}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        )}
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </div>
  );
}
