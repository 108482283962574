import { Table, Button, Form, Modal, Row, Dropdown } from "react-bootstrap";
import {
  CalendarIcon,
  DeleteIcon,
  EditIcon,
  GearIcon,
  PrintIcon,
} from "./../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { cacheTimes, queryActions, reportActions } from "../../utils/reactQueryActions";
import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { services } from "../../config";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import { defaultInvoiceAccountPosting, formatDate, paginationOptions, scrollToTop } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import PageHeader from "../PageHeader";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { toast } from "react-toastify";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty } from "lodash";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { UnAuthorized } from "../utils/UnAuthorized";
import { fetchActionsUtil } from "../../utils/helpers";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import NoTableItem from "../utils/NoTableItem";
import ChartOfAccountPicker from "../utils/ChartOfAccountPicker";
import { StaffSelectorMultiple } from "../GeneralSettingsModal";
import Avatar from "../utils/Avatar";

const initialValues = {
  title: "",
  oldtitle: "",
};
const schema = yup.object().shape({
  title: yup.string().required(),
});

function CreateInvoiceAccountsMappingModal({
  showCreateInvoiceAccountsMappingModal,
  setShowCreateInvoiceAccountsMappingModal,
  refetch,
}) {
  const backendUrl = useBackendUrl();

  const schema = yup.object().shape({
    title: yup.string().required(),
  });

  const { user: authUser } = useAuth();

  const formik = useFormik({
    initialValues: {
      title: "",
      UserName: authUser.Name,
      accountsToPost: defaultInvoiceAccountPosting.map(el => ({ ...el, readOnly: false })),
      Staffs: [
        {
          department: "",
          jobGrade: "",
          staff: "",
          staffName: "",
        },
        /*   {
            department: "",
            jobGrade: "",
            staff: "",
            staffName: "",
          }, */
      ],
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      createInvoiceAccountsMappingMutation.mutate({ ...values });
    },
  });

  const createInvoiceAccountsMapping = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/accounts/invoice-mapping/create`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createInvoiceAccountsMappingMutation = useMutation(
    (payload) => createInvoiceAccountsMapping(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        formik.resetForm();
        refetch();
        setShowCreateInvoiceAccountsMappingModal(false);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const getDepartmentsForForm = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };


  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
    isSuccess,
    refetch: refetchStaffs,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartmentsForForm(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  return (
    <Modal
      show={showCreateInvoiceAccountsMappingModal}
      onHide={() => setShowCreateInvoiceAccountsMappingModal(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Create Revenue Mapping</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Revenue Title</Form.Label>
              <Form.Control
                name="title"
                placeholder="Revenue Title"
                type="text"
                value={formik.values.title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.title && !!formik.errors.title}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <ChartOfAccountPicker accountsToPost={formik.values.accountsToPost} setAccountsToPost={(data) => {
                formik.setFieldValue('accountsToPost', data);
              }} readOnly={false}
                defaultActiveKey="0" /></Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Select users</Form.Label>
              <FieldArray
                name="Staffs"
                render={(arrayHelpers) => (
                  <>
                    <div className="row">
                      {formik.values.Staffs?.map((el, index) => (
                        <>
                          <StaffSelectorMultiple
                            data={data}
                            name="Staffs"
                            index={index}
                            formik={formik}
                            arrayHelpers={arrayHelpers}
                            hasLimit={false}
                            disabled={false}
                            isRequester={false}
                          />
                        </>
                      ))}
                    </div>
                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-light-blue text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            department: "",
                            jobGrade: "",
                            staff: "",
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />
            </Form.Group>

            <Button
              disabled={createInvoiceAccountsMappingMutation.isLoading}
              variant="primary"
              className="w-100 p-2"
              type="submit"
            >
              {createInvoiceAccountsMappingMutation.isLoading ? "Please wait…" : "Create Now"}
            </Button>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}

export default function InvoiceAccountsMapping() {
  const { backendUrl, token } = useAuth();
  const isAdmin = useIsAdmin();
  const [showCreateInvoiceAccountsMappingModal, setShowCreateInvoiceAccountsMappingModal] = useState(
    false
  );
  const [showEditInvoiceAccountsMappingModal, setShowEditInvoiceAccountsMappingModal] = useState(false);
  const [selectedtitle, setSelectedtitle] = useState({});

  useScrollTop();
  const initialFilterParams = { q: "" };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const getInvoiceAccountsMapping = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/accounts/invoice-mapping?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const {
    data = { InvoiceAccountsMapping: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["GET_BUISINESS_InvoiceAccountsMapping", queryParams],
    () => getInvoiceAccountsMapping(queryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const deleteInvoiceAccountsMapping = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/accounts/invoice-mapping/${payload.id}`,
      {
        method: "DELETE",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteInvoiceAccountsMappingMutation = useMutation(
    (payload) => deleteInvoiceAccountsMapping(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
      page: 1,
    });
  };

  const handleEdit = async (title) => {
    setSelectedtitle(null);
    if (await LoginDialog()) {
      setSelectedtitle(title);
      setShowEditInvoiceAccountsMappingModal(true);
    }
  };

  const handleDelete = async (title) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Type",
          description: "Are you sure you want to delete this Type",
        })
      ) {
        deleteInvoiceAccountsMappingMutation.mutate({ ...title });
      }
    }
  };

  return (
    <>
      {isAdmin ? (
        <main className="bg-white">
          {" "}
          <header className="p-4">
            <div className="search-area d-flex flex-wrap justify-content-between gap-3 w-100">
              <div className="d-flex flex-wrap gap-3 col-md-4">
                <div className="global-search-area w-75">
                  <MagnifyIcon />
                  <Form.Control
                    className=""
                    name="q"
                    value={filterParams.q}
                    onChange={(e) => handleSearchQueryChange(e)}
                    placeholder="Search Mapping..."
                  />
                </div>
                <Button
                  onClick={() => refetch()}
                  variant=""
                  className="bg-light-blue border-0 text-primary"
                >
                  <CachedIcon />
                </Button>
              </div>

              <div className="actions mr-5">
                <Button
                  onClick={() => setShowCreateInvoiceAccountsMappingModal(true)}
                  className="px-3"
                >
                  + Create New
                </Button>
              </div>
            </div>
          </header>
          <div className="content">
            <div className="px-md-4">
              <Table responsive borderless striped className="product-table">
                <thead>
                  <tr>
                    <th />
                    <th>S/N</th>
                    <th>Revenue Title</th>
                    <th>Accounts</th>
                    <th>Staff</th>
                    <th>Created By</th>
                    <th>Date Created</th>
                  </tr>
                </thead>
                <tbody>
                  {data.InvoiceAccountsMapping?.map((el, index) => (
                    <tr key={index}>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant=""
                            className="bg-white border-0"
                            bsPrefix="print more"
                          >
                            <DotsHorizontalIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            <Dropdown.Item
                              as="button"
                              onClick={() => handleEdit(el)}
                            >
                              <EditIcon /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => handleDelete(el)}
                            >
                              <DeleteIcon /> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>

                      <td>{index + 1}</td>
                      <td>{el.title}</td>
                      <td>{el.accountsToPost ? JSON.parse(el.accountsToPost).map(el => el?.userSelectedAccount ? el.userSelectedAccount.Description
                        : el.Description).join(", \n ") : '...'}</td>
                      <td>
                        <div className="d-flex gap-2 align-items-center">
                          <div className="avatar-list d-flex">{el.Staffs ? JSON.parse(el.Staffs).map((el, index) => <Avatar
                            key={index}
                            name={el?.staffName ? el.staffName : ""}
                            userId={el?.staff}
                            style={{
                              //  zIndex: arr.length - 1,
                              fontSize: "0.75rem",
                            }}
                          />) : '...'}</div> </div> </td>
                      <td>{el.UserName}</td>
                      <td>{el?.createdAt ? formatDate(el.createdAt) : '...'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.InvoiceAccountsMapping) ? (
                <div>
                  <NoTableItem queryParams={queryParams} />
                </div>
              ) : null}
            </div>
          </div>
          {showCreateInvoiceAccountsMappingModal && (
            <CreateInvoiceAccountsMappingModal
              showCreateInvoiceAccountsMappingModal={showCreateInvoiceAccountsMappingModal}
              setShowCreateInvoiceAccountsMappingModal={setShowCreateInvoiceAccountsMappingModal}
              refetch={refetch}
            />
          )}
          {!isEmpty(selectedtitle) && (
            <EditInvoiceAccountsMappingModal
              showEditInvoiceAccountsMappingModal={showEditInvoiceAccountsMappingModal}
              setShowEditInvoiceAccountsMappingModal={setShowEditInvoiceAccountsMappingModal}
              selectedtitle={selectedtitle}
              setSelectedtitle={setSelectedtitle}
              refetch={refetch}
            />
          )}
        </main>
      ) : (
        <UnAuthorized />
      )}
    </>
  );
}

function EditInvoiceAccountsMappingModal({
  showEditInvoiceAccountsMappingModal,
  setShowEditInvoiceAccountsMappingModal,
  selectedtitle = {},
  refetch,
  setSelectedtitle,
}) {
  const backendUrl = useBackendUrl();

  const formik = useFormik({
    initialValues: {
      title: selectedtitle.title || "",
      UserName: selectedtitle.UserName || "",
      accountsToPost: selectedtitle.accountsToPost ? JSON.parse(selectedtitle.accountsToPost) : defaultInvoiceAccountPosting,
      Staffs: selectedtitle.Staffs ? JSON.parse(selectedtitle.Staffs) : [],
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      editInvoiceAccountsMappingMutation.mutate({
        ...values,
      });
    },
  });

  useEffectOnce(() => {
    formik.setValues({
      title: selectedtitle.title,
      oldtitle: selectedtitle.title,
      UserName: selectedtitle.UserName,
      accountsToPost: selectedtitle.accountsToPost ? JSON.parse(selectedtitle.accountsToPost) : defaultInvoiceAccountPosting,
      Staffs: selectedtitle.Staffs ? JSON.parse(selectedtitle.Staffs) : [],
    });
  });

  const editInvoiceAccountsMapping = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/accounts/invoice-mapping/${selectedtitle.id}`,
      {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const editInvoiceAccountsMappingMutation = useMutation(
    (payload) => editInvoiceAccountsMapping(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        formik.resetForm();
        refetch();
        setShowEditInvoiceAccountsMappingModal(false);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const getDepartmentsForForm = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };


  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    refetch: refetchStaffs,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartmentsForForm(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  return (
    <Modal
      show={showEditInvoiceAccountsMappingModal}
      onHide={() => {
        setSelectedtitle(null);
        setShowEditInvoiceAccountsMappingModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Edit {formik.values.oldtitle}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Revenue Title</Form.Label>
              <Form.Control
                name="title"
                placeholder="Revenue Title"
                type="text"
                value={formik.values.title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.title && !!formik.errors.title}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <ChartOfAccountPicker accountsToPost={formik.values.accountsToPost} setAccountsToPost={(data) => {
                formik.setFieldValue('accountsToPost', data);
              }} readOnly={false}
                defaultActiveKey="0" /></Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Select users</Form.Label>
              <FieldArray
                name="Staffs"
                render={(arrayHelpers) => (
                  <>
                    <div className="row">
                      {formik.values.Staffs?.map((el, index) => (
                        <>
                          <StaffSelectorMultiple
                            data={data}
                            name="Staffs"
                            index={index}
                            formik={formik}
                            arrayHelpers={arrayHelpers}
                            hasLimit={false}
                            disabled={false}
                            isRequester={false}
                          />
                        </>
                      ))}
                    </div>
                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-light-blue text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            department: "",
                            jobGrade: "",
                            staff: "",
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />
            </Form.Group>

            <Button
              disabled={editInvoiceAccountsMappingMutation.isLoading}
              variant="primary"
              className="w-100 p-2"
              type="submit"
            >
              {editInvoiceAccountsMappingMutation.isLoading ? "Please wait…" : "Save Changes"}
            </Button>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}
