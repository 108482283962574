import { useFormik } from "formik";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import "../../assets/scss/RolesPriviledgesModal.scss";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";
import upIcon from "./assets/up.png";
import downIcon from "./assets/down.png";

import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
} from "../../utils/hooks";
import { useFetchUsers } from "../../hooks/useFetchUsers";
// import Avatar from "./utils/Avatar";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { GearIcon } from "../Icons";
import { useMutation, useQuery } from "react-query";
// import { initialGeneralSettings } from "../utils/helpers";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import roles from "../roles.json";
import Cookies from "universal-cookie";
import { cloneDeep } from "lodash";
import CompanySettings from "./CompanySettings";
import { backendApis } from "../../config";
import { Next } from "react-bootstrap/esm/PageItem";

export function RootRolesPriviledges({ submitForm, next }) {
  const { backendUrl } = useAuth();
  // const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const rootRoles = useStoreState((state) => state.rootRoles);

  const [priveledges, setPriveledges] = useState(rootRoles || []);
  const [isLoading, setIsLoading] = useState(false);
  const [userRoles] = useState(roles.roles);
  const setRootRoles = useStoreActions((actions) => actions.setRootRoles);

  const [selected, setSelected] = useState({
    level1: null,
    level2: null,
    level3: null,
  });

  useEffect(() => {
    setPriveledges((prev) => {
      return cloneDeep(prev).map((ele) => ({ ...ele, checked: false }));
    });
  }, []);

  const handleChange = (checked, role) => {
    let roles = [...priveledges];
    checked = !checked;

    if (role.priviledges !== undefined) {
      role.priviledges.map((priviledge) => {
        roles = [
          ...roles.filter(
            (el) =>
              !Boolean(
                el.name == priviledge.name && el.parent == priviledge.parent
              )
          ),
          {
            name: priviledge.name,
            checked: checked,
            parent: role.name,
          },
        ];

        if (priviledge.actions !== undefined) {
          priviledge.actions.map((action) => {
            roles = [
              ...roles.filter(
                (el) =>
                  !Boolean(
                    el.name === action.name && el.parent == action.parent
                  )
              ),
              {
                name: action.name,
                checked: checked,
                parent: priviledge.name,
              },
            ];

            if (action.subActions !== undefined) {
              action.subActions.map((subAction) => {
                roles = [
                  ...roles.filter(
                    (el) =>
                      !Boolean(
                        el.name === subAction.name &&
                          el.parent == subAction.parent
                      )
                  ),
                  {
                    name: subAction.name,
                    checked: checked,
                    parent: action.name,
                  },
                ];
              });
            }
          });
        }
      });
    }

    if (role.actions !== undefined) {
      role.actions.map((action) => {
        roles = [
          ...roles.filter(
            (el) =>
              !Boolean(el.name === action.name && el.parent == action.parent)
          ),
          {
            name: action.name,
            checked: checked,
            parent: role.name,
          },
        ];

        if (action.subActions !== undefined) {
          action.subActions.map((subAction) => {
            roles = [
              ...roles.filter(
                (el) =>
                  !Boolean(
                    el.name === subAction.name && el.parent == subAction.parent
                  )
              ),
              {
                name: subAction.name,
                checked: checked,
                parent: action.name,
              },
            ];
          });
        }
        // sub
      });
    }

    if (role.subActions !== undefined) {
      role.subActions.map((subAction) => {
        roles = [
          ...roles.filter(
            (el) =>
              !Boolean(
                el.name === subAction.name && el.parent == subAction.parent
              )
          ),
          {
            name: subAction.name,
            checked: checked,
            parent: role.name,
          },
        ];
      });
    }

    roles = [
      ...roles.filter(
        (el) => !Boolean(el.name == role.name && el.parent == role.parent)
      ),
      {
        name: role.name,
        checked: checked,
        parent: role.parent,
      },
    ];

    setPriveledges(roles);
    // setCompanySettings((prev) => ({ ...prev, companySettings: roles }));
  };

  const addSettings = async (payload) => {
    const cookies = new Cookies();
    const token = cookies.get("x-access-token");
    let response = await fetch(
      `${backendApis[0].url}/api/users/save-roles-priviledges`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          roles: payload.priveledges,
          Staff_ID: "root",
        }),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    console.log("success: ", res);
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
    onSuccess: () => {},
  });

  const formik = useFormik({
    initialValues: {
      /* priveledges,
      Staff_ID: "", */
    },

    onSubmit: (values) => {
      setIsLoading(true);
      //  Save  to  server
      addSettingsMutation.mutate(
        { priveledges },

        {
          onSuccess: (data) => {
            console.log("Success: ", data);
            setRootRoles(data.rootRoles);
            setIsLoading(false);
            // toast.success(data.message);
            next();
            // setShowRolesPriviledgesModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // formik.setFieldValue("userld", '');
  };

  const { data, isFetching } = useQuery(
    ["GET_GLOBAL_SETTINGS"],
    () => setUp(),
    {
      enabled: true,
    }
  );
  const getUsersInSystem = async () => {
    let response = await fetch(`${backendUrl}/api/users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    data.allUsersInSystem = [
      {
        label: "Select User",
        value: "",
      },
      ...data.staff.map((el) => ({
        label: el.Name,
        value: el.Staff_ID,
      })),
    ];

    return data;
  };
  const {
    data: { allUsersInSystem } = { allUsersInSystem: [] },
    isFetching: isFetchingUsers,
  } = useQuery(["GET_USERS_IN_SYSTEM"], () => getUsersInSystem(), {});

  const animatedComponents = makeAnimated();
  const users = useFetchUsers();

  submitForm(formik.submitForm);

  return (
    <div id="companySettings">
      <Form
        noValidate
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        style={
          isFetching || isFetchingUsers
            ? { opacity: "0.5", pointerEvents: "none" }
            : {}
        }
      >
        <div className="roles my-4 pt-4">
          {userRoles.map((role, index) => (
            <div
              key={index}
              className="role-items"
              style={
                selected.level1 === index
                  ? { height: "max-content", background: "rgb(246,246,246)" }
                  : { height: "3em" }
              }
            >
              <h6
                onClick={(e) => {
                  if (e.target.localName === "h6") {
                    setSelected((prev) => {
                      return {
                        ...prev,
                        level1: prev.level1 === index ? null : index,
                      };
                    });
                  }
                }}
              >
                <Form.Check
                  inline
                  type={"checkbox"}
                  checked={Boolean(
                    priveledges.find(
                      (el) => el.name === role.name && el.parent == role.parent
                    )?.checked
                  )}
                  onChange={(e) =>
                    handleChange(
                      Boolean(
                        priveledges.find(
                          (el) =>
                            el.name === role.name && el.parent == role.parent
                        )?.checked
                      ),
                      role
                    )
                  }
                />
                &nbsp;
                {role.name}
                {
                  <span className="directionIcon">
                    {selected.level1 === index ? (
                      <img src={upIcon} />
                    ) : (
                      <img src={downIcon} />
                    )}
                  </span>
                }
              </h6>
              <div className="priviledges">
                {role.priviledges
                  /* .filter((el) =>
                              el.hasOwnProperty("isCement")
                                ? el.isCement === isCement
                                : true
                            ) */
                  .map((priviledge, pIndex) => (
                    <Fragment key={pIndex}>
                      <p
                        onClick={(e) => {
                          if (e.target.localName === "p") {
                            setSelected((prev) => {
                              return {
                                ...prev,
                                level2: prev.level2 === pIndex ? null : pIndex,
                              };
                            });
                          }
                        }}
                      >
                        <Form.Check
                          inline
                          type={"checkbox"}
                          checked={Boolean(
                            priveledges.find(
                              (el) =>
                                el.name === priviledge.name &&
                                el.parent == priviledge.parent
                            )?.checked
                          )}
                          onChange={(e) =>
                            handleChange(
                              Boolean(
                                priveledges.find(
                                  (el) =>
                                    el.name === priviledge.name &&
                                    el.parent == priviledge.parent
                                )?.checked
                              ),
                              priviledge
                            )
                          }
                        />
                        &nbsp;{priviledge.name}
                      </p>
                      <ul
                        className="actions"
                        style={
                          selected?.level2 === pIndex
                            ? {
                                height: "max-content",
                                background: "rgb(246,256,246)",
                              }
                            : { height: "0em" }
                        }
                      >
                        {priviledge.actions?.map((action, aIndex) => (
                          <li
                            key={aIndex}
                            onClick={(e) => {
                              if (e.target.localName === "li") {
                                setSelected((prev) => {
                                  return {
                                    ...prev,
                                    level3:
                                      prev.level3 === aIndex ? null : aIndex,
                                  };
                                });
                              }
                            }}
                          >
                            <Form.Check
                              inline
                              type={"checkbox"}
                              checked={Boolean(
                                priveledges.find(
                                  (el) =>
                                    el.name === action.name &&
                                    el.parent == action.parent
                                )?.checked
                              )}
                              onChange={(e) =>
                                handleChange(
                                  Boolean(
                                    priveledges.find(
                                      (el) =>
                                        el.name === action.name &&
                                        el.parent == action.parent
                                    )?.checked
                                  ),
                                  action
                                )
                              }
                            />
                            &nbsp;{action.name}
                            <ul
                              className="actions"
                              style={
                                selected?.level3 === aIndex
                                  ? {
                                      height: "max-content",
                                      background: "rgb(246,266,246)",
                                    }
                                  : { height: "0em" }
                              }
                            >
                              {action?.subActions?.map((subAction, aIndex) => (
                                <li key={aIndex}>
                                  <Form.Check
                                    inline
                                    type={"checkbox"}
                                    checked={Boolean(
                                      priveledges.find(
                                        (el) =>
                                          el.name === subAction.name &&
                                          el.parent == subAction.parent
                                      )?.checked
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        Boolean(
                                          priveledges.find(
                                            (el) =>
                                              el.name === subAction.name &&
                                              el.parent == subAction.parent
                                          )?.checked
                                        ),
                                        subAction
                                      )
                                    }
                                  />
                                  &nbsp;{subAction.name}
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </Form>
    </div>
  );
}
