import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useQuery, useQueryClient } from "react-query";
import { useEffect } from "react";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { useState } from "react";
import useDebounce, {
  useChosenBackendUrl,
  useCurrencies,
  useIsGovernmentInvexERP,
} from "../../utils/hooks";
import queryString from "query-string";
import CachedIcon from "mdi-react/CachedIcon";
import Expenses from "../Overviews/Expenses";
import Revenue from "../Overviews/Revenue";
import { isEmpty } from "lodash";
import { format } from "date-fns";
import Select from "react-select";
import {
  analyticsOptions,
  fetchActionsUtil,
  formatForQty,
} from "../../utils/helpers";

import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import "../../assets/scss/customInvoiceWrapper.scss";
import "../../assets/scss/dashboard.scss";

import {
  AmountpaidLegendIcon,
  AmountRemainingLegendIcon,
  InvoiceAmountPaid,
} from "../Icons";
import currency from "currency.js";
import { useStoreState } from "easy-peasy";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function InvoiceAnalysisChart() {
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();

  const initialFilterParams = {
    by: "year",
    currency: prevailingCurrency,
    year: new Date().getFullYear(),
  };
  const isGovernmentInvexERP = useIsGovernmentInvexERP();
  const backendUrl = useChosenBackendUrl();
  const [show, setShow] = useState(false);
  const [queryParams, setQueryParams] = useState({
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);
  const generalSettings = useStoreState((state) => state.generalSettings);

  //invoice-dashboard-barchart

  // const getRevenue = async (debouncedqueryParams) => {
  //   let response = await fetch(
  //     `${backendUrl}/api/dashboard/get-revenue?${queryString.stringify(
  //       debouncedqueryParams
  //     )}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         Accept: "Application/json",
  //         "Content-Type": "Application/json",
  //       },
  //     }
  //   );

  //   if (!response.ok) {
  //     response = await response.json();
  //     throw new Error(response.message);
  //   }

  //   const { data } = await response.json();
  //   return data;
  // };

  // const { error, data, isSuccess, refetch, isFetching } = useQuery(
  //   ["GET_REVENUE", debouncedqueryParams, backendUrl],
  //   () => getRevenue(debouncedqueryParams),
  //   {
  //     // enabled: false,
  //     // cacheTime: 0,
  //     onSuccess: (data) => {
  //       // queryClient.removeQueries("LOGIN");
  //     },
  //   }
  // );

  const {
    data: stat,
    refetch: refetchStat,
    isLoading,
    isFetching: isFetchingStat,
    isSuccess: isSuccessStat,
    error: errorStat,
  } = useQuery(
    ["FETCH_INVOICE_STATISTICS-BARCHART", debouncedqueryParams, backendUrl],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/invoice/invoice-dashboard-barchart?${queryString.stringify(
          debouncedqueryParams
        )}`,
        "GET"
      ),
    {
      keepPreviousData: true,
      onSuccess: ({ data }) => {},
    }
  );

  useEffect(() => {
    console.log(errorStat);
  }, [errorStat]);

  return (
    <div className="custom-chart p-4">
      <header className="section-header  d-flex justify-content-between">
        <h6 className="title">
          Invoice Amount
          <button
            title="Refresh"
            onClick={() => refetchStat()}
            className="btn text-primary"
          >
            <CachedIcon />
          </button>
        </h6>

        <div className="position-relative z-10">
          <Select
            classNamePrefix={`form-select-analytics`}
            isSearchable={false}
            options={analyticsOptions}
            value={analyticsOptions.find((el) => el.value === queryParams.by)}
            onChange={(selected) => {
              setQueryParams({
                ...queryParams,
                by: selected?.value,
              });
            }}
          />
          {queryParams.by === "select-year" && (
            <div className="position-absolute -bottom-100 end-0 pt-2">
              <Form.Control
                type="number"
                value={queryParams.year}
                onChange={(e) =>
                  setQueryParams({
                    ...queryParams,
                    year: e.target.value,
                  })
                }
                autoFocus={true}
              />
            </div>
          )}
        </div>
      </header>
      <div className=" row">
        <div className=" col-md-6">
          <div className=" d-flex  gap-4">
            <div>
              <p className=" chart-text ">Amount Paid</p>
              <div className=" d-flex align-items-center gap-1">
                <h6>
                  {" "}
                  {currency(Number(stat?.amountPaid), {
                    symbol: generalSettings?.prevailingCurrency || "",
                    precision: 2,
                    format: formatForQty,
                  }).format()}{" "}
                </h6>
                {/* <span>
                  <InvoiceAmountPaid />
                </span>{" "}
                10+ */}
              </div>
            </div>
            <div>
              <p className=" chart-text"> Amount Remaining</p>
              <div className=" d-flex align-items-center gap-1">
                <h6>
                  {" "}
                  {currency(Number(stat?.amountRemaining), {
                    symbol: generalSettings?.prevailingCurrency || "",
                    precision: 2,
                    format: formatForQty,
                  }).format()}{" "}
                </h6>
                {/* <span>
                  <InvoiceAmountPaid />
                </span>{" "}
                10+ */}
              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-6     chart-desc">
          <div className=" d-flex align-items-center gap-4">
            <div>
              <AmountpaidLegendIcon />{" "}
              <span className=" chart-text">Amount Paid</span>
            </div>

            <div>
              <AmountRemainingLegendIcon />{" "}
              <span className=" chart-text">Amount Remaining</span>
            </div>
          </div>
        </div>
      </div>

      {/* bar chart */}

      <div
        className="custom-chart-holder   mt-4"
        style={{
          width: "40rem",
          height: "20rem",
        }}
      >
        {
          <Bar
            options={{
              responsive: true,

              plugins: {
                legend: {
                  position: "top",
                  align: "end",
                  display: false,

                  labels: {
                    fontColor: "#94A3B8",
                    fontSize: 12,
                    boxHeight: 8,
                    boxWidth: 8,
                    pointStyle: "circle",
                    usePointStyle: true,
                  },
                },
                title: {
                  display: false,
                },
                tooltip: {
                  backgroundColor: "#00000080",
                  boxHeight: 8,
                  boxWidth: 8,
                  pointStyle: "circle",
                  usePointStyle: true,
                  boxPadding: 8,
                },
              },

              scales: {
                y: {
                  grid: {
                    color: "#94A3B8",
                    lineWidth: 0.3,
                  },
                  ticks: {
                    color: "#94A3B8",
                    stepSize: Math.round(100000000 / 10),
                    fontSize: 0,
                  },
                },
                x: {
                  grid: {
                    color: "#fff",
                  },
                  ticks: {
                    color: "#94A3B8",
                    fontSize: 0,

                    // stepSize: 100,
                  },
                },
              },
            }}
            data={{
              labels: [
                ...(isSuccessStat ? stat?.data?.map((el) => el.date) : []),
              ],
              datasets: [
                {
                  label: "Amount Paid",
                  data: [
                    ...(isSuccessStat
                      ? stat?.data?.map((el) => el.totalPaid)
                      : []),
                  ],
                  backgroundColor: "#C0C5CF",
                  borderRadius: 100,
                  barThickness: 16,
                },
                {
                  label: "Amount Remaining",
                  data: [
                    ...(isSuccessStat
                      ? stat?.data?.map((el) => el.totalUnpaid)
                      : []),
                  ],
                  backgroundColor: "#2463AE",
                  borderRadius: 100,
                  barThickness: 16,
                },
              ],
            }}
          />
        }
        {/* <div className="blur" /> */}
      </div>
      {/* <OverviewModal
        show={show}
        setShow={setShow}
        dateRange={
          !isEmpty(data)
            ? {
                startDate: data[0].startDate,
                endDate: data[data.length - 1].endDate,
              }
            : {}
        }
      /> */}
    </div>
  );
}

// export function OverviewModal({ show, setShow, dateRange = {} }) {
//   return (
//     <Modal
//       show={show}
//       onHide={() => setShow(false)}
//       dialogClassName="modal-90w"
//       aria-labelledby="example-custom-modal-styling-title"
//       centered={true}
//       animation={false}
//       enforceFocus={false}
//       backdrop="static"
//     >
//       <Modal.Header closeButton>
//         <Modal.Title className="d-block">
//           <h2 className="h5 text-nowrap w-100">
//             Total Revenue vs Expenses{" "}
//             {dateRange?.startDate && dateRange?.endDate ? (
//               <span>
//                 {dateRange.startDate
//                   ? format(new Date(dateRange.startDate), "dd MMM, yyy")
//                   : ""}
//                 {" - "}
//                 {dateRange.endDate
//                   ? format(new Date(dateRange.endDate), "dd MMM, yyy")
//                   : ""}
//               </span>
//             ) : null}
//           </h2>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="row justify-content-between">
//           <div className="col-md-6  pe-5">
//             <Revenue dateRange={dateRange} />
//           </div>
//           <div className="col-md-6  ps-5">
//             <Expenses dateRange={dateRange} />
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// }
