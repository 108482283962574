
import { FieldArray } from "formik";
import { Button, Form, InputGroup, Accordion, Col } from "react-bootstrap";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import { useEffect, useMemo, useState } from "react";
import ExpensesSelector from "./ExpensesSelector";
import Select from "react-select";
import { values } from "lodash";
import { useIsAdmin, useIsCashier } from "../../utils/hooks";

// const debitOrCreditOptions = [{ label: 'Debit', value: 'Debit' }, { label: 'Credit', value: 'Credit' }];

export default function ChartOfAccountPicker({
  readOnly, accountsToPost = [], setAccountsToPost, defaultActiveKey = "-1",
}) {
  const isAdmin = useIsAdmin()
  const isCashier = useIsCashier();

  const [showJournalPopover, setShowJournalPopover] = useState(-1)

  if (!isAdmin && !isCashier) {
    return null
  }

  return <Accordion defaultActiveKey={defaultActiveKey}>
    <Accordion.Item eventKey="0">
      <Accordion.Header className="m-0 fs-6">Account Postings</Accordion.Header>
      <Accordion.Body>
        <div style={readOnly ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
          <Form.Group>
            {/* <FieldArray
              name="accountsToPost" // Key in Formik's values to manage this array
              render={(arrayHelpers) => (
                <> */}
            {accountsToPost?.map((account, index) => (
              <Form.Group className="mb-3 row d-flex gap-3 align-items-center" key={index}>

                {/*  <Button
                type="button"
                variant="outline-danger"
                onClick={() => {
                  if (formik.values.accounts.length > 0) {
                    arrayHelpers.remove(formik.values.accounts.length - 1); // Remove the last account
                  }
                }}
              >
                ❌
              </Button> */}
                {/*  ${account.accountToPostAccountID || ""} /  */}
                <Col>
                  {account?.customLabel && <Form.Label>{account?.customLabel}:</Form.Label>}
                  <InputGroup hasValidation>
                    <Form.Control
                      placeholder="Select Account"
                      value={account?.userSelectedAccount ? `${account?.userSelectedAccount?.Description || ""
                        } / ${account?.userSelectedAccount?.Type || ""}` : `${account.Description || ""
                        } / ${account.Type || ""}`}
                      onChange={() => { }}
                      readOnly
                    />
                    <Popover
                      isOpen={showJournalPopover == index}
                      onClickOutside={() =>
                        setShowJournalPopover()
                      }
                      content={() => (
                        <ExpensesSelector
                          handleSelectedExpense={(selectedExpense) => {
                            // Update Formik array at the specific index
                            const updatedAccounts = accountsToPost;
                            updatedAccounts[index] = { ...updatedAccounts[index], userSelectedAccount: selectedExpense, };
                            setAccountsToPost(updatedAccounts);
                            setShowJournalPopover()
                          }}
                          usage={"chart of accounts"}
                          onHide={() =>
                            setShowJournalPopover()
                          }
                          Type={account?.Type}
                        />
                      )}
                      position="bottom"
                    >
                      <InputGroup.Text
                        onClick={() => {
                          setShowJournalPopover(index)
                        }}
                      >
                        <MagnifyIcon />
                      </InputGroup.Text>
                    </Popover>
                  </InputGroup>
                </Col>

                {/* <Col sm={3}>
                 <Select classNamePrefix="form-select" options={debitOrCreditOptions}
                    value={debitOrCreditOptions.find(
                      (el) => el.value === accountsToPost[index].entryPosition
                    )}
                    onChange={(selected) => {
                      const updatedAccounts = accountsToPost;
                      updatedAccounts[index] = {
                        ...updatedAccounts[index],
                        entryPosition: selected.value
                      };
                      setAccountsToPost(updatedAccounts);
                    }} isDisabled={true} />
                  <p>{account?.entryPosition}</p>
                </Col> */}
              </Form.Group>
            ))}
            {/*  {!readOnly && <Button
                    type="button"
                    variant="light-blue"
                    onClick={() => {
                      setAccountsToPost([...accountsToPost, {
                        AccountID: "",
                        Description: "",
                        Type: "",
                      }])
                    }}
                  >
                    + Add Account
                  </Button>} */}
            {/*  </>
              )}
            /> */}
          </Form.Group>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
}

