import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon"
import { Button, Dropdown, Form, Table } from "react-bootstrap"
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FieldArray, useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import { DeleteIcon, EditIcon } from "../Icons";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import { cloneDeep, isEmpty } from "lodash";
import { Link } from "react-router-dom";


export default function RequisitionLabels() {
  const [showNewLabelModal, setShowNewLabelModal] = useState(false);
  const [showEditLabelModal, setShowEditLabelModal] = useState(false);
  const { backendUrl } = useAuth()

  const getLabels = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/labels`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const { data, refetch } = useQuery(
    ["LABELS"],
    () => getLabels(),
    {}
  );

  const deleteLabel = async (payload) => {
    let response = await fetch(`${backendUrl}/api/labels/${payload.id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };


  const deleteLabelMutation = useMutation((payload) => deleteLabel(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleDelete = async (el) => {
    if (
      await ConfirmDialog({
        type: 'danger',
        title: "Delete Label",
        description: `Are you sure, delete ${el.name}`,
      })
    ) {
      deleteLabelMutation.mutate(el);
    }
  };

  return <> <div className="labels">
    <div className="d-flex justify-content-between align-items-center title"><span>LABELS</span>
      <Button variant="outline-primary" className="border-0 fs-6" onClick={() => setShowNewLabelModal(true)}>+ Add</Button> </div>

    <div className="">
      {data?.labels.map(el => <div key={el.id} className="a-label">
        <Link to={`/requisition/request?labelId=${el.id}&labelName=${el.name}`} onClick={() => document.body.scrollTop = document.documentElement.scrollTop = 0} className="d-flex gap-2 flex-grow-1 align-item-center">
          <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M17.701 8.08L14.368 4.08C13.796 3.394 12.956 3 12.063 3H6C3.794 3 2 4.794 2 7V13C2 15.206 3.794 17 6 17H12.063C12.956 17 13.796 16.606 14.368 15.92L17.701 11.92C18.628 10.807 18.628 9.193 17.701 8.08ZM11.75 11.25C11.06 11.25 10.5 10.69 10.5 10C10.5 9.31 11.06 8.75 11.75 8.75C12.44 8.75 13 9.31 13 10C13 10.69 12.44 11.25 11.75 11.25Z" fill={el.color} />
          </svg></span>
          <p className="p-cursor">{el.name}</p>
        </Link>

        <Dropdown align={"right"}>
          <Dropdown.Toggle
            variant=""
            className="bg-none border-0"
            bsPrefix="print more">
            <DotsHorizontalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",

            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            <Dropdown.Item
              as="button"
              className=""
              onClick={() => setShowEditLabelModal(el)}
            >
              <EditIcon /> Edit
            </Dropdown.Item>
            <Dropdown.Divider></Dropdown.Divider>
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={() => handleDelete(el)}
            >
              <DeleteIcon /> Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>)}
    </div>
  </div >

    {showNewLabelModal && <NewLabelModal show={showNewLabelModal} setShow={setShowNewLabelModal} refetch={refetch} />
    }
    {showEditLabelModal && <EditLabelModal show={showEditLabelModal} setShow={setShowEditLabelModal} refetch={refetch} oldData={showEditLabelModal} />}
  </>
}

function NewLabelModal({
  show, setShow, refetch
}) {
  const { backendUrl } = useAuth()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formik = useFormik({
    initialValues: {
      name: '',
      color: '#1F5494'
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
    }),
    onSubmit: (values) => {
      // Create label
      createLabelMutation.mutate(values)
    },
  });

  const createLabel = async (payload) => {
    let response = await fetch(`${backendUrl}/api/labels`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createLabelMutation = useMutation(
    (payload) => createLabel(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        if (refetch) refetch()
        formik.resetForm();
        setShow(false)
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  return (
    <>
      <Modal show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>New label</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate
            onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Label className="mb-2">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter a new label name:"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.name && !!formik.errors.name
                }
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>


            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label className="mb-2">Color</Form.Label>
              <Form.Control
                type="color"
                placeholder="Please select a color:"
                name="color"
                value={formik.values.color}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" disabled={createLabelMutation.isLoading} type="button" onClick={formik.submitForm}>
            {createLabelMutation.isLoading ? 'Please wait' : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function EditLabelModal({
  show, setShow, refetch, oldData
}) {
  const { backendUrl } = useAuth()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formik = useFormik({
    initialValues: {
      id: oldData.id,
      name: oldData.name,
      color: oldData.color
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
    }),
    onSubmit: (values) => {
      // edit label
      editLabelMutation.mutate(values)
    },
  });

  const editLabel = async (payload) => {
    let response = await fetch(`${backendUrl}/api/labels/${payload.id}`, {
      method: "PATCH",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const editLabelMutation = useMutation(
    (payload) => editLabel(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        if (refetch) refetch()
        formik.resetForm();
        setShow(false)
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  return (
    <>
      <Modal show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Edit label</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate
            onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-2">
              <Form.Label className="mb-2">Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Please enter a new label name:"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.name && !!formik.errors.name
                }
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>


            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label className="mb-2">Color</Form.Label>
              <Form.Control
                type="color"
                placeholder="Please select a color:"
                name="color"
                value={formik.values.color}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="button" disabled={editLabelMutation.isLoading} onClick={formik.submitForm}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function RequisitionLabelsPickerModal({
  show, setShow, refetch, selectedData
}) {
  const { backendUrl } = useAuth()

  const [showNewLabelModal, setShowNewLabelModal] = useState(false);
  const handleClose = () => setShow(false);


  const formik = useFormik({
    initialValues: {
      labels: [],
      requestid: selectedData.requestid
    },
    validationSchema: yup.object().shape({
    }),
    onSubmit: (values) => {
      // label requisition
      const payload = cloneDeep(values);
      payload.labels = payload.labels.filter(el => el.checked);
      createLabelMutation.mutate(payload)
    },
  });

  const createLabel = async (payload) => {
    let response = await fetch(`${backendUrl}/api/labels/label-requisition`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createLabelMutation = useMutation(
    (payload) => createLabel(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        if (refetch) refetch()
        formik.resetForm();
        setShow(false)
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const getLabels = async () => {

    try {
      let [response, saveLabelResponse] = await Promise.all([fetch(`${backendUrl}/api/labels`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }), fetch(`${backendUrl}/api/labels/label-requisition/${selectedData.requestid}`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      })])

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      response = await response.json();

      if (!saveLabelResponse.ok) {
        saveLabelResponse = await saveLabelResponse.json();
        throw new Error(saveLabelResponse.message);
      }

      saveLabelResponse = await saveLabelResponse.json();
      const savedLabels = !isEmpty(saveLabelResponse?.data?.labels) ? saveLabelResponse.data.labels : [];


      console.log(saveLabelResponse, response.data.labels, response.data.labels.map(el => ({
        ...el, checked: Boolean(savedLabels.find(savedLabel => savedLabel.id == el.id))
      })))


      formik.setFieldValue('labels', response.data.labels.map(el => ({
        ...el, checked: Boolean(savedLabels.find(savedLabel => savedLabel.id == el.id))
      })))

      return response.data;
    } catch (err) {
      console.log(err)
    }
  };
  const { data, isFetching, refetch: refetchLabels } = useQuery(
    ["LABELS",],
    () => getLabels(),
    {}
  );

  return (
    <>
      <Modal show={show} onHide={handleClose} centered animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Label as</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p class="fw-6 h5">{selectedData?.title}</p>
          <p>{selectedData?.formUsed && selectedData.formUsed?.Title
            ? selectedData.formUsed?.Title
            : selectedData.type}</p>

          <hr />

          <FormikProvider value={formik}>
            <Form noValidate
              onSubmit={formik.handleSubmit}>


              <Form.Group className="mb-3">
                <Form.Label className="d-flex justify-content-between">
                  Select labels
                </Form.Label>

                <FieldArray
                  name="labels"
                  render={(arrayHelpers) => (
                    <>
                      <Table hover borderless striped>
                        <thead>
                        </thead>
                        <tbody>
                          {formik.values.labels?.map(
                            (el, index) => (
                              <tr className="col-md-12" key={index}>
                                <td>
                                  <label className="d-flex align-items-center gap-3 py-2 m-0"
                                  >
                                    <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                      <path d="M17.701 8.08L14.368 4.08C13.796 3.394 12.956 3 12.063 3H6C3.794 3 2 4.794 2 7V13C2 15.206 3.794 17 6 17H12.063C12.956 17 13.796 16.606 14.368 15.92L17.701 11.92C18.628 10.807 18.628 9.193 17.701 8.08ZM11.75 11.25C11.06 11.25 10.5 10.69 10.5 10C10.5 9.31 11.06 8.75 11.75 8.75C12.44 8.75 13 9.31 13 10C13 10.69 12.44 11.25 11.75 11.25Z" fill={el.color} />
                                    </svg></span>

                                    <p className="flex-grow-1">{el.name}</p>

                                    <Form.Check checked={formik.values.labels[index]?.checked} name={`labels[${index}].checked`} onChange={formik.handleChange} />
                                  </label>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                      {/*  <div className="d-flex justify-content-start px-4 mt-4">
                        <button
                          type="button"
                          className="btn btn-sm btn-light-blue text-nowrap"
                          onClick={() =>
                            arrayHelpers.push({
                              name: "",
                            })
                          }
                        >
                          + Add
                        </button>
                      </div> */}
                    </>
                  )}
                />
              </Form.Group>
            </Form>
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="light-blue" onClick={() => setShowNewLabelModal(true)}>
            Create New
          </Button>
          <div className="d-flex gap-3">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button disabled={isFetching || createLabelMutation.isLoading} variant="primary" type="button" onClick={formik.submitForm}>
              {createLabelMutation.isLoading ? 'Please wait...' : "Apply"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {showNewLabelModal && <NewLabelModal show={showNewLabelModal} setShow={setShowNewLabelModal} refetch={refetchLabels} />}
    </>
  );
}
