import React, { useEffect, useState } from "react";
import DropDownSelect from "./DropDownSelect";
import { RootRolesPriviledges } from "./CollapsibleTree";

function CompanySettings({ submitForm, next }) {
  return (
    <div className="contents">
      <h1>What do you want to do in InvexERP</h1>
      <RootRolesPriviledges submitForm={submitForm} next={next} />
    </div>
  );
}

export default CompanySettings;
