import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import "../../assets/scss/scoped/requisition-wrapper.scoped.scss";
import { Button } from "react-bootstrap";
import { FilterCollapseIcon } from "../Icons";
import { useLocalStorage } from "../../utils/hooks";

export default function RequisitionWrapper(props) {
  const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage("IS_REQUISITION_SIDEBAR_OPEN", true);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const SideBarToggle = (props) => (
    <Button
      variant=""
      onClick={handleSidebarToggle}
      {...props}
      style={{ transform: isSidebarOpen ? 'translateX(1.25rem)' : '' }}
    >
      <FilterCollapseIcon style={{ transform: isSidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)' }} />
    </Button>
  )

  return (
    <>
      <div className="wrapper">
        <div className="search-area-">{ }</div>
        {props.Nav}

        {props?.sideNav ? (
          <div className={`d-flex requisition-holder ${isSidebarOpen ? "open-sidebar" : "close-sidebar"}`}>
            <div className="request-sidebar">
              <div className={`d-flex justify-content-${isSidebarOpen ? 'end' : 'start'} position-relative `} style={{ minWidth: '15rem', width: '15rem' }}>
                <SideBarToggle onClick={handleSidebarToggle} className="position-absoulte d-none d-md-inline" />
              </div>
              <div className="no-scroll-holder">{React.cloneElement(props.sideNav, {})}</div>
            </div>
            <div className="request-content">
              <Outlet />
            </div>
          </div>
        ) : (
          <Outlet />
        )}

        {" "}
      </div>
    </>
  );
}
