import React, { useEffect, useRef, useState } from "react";
import { backendApis } from "../../config";
import UseInView from "./UseInview";

const FinalPage = () => {
  const pageRef = useRef();
  const [companyLogo, setCompanyLogo] = useState("");
  const isInView = UseInView(pageRef, { threshold: 0.5 });
  useEffect(() => {
    setCompanyLogo(() => (
      <img
        src={`${backendApis[0].url}/images/company-logo.png`}
        alt="Company Logo"
        width="200"
        class="mb-2"
      />
    ));
    console.log("final page in view");
  }, [isInView]);
  const [getImage, setGetImage] = useState(null);
  return (
    <div ref={pageRef} className="contents finalPage">
      <h1>Congratulations!</h1>
      <p>
        You have successfully completed the setup process for InvexERP. We are
        glad you found our platform useful and informative. If you have any
        questions or need further assistance, please don't hesitate to reach out
        to our support team.
      </p>
      <p>Thank you for choosing InvexERP for your business automation needs!</p>
      <p className="link">
        <a href="/dashboard">Go to the dashboard</a>

        <a href="https://invexerp.excellentbridge.com" target="_blank">
          Learn more about InvexERP
        </a>
      </p>
    </div>
  );
};

export default FinalPage;
