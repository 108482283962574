import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addSaleItem,
  amountTypes,
  convertFromBaseCurrency,
  convertToBaseCurrency,
  copyText,
  customerFullName,
  dateIsBeforeMaxBackDate,
  employeeFullName,
  fetchActionsUtil,
  formatCurrencyToNumber,
  formatDate,
  getNumberFromString,
  initialServiceItem,
  maxTopPopperConfig,
  pcsToTons,
  printers,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  tonsToPcs,
  toTonsOrPcs,
  waitFor,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  InvoicePageHeaderIcon,
  NewUserIcon,
  TrashIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { debounce, isEmpty, isEqual, lowerCase } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import {
  Field,
  FieldArray,
  useFormik,
  FormikProvider,
  ErrorMessage,
} from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, {
  useScrollTop,
  useEffectOnce,
  useUpdateEffect,
  useTaxOptions,
  useUnits,
  useDidUpdateEffect,
  useCurrencies,
} from "../../utils/hooks";
import AddRodItemModal from "./AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "./EditIronRodItemModal";
import PermitModal from "./PermitModal";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import SelectSaleRepDialog from "../SaleRepDialog";
import { first } from "lodash";
import { filter } from "lodash";
import InvoiceAndWaybillDialog from "../InvoiceAndWaybillDialog";
import moment from "moment";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { truncate } from "lodash";
import { compareAsc, startOfDay, sub } from "date-fns";
import NewRequisitionModal from "../Requisition/NewRequisition";
import AddRodItemMultipleModal from "./AddRodItemMultipleModal";
import ConvertQuantity, { QuantityLabel } from "../utils/ConvertQuantity";
import PreorderDialog from "../PreorderDialog";
import LockedStockDialog from "../LockedStockDialog";
import { IsPrivileged } from "../DisplayChildElement";
import { cloneDeep } from "lodash";
import { pickBy } from "lodash";
import ImportCartItems from "./ImportCartItems";
import DateTime from "react-datetime";

import AddServiceItemModal from "./AddServiceItemModal";
import CustomLoadingOrderInput from "../CustomLoadingOrderInput/CustomLoadingOrderInput";
import InvoiceInnerPageLayout from "../InvioiceInnerPageLayout";
import "../../assets/scss/dashboard.scss";
import "../../assets/scss/customInvoiceWrapper.scss";
import CustomInvoiceNumberInput from "../utils/CustomInvoiceNumberInput";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import AsyncCreatableSelect from "react-select/async-creatable";

export default function CreateReoccurringInvoice({
  mode,
  oldInvoiceData,
  splitPaymentsDataToEdit = {},
  refreshInvoiceDataItems,
  invoiceType,
}) {
  useScrollTop();
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit",
      value: "Credit",
    },
    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CustomerAccountSelectIcon />,
      label: "Customer Account",
      value: "Customer Account",
    },
    /*{
      icon: <CashSelectIcon />,
      label: "Loyalty Account",
      value: "Loyalty Account",
    },*/
    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment Multiple",
      value: "Split Payment Multiple",
    },
  ];

  const itemSearchCriteriaOptions = [
    {
      label: "Item Code",
      value: "Item Code",
    },
    {
      label: "Serial Number",
      value: "Serial Number",
    },
  ];

  const intervalSearchCriteriaOptions = [
    {
      label: "Daily",
      value: "Daily",
    },
    {
      label: "Weekly",
      value: "Weekly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Yearly",
      value: "Yearly",
    },
  ];

  const reoccuringEndOptions = [
    { label: "None", value: "None" },
    { label: "By", value: "By" },
    { label: "After", value: "After" },
  ];

  const reoccurringWeekOptions = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ];
  const reoccurringMonthlyOnOptions = [
    { label: "Day", value: "Day" },
    { label: "First", value: "First" },
    { label: "Second", value: "Second" },
    { label: "Third", value: "Third" },
    { label: "Fourth", value: "Fourth" },
    { label: "Last", value: "Last" },
  ];
  const reoccurringMonthlyOnDayOptions = [
    { label: "1st", value: "1st" },
    { label: "2nd", value: "2nd" },
    { label: "3rd", value: "3rd" },
    { label: "4th", value: "4th" },
    { label: "5th", value: "5th" },
    { label: "6th", value: "6th" },
    { label: "7th", value: "7th" },
    { label: "8th", value: "8th" },
    { label: "9th", value: "9th" },
    { label: "10th", value: "10th" },
    { label: "11th", value: "11th" },
    { label: "12th", value: "12th" },
    { label: "13th", value: "13th" },
    { label: "14th", value: "14th" },
    { label: "15th", value: "15th" },
    { label: "16th", value: "16th" },
    { label: "17th", value: "17th" },
    { label: "18th", value: "18th" },
    { label: "19th", value: "19th" },
    { label: "20th", value: "20th" },
    { label: "21st", value: "21st" },
    { label: "22nd", value: "22nd" },
    { label: "23rd", value: "23rd" },
    { label: "24th", valuel: "24th" },
    { label: "25th", value: "25th" },
    { label: "26th", value: "26th" },
    { label: "27th", value: "27th" },
    { label: "28th", value: "28th" },
    { label: "Last", value: "Last" },
  ];

  const reoccurringYearlyOptions = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ];
  const taxOptions = useTaxOptions();
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();

  const { Units: saleTypes } = useUnits();
  const { Trans_ID } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const generalSettings = useStoreState((state) => state.generalSettings);
  const { backendUrl } = useAuth();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showPermitModal, setShowPermitModal] = useState(false);

  // const [tableData, setTableData] = useState([]);
  const [lockedTableData, setLockedTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);
  const location = useLocation();
  const [serialNumbersForEdit, setSerialNumbersForEdit] = useState([]);

  const convertQuantity = (Quantity, Item_Desc, type) => {
    return lowerCase(type) === "tons"
      ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
      : Quantity;
  };

  const [refresh, setRefresh] = useState(false);

  useEffectOnce(() => {
    if (location.state) {
      if (location.state?.itemsFromProformaInvoice) {
        return populateTableFromItems(location.state?.itemsFromProformaInvoice);
      }
      if (location.state?.justRestockedSaleItems) {
        return populateTableFromItems(location.state?.justRestockedSaleItems);
      }
      if (location.state?.preparedItems) {
        return populateTableFromItems(location.state?.preparedItems);
      }
    }

    // EDITABLE ITEMS
    if (!isEmpty(oldInvoiceData)) {
      return populateTableFromItems(oldInvoiceData);
    }
  });

  const createRodInvoice = async (payload) => {
    if (
      payload.currency &&
      payload.currency !== generalSettings?.prevailingCurrency
    ) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }
    /*  console.log("JJ", payload);
    return; */

    let response = await fetch(`${backendUrl}/api/invoice/create`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      // alert(JSON.stringify(response));
      throw new Error(JSON.stringify(response));
    }
    const res = await response.json();
    return res;
  };

  const saveInvoice = async (payload) => {
    if (
      payload.currency &&
      payload.currency !== generalSettings?.prevailingCurrency
    ) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }
    /*  console.log("JJ", payload);
    return; */

    let response = await fetch(`${backendUrl}/api/invoice/reocurring-invoice`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      // alert(JSON.stringify(response));
      throw new Error(JSON.stringify(response));
    }
    const res = await response.json();
    return res;
  };

  const openInvoiceModal = async (data) => {
    await InvoiceAndWaybillDialog({
      title: "Transaction posted successfully",
      // description: "...",
      TransactionID: data.TransactionID,
      thermalPrinter: data.thermalPrinter,
    });
  };

  const lockedStockAction = async ({ errorData }) => {
    const dialogAction = await LockedStockDialog({
      title: errorData.message,
      items: errorData?.items,
    });

    if (dialogAction) {
      // send to pending
      createRodPermitMutation.mutate({
        items: tableData.map((el) => ({
          ...el,
          Quantity: el.Quantity,
          requirePermission: true,
        })),
        ...formik.values,
        subTotal,
        discount,
        amountDue,
        profit,
        balance,
        amount,
        customer: selectedCustomer,
        taxValue,
        costOFSales,
        otherTaxValues,
      });
    }
  };

  const createRodInvoiceMutation = useMutation(
    (payload) => {
      if (invoiceType === "reoccurringinvoice") {
        return saveInvoice(payload);
      }

      return createRodInvoice(payload);
    },
    {
      onSuccess: async ({ data, message }) => {
        toast.success(message);

        if (mode === "edit" && invoiceType !== "reoccurringinvoice") {
          refreshInvoiceDataItems();
          setTimeout(() => {
            openInvoiceModal(data);
          }, 900);

          return;
        }

        if (location?.state?.update) {
          refetch();
        }

        if (invoiceType !== "reoccurringinvoice") {
          data.thermalPrinter = formik.values.printer === "Thermal Printer";
        }

        formik.resetForm();

        setSelectedCustomer(defaultCustomer);

        if (invoiceType !== "reoccurringinvoice") {
          openInvoiceModal(data);
        }
      },
      onError: async ({ message }) => {
        try {
          const errorData = JSON.parse(message);
          if (errorData.displayType === "modal") {
            if (errorData?.lockedStockInfo) {
              lockedStockAction({
                errorData,
              });
              return;
            }

            //  This  show  not mess  with  our flow
            ConfirmDialog({
              type: "danger",
              title: errorData.message,
              description: "",
              choice: false,
              summaryTitle: "Summary",
              summary: errorData?.summary,
            });
          } else {
            toast.error(
              `Unable to perform action: ${errorData?.message || message}`
            );
          }
        } catch (err) {
          console.log(err);
          toast.error(`Unable to perform action: ${message}`);
        }
      },
    }
  );

  const preorderInvoice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/preorder`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(JSON.stringify(response));
    }
    const res = await response.json();
    return res;
  };

  const preorderInvoiceMutation = useMutation(
    (payload) => preorderInvoice(payload),
    {
      onSuccess: async ({ data, message }) => {
        toast.success(message);

        PreorderDialog({
          title: "Transaction posted successfully",
          TransactionID: data.TransactionID,
        });

        if (!isEdit) {
          formik.resetForm();
          setSelectedCustomer(defaultCustomer);
          return;
        }
      },
      onError: ({ message }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  // permit
  const createRodPermit = async (payload) => {
    if (
      payload.currency &&
      payload.currency !== generalSettings?.prevailingCurrency
    ) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }

    let response = await fetch(
      `${backendUrl}/api/invoice/create-pending-permit`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createRodPermitMutation = useMutation(
    (payload) => createRodPermit(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        /*  formik.setFieldValue("pendingTransaction", data.TransactionID);
        setLockedTableData([...tableData]);
        setTableData([
          ...tableData.map((el) => ({
            ...el,
            Quantity: el.Quantity,
            requirePermission: true,
          })),
        ]); */
        copyText(
          `${services.frontendUrl}/approval/details/${data.TransactionID}`
        );

        // Clear UI
        formik.resetForm();
        setSelectedCustomer(defaultCustomer);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const determinePaymentType = () => {
    const paymentFromLocation =
      location.state?.itemsFromProformaInvoice[0]?.PayType;

    return appSettings.requireSalesRep
      ? "Credit"
      : paymentFromLocation
        ? paymentFromLocation
        : "Cash";
  };

  // ----------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      deliveryInformation: [
        {
          Vendor_ID: "",
          Vendor_Name: "",
          ServiceChargePerLitre: 0,
          Volume: 0,
          Total: 0,
          DriverName: "",
          TruckNumber: "",
          InhouseLoading: false,
          VolumeLoaded: 0,
          VolumeSupplied: 0,
          ShortageVolume: 0,
          EmployeeID: "",
        },
      ],
      //  tax: "",
      taxType: "None",
      creditLine: "",
      // PaymentType: appSettings.requireSalesRep ? "Credit" : "Cash",
      PaymentType: generalSettings?.defaultInvoicePaymentType || "",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: moment(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      printReciept: true,
      dueIn: generalSettings.dueIn || 1,
      pendingTransaction: "",
      supplyNow: generalSettings.supplyNow,
      ShipTo: "",
      customerBalance: "",
      amountToPayFromCustomerCredit: "",
      printer: appSettings.printerType,
      posCharge: "",
      loadingCharge: "",
      offloadingCharge: "",
      isBatchStandard: appSettings.isBatchStandard, // for server to use
      transportCharge: "",
      Sale_Rep: "",
      Sale_Rep_Name: "",
      shippingCost: 0,
      otherCharges: 0,

      // Split Payment Multiple - Amounts
      /* splitPaymentCardAmount: "",
      splitPaymentChequeAmount: "",
      splitPaymentDirectBankTransferAmount: "",

      // Banks
      splitPaymentCardBank: "",
      splitPaymentChequeBank: "",
      splitPaymentDirectBankTransferBank: "",

      // Numbers
      splitPaymentChequeNumber: "", */

      // --------------
      splitPaymentCardArray: [
        {
          amount: "",
          bank: "",
          posCharge: "",
          fixedAmount: 0,
        },
      ],

      splitDirectBankTransferArray: [
        {
          amount: "",
          bank: "",
        },
      ],

      splitPaymentChequeArray: [
        {
          amount: "",
          bank: "",
          chequeNumber: "",
        },
      ],
      Bar_Code: "",
      currency: generalSettings.prevailingCurrency,
      linkedPaymentID: "",
      jobNumber: "",
      milestone: "",
      conversionAmount: generalSettings.dollarInBaseCurrency,
      poNumber: "",
      lockBank: false,
      itemSearchCriteria: generalSettings.itemSearchCriteria, // Serial Number // Item Code
      amountFromCustomerBalance: 0,
      bankOnInvoice: "",
      taxes: [],
      generalDiscountType: "Fixed",
      generalDiscount: 0,
      generalDiscountPercentage: "",
      // ============================
      interval: invoiceType === "reoccurringinvoice" ? "Daily" : "",
      reoccurringStartDate: "",
      reoccurringEndDate: "",
      every: 1,
      reoccuringEndOption: invoiceType === "reoccurringinvoice" ? "None" : "",
      reoccurringWeekOption: "",
      reoccurringMonthlyOnOption: "",
      reoccurringMonthlyOnDayOption: "",
      reoccurringMonthlyOnNotDayOption: "",
      everyMonth: "",
      reoccurringYearlyOption: "",
      reoccurringYearlyOnDayOption: "",
      reoccurringOccurrence: "",
      // ================================
      inlineItems: [{ ...initialServiceItem() }],
      salesType: "",
    },
    validationSchema: yup.object().shape({
      PaymentType: yup.string().required("Please select Payment Type"),
      ShipTo: yup.string().required("Please enter name on Invoice"),
      // invoiceCat: yup.string().required("required"),

      interval: yup.string().when("invoiceType", {
        is: (invoiceType) => invoiceType === "reoccurringinvoice",
        then: yup.string().required("required"),
        otherwise: yup.string(),
      }),

      every: yup.number().when(["invoiceType", "interval"], {
        is: (invoiceType, interval) =>
          invoiceType === "reoccurringinvoice" &&
          (interval === "Daily" || interval === "Weekly"),
        then: yup
          .number()
          .required("required")
          .min(1, "Value must be at least 1"),
        otherwise: yup.number().nullable(), // Allows it to be empty when the condition isn't met
      }),

      every: yup.number().when(["invoiceType", "interval"], {
        is: (invoiceType, interval) =>
          invoiceType === "reoccurringinvoice" &&
          (interval === "Daily" || interval === "Weekly"),
        then: yup
          .number()
          .required("required")
          .min(1, "Value must be at least 1"),
        otherwise: yup.number().nullable(), // Allows it to be empty when the condition isn't met
      }),

      reoccurringEndDate: yup
        .date()
        .nullable()
        .when("reoccuringEndOption", {
          is: "By",
          then: yup.date().required("required"),
        }),

      reoccurringStartDate: yup
        .date()
        .nullable()
        .when(["invoiceType", "interval"], {
          is: (invoiceType, interval) =>
            invoiceType === "reoccurringinvoice" &&
            (interval === "Daily" ||
              interval === "Weekly" ||
              interval === "Monthly" ||
              interval === "Yearly"),
          then: yup.date().required("required"),
        }),

      reoccurringOccurrence: yup
        .number()
        .nullable()
        .when("reoccuringEndOption", {
          is: "After",
          then: yup
            .number()
            .required("required")
            .min(1, "Must be at least 1"),
        }),

      reoccuringEndOption: yup.string().when("invoiceType", {
        is: (invoiceType) => invoiceType === "reoccurringinvoice",
        then: yup.string().required("End option is required"),
        otherwise: yup.string().nullable(), // Allows it to be empty if condition isn't met
      }),

      reoccurringWeekOption: yup.string().when(["invoiceType", "interval"], {
        is: (invoiceType, interval) =>
          invoiceType === "reoccurringinvoice" && interval === "Weekly",
        then: yup.string().required("required"),
        otherwise: yup.string().nullable(), // Allows it to be empty if condition isn't met
      }),
    }),
    onSubmit: async (values) => {
      values = cloneDeep(values);
      if (isEmpty(tableData)) return toast.error(`Please add an Item`);
      if (!selectedCustomer) return toast.error(`Please select a customer`);
      // if(paymentMethod)

      // Check if user has selected Batch, if feature enabled
      const foundItemsWithoutBatch = tableData.filter((el) =>
        isEmpty(el.selectedBatches)
      );

      if (generalSettings?.deliveryInformationOnInvoice) {
        const itemsQuantityTotal = tableData?.reduce(
          (acc, curr) => acc + Number(curr?.Quantity),
          0
        );
        const totalVolume = values?.deliveryInformation?.reduce(
          (acc, curr) => acc + Number(curr.Volume),
          0
        );
        if (totalVolume !== itemsQuantityTotal)
          return toast.error(`Volume and Quantity does not match`);
      }
      //  console.log(foundItemsWithoutBatch);
      if (generalSettings?.selectBatchDuringInvoicing) {
        if (!isEmpty(foundItemsWithoutBatch)) {
          toast.error(
            `Select batch for ${foundItemsWithoutBatch
              .map((el) => el.Item_Name)
              .join(", ")}`
          );
          return;
        }
      }

      // Check if the user has not chosen bank ----------------------------------------------
      if (
        ["Credit/Debit Card", "Direct Bank Transfer"].includes(
          formik.values.PaymentType
        ) &&
        formik.values.BankName === ""
      ) {
        showSelectBankDialog({
          submit: true,
        });
        return;
      } else if (
        formik.values.PaymentType === "Cheque" &&
        formik.values.BankName === ""
      ) {
        showSelectBankDialog({
          hasChequeNumber: true,
          submit: true,
        });
        return;
      }
      // --------------------------------------------------------------------------------

      // validate
      values.summaryFromMultiplePayments = {};

      if (values.PaymentType === "Split Payment Multiple") {
        let hasError = false;
        for (const [i, v] of values.splitPaymentCardArray.entries()) {
          const str = [...Array(i)].map((el) => `_`).join(" ");
          if (v.amount && !v.bank) {
            formik.setFieldError(
              `splitPaymentCardArray[${i}].bank`,
              "Please select a Bank"
            );
            hasError = true;
          }
          values.summaryFromMultiplePayments[
            `Credit/Debit Card (POS) - ${v.bank}${str}`
          ] = v.amount;
        }

        for (const [i, v] of values.splitDirectBankTransferArray.entries()) {
          const str = [...Array(i)].map((el) => `_`).join(" ");
          if (v.amount && !v.bank) {
            formik.setFieldError(
              `splitDirectBankTransferArray[${i}].bank`,
              "Please select a Bank"
            );
            hasError = true;
          }
          values.summaryFromMultiplePayments[
            `Direct Bank Transfer - ${v.bank}${str}`
          ] = v.amount;
        }

        for (const [i, v] of values.splitPaymentChequeArray.entries()) {
          const str = [...Array(i)].map((el) => `_`).join(" ");
          if (v.amount && !v.bank) {
            formik.setFieldError(
              `splitPaymentChequeArray[${i}].bank`,
              "Please select a Bank"
            );
            hasError = true;
          }

          if (v.amount && !v.chequeNumber) {
            formik.setFieldError(
              `splitPaymentChequeArray[${i}].chequeNumber`,
              "Please add the Cheque Number"
            );
            hasError = true;
          }
        }

        values.summaryFromMultiplePayments[`Amount from Customer Account`] =
          values.amountFromCustomerBalance;

        if (hasError === true) return;
      }

      if (values.PaymentType === "Split Payment Multiple") {
        values.posCharge = formatCurrencyToNumber(totalPosCharge);
      }
      //console.log(values);
      //return;
      //---------------------------

      // sales rep version
      if (appSettings.requireSalesRep) {
        if (isEmpty(values.Sale_Rep)) {
          return toast.error("Please select a sales rep");
        }
      }

      // Validate max ===================================================================
      if (mode !== "edit") {
        const isDateBeforeMaxBackDate = dateIsBeforeMaxBackDate({
          dateSelected: values.salesDate,
          maxBackDateDurationNumber: generalSettings.maxBackDateDurationNumber,
          maxBackDateCustom: generalSettings.maxBackDateCustom,
          maxBackDateDurationValue: generalSettings.maxBackDateDurationValue,
        });

        if (!Boolean(isDateBeforeMaxBackDate)) {
          return toast.error("Cannot back date, contact Admin");
        }
      }
      // ================================================================================

      // Edit ===============================================================
      if (mode === "edit" && Trans_ID) {
        if (
          await ConfirmDialog({
            title: "Post Transaction",
            description: "Are you sure, you want to make this transaction",
            summaryTitle: "Summary",
            summary: setupSummary(values),
            removeUnderscore: true,
          })
        ) {
          createRodInvoiceMutation.mutate({
            items: tableData,
            ...values,
            subTotal,
            discount,
            amountDue,
            profit,
            balance,
            amount,
            customer: selectedCustomer,
            taxValue,
            costOFSales,
            isRod: true,
            // blunder
            amountPaid:
              values.PaymentType === "Split Payment"
                ? /*values.cashAmount*/ values.amountPaid
                : values.PaymentType === "Split Payment Multiple"
                  ? totalCashFromMultiPaymentSplit
                  : values.PaymentType === "Customer Account"
                    ? 0
                    : values.amountPaid,
            mode,
            TransactionIDForDelete: Trans_ID,
            salesIncome,
            otherTaxValues,
          });
          return;
        }
      }
      // End Edit =======================================================

      // Preorder ===============================================================
      if (mode === "preorder" || mode === "edit-preorder") {
        values.supplyNow = false;
        if (
          await ConfirmDialog({
            title: "Post Transaction",
            description: "Are you sure, you want to make this Pre-order",
            summaryTitle: "Summary",
            summary: setupSummary(values),
            removeUnderscore: true,
          })
        ) {
          preorderInvoiceMutation.mutate({
            items: tableData,
            ...values,
            subTotal,
            discount,
            amountDue,
            profit,
            balance,
            amount,
            customer: selectedCustomer,
            taxValue,
            costOFSales,
            isRod: true,
            // blunder
            amountPaid:
              values.PaymentType === "Split Payment"
                ? values.amountPaid
                : values.PaymentType === "Split Payment Multiple"
                  ? totalCashFromMultiPaymentSplit
                  : values.PaymentType === "Customer Account"
                    ? 0
                    : values.amountPaid,
            mode,
            TransactionIDForDelete: Trans_ID,
            salesIncome,
            otherTaxValues,
          });
          return;
        }
        return;
      }
      // Pre-order =======================================================

      const currentPaymentWeWantToLink = paymentsData?.payments.find(
        (el) => el.value === values.linkedPaymentID
      );
      if (!currentPaymentWeWantToLink && values.linkedPaymentID) {
        return toast.error(
          `Payment not found - ${formik.values.linkedPaymentID}`
        );
      }

      // set config for sale permissions
      if (
        appSettings.ignoreSalePermissions &&
        !currentPaymentWeWantToLink?.requiresApproval
      ) {
        // confirm first
        if (
          !(await ConfirmDialog({
            title: "Post Transaction",
            description: "Are you sure, you want to make this transaction.",
            summaryTitle: "Summary",
            summary: setupSummary(values),
            removeUnderscore: true,
          }))
        ) {
          return;
        }

        return createRodInvoiceMutation.mutate({
          items: tableData,
          ...values,
          subTotal,
          discount,
          amountDue,
          profit,
          balance,
          amount,
          customer: selectedCustomer,
          taxValue,
          costOFSales,
          salesIncome,
          isRod: true,
          // blunder
          amountPaid:
            values.PaymentType === "Split Payment"
              ? /*values.cashAmount*/ values.amountPaid
              : values.PaymentType === "Split Payment Multiple"
                ? totalCashFromMultiPaymentSplit
                : values.PaymentType === "Customer Account"
                  ? 0
                  : values.amountPaid,
          otherTaxValues,
        });
      }

      if (values.pendingTransaction) {
        // Get items that are not in the locked set
        const lockedTableBarcode = lockedTableData.map((el) => el.Bar_Code);
        const newTableRecords = tableData.filter(
          (el) => !lockedTableBarcode.includes(el.Bar_Code)
        );
        const oldTableRecords = tableData.filter((el) =>
          lockedTableBarcode.includes(el.Bar_Code)
        );
        if (newTableRecords.find((el) => el.requirePermission)) {
          if (
            await ConfirmDialog({
              description:
                "One or more added items requires COST PERMISSIONS and cannot be posted would you like to post it for confirmation?",
            })
          ) {
            // send to pending
            createRodPermitMutation.mutate({
              items: newTableRecords.map((el) => ({
                ...el,
                Quantity: el.Quantity,
              })),
              ...values,
              subTotal,
              discount,
              amountDue,
              profit,
              balance,
              amount,
              customer: selectedCustomer,
              taxValue,
              costOFSales,
              otherTaxValues,
            });
          }
          return;
        } else {
          // check if old data has been - if data from server Status: "Pending", local requirePermission: false
          if (
            oldTableRecords.find((el) => el.requirePermission) ||
            oldTableRecords.find((el) => el.Status === "Pending")
          ) {
            toast.info("Some items in this transaction still needs approval");
          } else {
            // confirm first
            if (
              !(await ConfirmDialog({
                title: "Post Transaction",
                description: "Are you sure, you want to make this transaction",
                summaryTitle: "Summary",
                summary: setupSummary(values),
                removeUnderscore: true,
              }))
            ) {
              return;
            }
            // submit to backend - create transaction
            createRodInvoiceMutation.mutate({
              items: tableData,
              ...values,
              subTotal,
              discount,
              amountDue,
              profit,
              balance,
              amount,
              customer: selectedCustomer,
              taxValue,
              costOFSales,
              salesIncome,
              isRod: true,
              // blunder
              amountPaid:
                values.PaymentType === "Split Payment"
                  ? /*values.cashAmount*/ values.amountPaid
                  : values.PaymentType === "Split Payment Multiple"
                    ? totalCashFromMultiPaymentSplit
                    : values.PaymentType === "Customer Account"
                      ? 0
                      : values.amountPaid,
              otherTaxValues,
            });
          }
          return;
        }
      }

      // -----------------------------------------------------------

      // check if permission is required
      if (
        tableData.find((el) => el.requirePermission) ||
        currentPaymentWeWantToLink?.requiresApproval
      ) {
        if (
          await ConfirmDialog({
            description: currentPaymentWeWantToLink?.requiresApproval
              ? "Payment requires PERMISSION and cannot be posted would you like to post it for confirmation?"
              : "One or more items requires COST PERMISSIONS and cannot be posted would you like to post it for confirmation?",
          })
        ) {
          /* if (location.state?.job) {
            setShowNewRequisitionModal(true);
            return;
          } */

          // send to pending
          createRodPermitMutation.mutate({
            items: tableData.map((el) => ({
              ...el,
              Quantity: el.Quantity,
              requirePermission: true,
            })),
            ...values,
            subTotal,
            discount,
            amountDue,
            profit,
            balance,
            amount,
            customer: selectedCustomer,
            taxValue,
            costOFSales,
            otherTaxValues,
          });
        }
        return;
      } else {
        // confirm first]
        console.log(values, "dd");
        if (
          !(await ConfirmDialog({
            title: "Post Transaction",
            description: "Are you sure, you want to make this transaction ?",
            summaryTitle: "Summary",
            summary: setupSummary(values),
            removeUnderscore: true,
          }))
        ) {
          return;
        }
        createRodInvoiceMutation.mutate({
          items: tableData,
          ...values,
          subTotal,
          discount,
          amountDue,
          profit,
          balance,
          amount,
          customer: selectedCustomer,
          taxValue,
          costOFSales,
          salesIncome,
          isRod: true,
          // blunder
          amountPaid:
            values.PaymentType === "Split Payment"
              ? /*values.cashAmount*/ values.amountPaid
              : values.PaymentType === "Split Payment Multiple"
                ? totalCashFromMultiPaymentSplit
                : values.PaymentType === "Customer Account"
                  ? 0
                  : values.amountPaid,
          otherTaxValues,
        });
      }
    },
    onReset: () => {
      setTableData([]);
      formik.setFieldValue("PaymentType", formik.values.PaymentType);
      paymentsDataQuery.refetch();
      const chosenBankOnInvoice = data.banks?.find(
        (el) => el.showOnSalesInvoice === "yes"
      );

      // Delay, there was an issue
      if (chosenBankOnInvoice) {
        setTimeout(
          () =>
            formik.setFieldValue(
              "bankOnInvoice",
              chosenBankOnInvoice?.BankName
            ),
          500
        );
      }
    },
  });

  const tableData = useMemo(() => {
    return formik.values?.inlineItems
      ? cloneDeep(formik.values.inlineItems)
      : [];
  }, [formik.values?.inlineItems]);

  const setTableData = (items) => {
    formik.setFieldValue("inlineItems", [...items]);
  };

  /*  useEffect(() => {
    console.log(formik.values.bankOnInvoice);
  }, [formik.values.bankOnInvoice]);

  useEffect(() => {
    console.log(formik.values.currency);
  }, [formik.values.currency]); */

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
      lockBank: formik.values.lockBank,
    });
    if (bank && bank?.bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }

    if (props.submit) {
      formik.submitForm();
    }
  };

  useEffectOnce(() => {
    if (location.state && location.state?.customer) {
      handleSelectedCustomer(location.state?.customer);
    }
  });

  useEffectOnce(() => {
    if (location.state) {
      if (location.state?.jobItems) {
        populateTableFromItems(location.state?.jobItems);
      }
    }
  });

  useDidUpdateEffect(() => {
    if (isEmpty(tableData)) return;
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));
    if (data?.drivers) {
      data.drivers = data.drivers.map((el) => ({
        ...el,
        label: `${el.FirstName} ${el.LastName}`,
        value: `${el.FirstName} ${el.LastName}`,
      }));
    } else {
      data.driver = [];
    }

    data.banks = data?.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    if (isEmpty(location.state)) {
      const chosenBankOnInvoice = data.banks.find(
        (el) => el.showOnSalesInvoice === "yes"
      );
      if (chosenBankOnInvoice && isEmpty(formik.values.bankOnInvoice)) {
        formik.setFieldValue("bankOnInvoice", chosenBankOnInvoice?.BankName);
      }
    }
    return data;
  };

  const { data = { invoiceCat: [], banks: [] } } = useQuery(
    [queryActions.INVOICE_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const { data: scheduleData, refetch } = useQuery(
    ["UPDATE_SCHEDULED_TRANSACTION", location?.state?.id, refresh],
    () =>
      location?.state?.update &&
      fetchActionsUtil(
        `${backendUrl}/api/invoice/sheduled-tansactions/${location?.state?.id}`
      ),
    {
      onSuccess: (data) => {
        if (!data?.data) return;
        const invoiceData = data?.data?.invoice
          ? JSON.parse(data?.data?.invoice)
          : {};

        const { invoice, ...rest } = data?.data;

        formik.resetForm({
          values: {
            ...formik.initialValues,
            ...invoiceData, // Merge invoice data into initial values
            salesDate: moment(
              invoice?.salesDate || formik.initialValues.salesDate
            ), // Example for moment date
            ...rest,
            reoccurringStartDate: rest?.reoccurringStartDate
              ? moment(rest?.reoccurringStartDate)
              : "",
            reoccurringEndDate: rest?.reoccurringEndDate
              ? moment(rest?.reoccurringEndDate)
              : "",
            every: invoiceData?.every,

            id: location?.state?.id,
          },
        });
      },
    }
  );

  const paymentFilter = useMemo(() => {
    return { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" };
  }, [selectedCustomer?.Cust_ID]);

  const customerPaymentsFilter = useDebounce(paymentFilter, 800);

  const fetchPayments = async ({ Cust_ID, Remark }) => {
    let response = await fetch(
      `${backendUrl}/api/customers/get-payments?${queryString.stringify({
        Cust_ID,
        /*     Remark, */
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.payments = [
      {
        value: "",
        label: "None",
      },
      ...data.payments.map((el) => {
        const requiresApproval =
          compareAsc(
            new Date(startOfDay(new Date())),
            new Date(el.Post_Time)
          ) === 1;

        return {
          ...el,
          value: el.Trans_ID,
          label: `${el.Remark} -  ${currency(el.Credit, {
            symbol: currencySymbol,
          }).format()} - ${el?.Post_Time ? formatDate(el?.Post_Time) : ""} ${requiresApproval ? "- Requires Approval" : ""
            }`,
          requiresApproval,
        };
      }),
    ];

    return data;
  };

  const { data: paymentsData, ...paymentsDataQuery } = useQuery(
    ["CUSTOMER_PAYMENTS", customerPaymentsFilter],
    () => fetchPayments(customerPaymentsFilter),
    {
      keepPreviousData: false,
      enabled:
        generalSettings?.linkPaymentToInvoice &&
        !!customerPaymentsFilter.Cust_ID /*  || !!customerPaymentsFilter.Remark */,
    }
  );

  const populateTableFromItems = (items) => {
    if (generalSettings?.itemsRequiresSerialNumber) {
      setSerialNumbersForEdit(
        items
          .map((el) => el.serialNumberItems)
          .flat()
          .map((el) => el.serialNum)
      );
    }

    //console.log(items);
    // convert qtys and clean
    if (
      items &&
      items[0]?.baseCurrency &&
      items[0]?.baseCurrency !== generalSettings?.prevailingCurrency
    ) {
      items = items.map((item) =>
        convertFromBaseCurrency({
          data: item,
          conversionAmount: items[0]?.baseConversionAmount,
        })
      );
    }
    //
    // console.log(items);
    // convert qtys and clean up

    // console.log(items, "d");

    items = items?.map((el) => {
      // ------------------------

      // check if sales type  - old  records
      const value = qtyFormat(el.QTY, el.Serial_Number, itemMeasurements).split(
        "-"
      );
      const tons = value[0];

      const discountPerItem = currency(el?.Discount || 0).divide(
        getNumberFromString(
          el?.Warrant_Duration ? el?.Warrant_Duration : el?.QTY ? el.QTY : 1
        )
      ).value;

      return {
        ...el,
        //  old way -  user  must  sell  either  ton or  pcs not both
        saleType: el.Serial_Number
          ? Number(tons) > 0
            ? "Tons"
            : "Pieces"
          : "",
        // new way for new records
        ...(el?.saleType ? { saleType: el?.saleType } : {}),
        Product_Name: el.ProductName ? el.ProductName : el.Product_Name,
        Item_Desc: el.Serial_Number,
        PriceSold: currency(el.PriceSold, {
          symbol: "",
          separator: "",
        }).format(),
        Quantity: currency(el.QTY, {
          symbol: "",
          separator: "",
        }).format(),
        UnitCost: currency(el.UnitCost, {
          symbol: "",
          separator: "",
        }).format(),
        Discount: currency(el.Discount, {
          symbol: "",
          separator: "",
        }).format(),
        SubTotal: currency(el.SubTotal, {
          symbol: "",
          separator: "",
        }).format(),
        Profit: currency(el.Profit, {
          symbol: "",
          separator: "",
        }).format(),
        UnitPrice: currency(el.Unit_Price, {
          symbol: "",
          separator: "",
        }).format(),
        Size: el.Serial_Number,
        selectedBatches: el?.selectedBatches
          ? JSON.parse(el.selectedBatches)
          : [],
        discountPerItem,
      };
    });

    //  console.log(items);

    if (items) {
      formik.resetForm();
      let {
        DeliveryInformation,
        PayType = "",
        VAT,
        OverwriteOfficer,
        Date_Log,
        TransactionID,
        customer,
        ProductName,
        shippingCost,
        otherCharges,
        /*  currency: */ baseCurrency = generalSettings?.prevailingCurrency,
        taxType,
        ShipTo,
        linkedPaymentID,
        jobNumber,
        milestone,
        /*  conversionAmount:  */ baseConversionAmount = 0,
        poNumber,

        //   console.log(linkedPaymentID, ShipTo);
        bank,

        // --------------------------
        loadingCharge,
        offloadingCharge,
        transportCharge,
        posCharge,
        // --------------------------
        LoadingCharge,
        OffloadingCharge,
        TransportCharge,
        PosCharge,
        // --------------------------
        canModify = false,
        Status,
        dueIn,
        UserName,
        supplyNow,
        amountFromCustomerBalance,
        bankOnInvoice,
        otherTaxValues,
        generalDiscount,
        generalDiscountType,
        generalDiscountPercentage,
      } = items[0];

      //

      loadingCharge = loadingCharge ? loadingCharge : LoadingCharge;
      offloadingCharge = offloadingCharge ? offloadingCharge : OffloadingCharge;
      transportCharge = transportCharge ? transportCharge : TransportCharge;
      posCharge = posCharge ? posCharge : PosCharge;

      //  console.log(posCharge);

      //  console.log(linkedPaymentID, ShipTo, PayType);
      // TODO - Default Pay Type
      if (PayType === "Proforma") {
        if (appSettings.requireSalesRep) {
        } else {
          PayType = generalSettings.defaultInvoicePaymentType;
        }
      }

      formik.setValues({
        ...formik.values,
        deliveryInformation: DeliveryInformation
          ? JSON.parse(DeliveryInformation)
          : [
            {
              Vendor_ID: "",
              Vendor_Name: "",
              ServiceChargePerLitre: 0,
              Volume: 0,
              Total: 0,
              DriverName: "",
              TruckNumber: "",
              InhouseLoading: false,
              VolumeLoaded: 0,
              VolumeSupplied: 0,
              ShortageVolume: 0,
              EmployeeID: "",
            },
          ],
        //    taxValue: VAT,
        ...(["Split Payment Multiple", "Split Payment"].includes(PayType)
          ? { PaymentType: PayType }
          : {}),
        OverwriteOfficer,
        // salesDate: Date_Log, // use current date
        ...(TransactionID ? { pendingTransaction: TransactionID } : {}),
        invoiceCat: ProductName,
        shippingCost,
        otherCharges,
        currency: baseCurrency,
        taxType: taxType || "None",
        jobNumber,
        milestone,
        conversionAmount: baseConversionAmount,
        poNumber,
        salesDate: !isEdit ? new Date() : Date_Log,
        pendingTransaction: !isEdit ? TransactionID : "",
        invoiceCat: ProductName,
        shippingCost,
        otherCharges,
        currency,
        taxType: taxType || "None",
        BankName: bank ? bank : "",
        loadingCharge,
        offloadingCharge,
        transportCharge,
        posCharge,
        ...(splitPaymentsDataToEdit ? splitPaymentsDataToEdit : {}),
        dueIn: dueIn ? dueIn : generalSettings.dueIn,
        lockBank: Boolean(
          !isEmpty(bank) && UserName === "Store" && appSettings.isMertesacker
        ),
        ...(supplyNow !== undefined ? { supplyNow } : {}),
        amountFromCustomerBalance,
        bankOnInvoice,
        taxes: otherTaxValues ? JSON.parse(otherTaxValues) : [],
        generalDiscount,
        generalDiscountType,
        generalDiscountPercentage,
      });

      // This was not setting, had to delay
      setTimeout(() => {
        if (ShipTo) {
          formik.setFieldValue("ShipTo", ShipTo);
        }
        if (linkedPaymentID) {
          formik.setFieldValue("linkedPaymentID", linkedPaymentID);
        }

        formik.setFieldValue("currency", baseCurrency);
      }, 500);

      setTableData(items);
      if (customer) setSelectedCustomer(customer);

      // locked
      if (!isEdit && !canModify && mode !== "preorder") {
        setLockedTableData(items);
      }

      if (appSettings.requireSalesRep) {
        getSaleReps({
          selectedCustomer: customer,
          invoiceCat: ProductName,
        });
      }

      if (Status === "Pre-order" && !isEdit) {
        handlePaymentMethod("Customer Account");
        return;
      }

      //  console.log(splitPaymentsDataToEdit);
      if (["Split Payment Multiple", "Split Payment"].includes(PayType)) return;
      handlePaymentMethod(
        linkedPaymentID ? "Customer Account" : jobNumber ? "Credit" : PayType
      );
      //  handlePaymentMethod(linkedPaymentID ? "Customer Account" : PayType);
    }
  };

  useEffect(() => {
    if (selectedCustomer?.Cust_ID !== "000101") {
      formik.setFieldValue(
        "ShipTo",
        selectedCustomer && selectedCustomer?.LastName
          ? selectedCustomer?.LastName
          : defaultCustomer?.LastName
      );
    }
  }, [selectedCustomer]);

  const handleSelectedPermit = (permit) => {
    // Check if item has been invoiced
    if (permit.Status === "Invoiced") {
      toast.info("Already Invoiced, Select Another");
      return;
    }

    if (permit.Status === "Pending") {
      toast.info("Not Approved, Select Another");
      return;
    }

    if (["Approved", "Pre-order"].includes(permit.Status)) {
      setShowPermitModal(false);
      populateTableFromItems(permit.items);
    } else {
      toast.info("Invalid Selection, Select Approved or Pre-order");
    }
  };

  const handleAddItem = (item) => {
    if (Array.isArray(item)) {
      setTableData(item);

      formik.setFieldValue("inlineItems", item);
      setShowItemSelectorModal(false);
      return;
    }
    // console.log(item);
    // console.log(item);
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }

    // if sale rep version, ensure all other items have same product_name
    if (!isEmpty(tableData) && appSettings.requireSalesRep) {
      const firstItem = first(tableData);
      //  console.log(firstItem);
      if (firstItem.Product_Name !== item.Product_Name) {
        return toast.error(
          `Select Items with the same manufacturer (${firstItem.Product_Name
          }), `
        );
      }
    }

    if (isEmpty(tableData) && appSettings.requireSalesRep) {
      // get Sales Rep
      getSaleReps({
        selectedCustomer,
        invoiceCat: item.Product_Name,
      });
    }

    let foundIndex = tableData.findIndex((el) => el.Bar_Code === item.Bar_Code);

    // Check for Serial Number
    if (!isEmpty(item?.serialNumberItems)) {
      foundIndex = -1;
    }

    let updatedTableData = [...tableData];
    if (foundIndex === -1) {
      updatedTableData = [...tableData, item];
    } else {
      const oldItem = tableData[foundIndex];
      if (
        (oldItem.saleType !== item.saleType && !appSettings.isBatchStandard) ||
        oldItem.Serial_Number !== item.Serial_Number
      ) {
        updatedTableData = [...tableData, item];
      } else {
        updatedTableData[foundIndex] = item;
      }
    }

    setTableData(updatedTableData);

    // Populate FieldArray with the updated tableData

    formik.setFieldValue("inlineItems", updatedTableData);

    formik.setFieldValue("invoiceCat", item.Product_Name);
    if (Number(item.quantityInStock) <= 0) {
      formik.setFieldValue("supplyNow", false);
      formik.setFieldValue("printWaybill", false);
    }

    setShowItemSelectorModal(false);
  };

  const handleEditItem = (item) => {
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }

    const foundIndex = tableData.findIndex(
      (el) =>
        el.Bar_Code === item.Bar_Code &&
        el.saleType === item.saleType &&
        el.Serial_Number === item.Serial_Number &&
        isEqual(item?.serialNumberItems, el?.serialNumberItems)
    );

    tableData[foundIndex] = item;
    setTableData([...tableData]);
    formik.setFieldValue("inlineItems", [...tableData]);
    setEditedItemIndex(null);
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);

    formik.setFieldValue("inlineItems", [
      ...tableData.filter((el, i) => i !== index),
    ]);
  };

  const getSaleReps = async ({ selectedCustomer, invoiceCat }) => {
    try {
      let response = await fetch(
        `${backendUrl}/api/customers/sales-rep?${queryString.stringify({
          Cust_ID: selectedCustomer.Cust_ID,
          Product: invoiceCat,
        })}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        toast.error(response.message);
      } else {
        const {
          data: { customerReps = [] },
        } = await response.json();
        if (isEmpty(customerReps)) {
          getSaleRepDialog({
            selectedCustomer,
            invoiceCat,
          });
          return;
        } else {
          const foundSaleRep = first(customerReps);
          formik.setFieldValue("Sale_Rep", foundSaleRep.Rep_ID);
          formik.setFieldValue(
            "Sale_Rep_Name",
            employeeFullName(foundSaleRep.employee)
          );
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to get saleReps");
    } finally {
    }
  };

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(defaultCustomer);
  };

  const handleRowClick = (e, index) => {
    if (e.target.tagName === "TD") {
      if (formik.values.jobNumber && mode !== "edit") {
        return;
      }
      setEditedItemIndex(index);
    }
  };

  // discount sum
  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
        .map((el) => {
          return el.Discount;
        })
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;
    return sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      }).format()
      : "0.00";
  }, [tableData, formik.values.jobNumber]);

  const subTotal = useMemo(() => {
    // TODO - Add Charges
    const sum = tableData
      ? tableData
        .map((el) => el.SubTotal)
        .reduce(
          (a, b) =>
            currency(a, {
              symbol: "",
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;
    return sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      })
        .add(discount) // don't known -
        .format()
      : "0.00";
  }, [tableData, discount]);

  const totalPosCharge = useMemo(() => {
    return formik.values.splitPaymentCardArray
      .map((el) => el.posCharge)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitPaymentCardArray]);

  const chargesAfterTax = useMemo(() => {
    return currency(formik.values.loadingCharge, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.offloadingCharge)
      .add(formik.values.posCharge)
      .add(formik.values.transportCharge)
      .add(totalPosCharge)
      .add(formik.values.shippingCost)
      .add(formik.values.otherCharges);
  }, [
    formik.values.offloadingCharge,
    formik.values.loadingCharge,
    formik.values.posCharge,
    formik.values.transportCharge,
    totalPosCharge,
    formik.values.shippingCost,
    formik.values.otherCharges,
  ]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
      precision: 4,
    })
      .subtract(discount)
      .subtract(formik.values.generalDiscount)
      .multiply(taxType?.percentage)
      .format();
  }, [
    subTotal,
    discount,
    formik.values.taxType,
    taxOptions,
    formik.values.generalDiscount,
  ]);

  // Calculate other taxValues
  const otherTaxValues = useMemo(() => {
    return formik.values.taxes
      .map((el) => {
        const taxType = taxOptions.find(
          (taxOption) => taxOption.value === el.taxType
        );
        //  console.log(taxType, subTotal);
        return {
          ...el,
          taxValue: currency(subTotal, {
            precision: 4,
          })
            .subtract(discount)
            .subtract(formik.values.generalDiscount)
            .multiply(taxType?.percentage).value,
        };
      })
      .filter((el) => el.taxType);
  }, [
    subTotal,
    formik.values.taxes,
    taxOptions,
    discount,
    formik.values.generalDiscount,
  ]);

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
        .map((el) => el.SubTotal)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;

    // add loading charges

    //
    const totalOtherTaxes = !isEmpty(otherTaxValues)
      ? otherTaxValues.reduce(
        (sum, tax) => currency(sum).add(tax.taxValue).value,
        0
      )
      : 0;

    const total = sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      })
        .multiply(100)
        .divide(100)
        .add(taxValue)
        .add(chargesAfterTax)
        .add(totalOtherTaxes)
        .subtract(formik.values.generalDiscount)
        .format()
      : "0.00";

    // TODO - Add Invoice Discount Data %, Fixed

    const value = formik.values.PaymentType;
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", total);
    }

    formik.setFieldValue("amountToPayFromCustomerCredit", total);

    // will be set before we get here
    // if (value !== "Split Payment Multiple") {
    // formik.setFieldValue("cashAmount", total);
    //}
    return total;
  }, [
    tableData,
    taxValue,
    formik.values.PaymentType,
    chargesAfterTax,
    otherTaxValues,
    formik.values.generalDiscount,
  ]);

  // other cash
  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const totalSplitPaymentCardAmount = useMemo(() => {
    return formik.values.splitPaymentCardArray
      .map((el) => el.amount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitPaymentCardArray]);

  const totalSplitPaymentChequeAmount = useMemo(() => {
    return formik.values.splitPaymentChequeArray
      .map((el) => el.amount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitPaymentChequeArray]);

  const totalSplitPaymentDirectBankTransferAmount = useMemo(() => {
    return formik.values.splitDirectBankTransferArray
      .map((el) => el.amount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [formik.values.splitDirectBankTransferArray]);

  const totalCashFromMultiPaymentSplit = useMemo(() => {
    return (
      //  .add(chargesAfterTax)
      currency(totalSplitPaymentCardAmount, {
        symbol: "",
        separator: "",
      })
        .add(totalSplitPaymentChequeAmount)
        .add(totalSplitPaymentDirectBankTransferAmount)
        .add(formik.values.cashAmount).value
    );
  }, [
    totalSplitPaymentCardAmount,
    totalSplitPaymentChequeAmount,
    totalSplitPaymentDirectBankTransferAmount,
    formik.values.cashAmount,
    // chargesAfterTax,
  ]);

  const balance = useMemo(() => {
    // is Balance Zero for split Payment
    const totalCashForSplit = currency(formik.values.cashAmount, {
      symbol: "",
      separator: "",
    })
      .add(amount)
      .format();

    // console.log(amountDue, totalCashFromMultiPaymentSplit);

    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.amountFromCustomerBalance)
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? totalCashForSplit
          : formik.values.PaymentType === "Split Payment Multiple"
            ? totalCashFromMultiPaymentSplit
            : formik.values.amountPaid
      )
      .format();
  }, [
    amount,
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
    totalCashFromMultiPaymentSplit,
    formik.values.amountFromCustomerBalance,
  ]);

  const profit = useMemo(() => {
    const sum = tableData
      ? tableData
        .map((el) => el.Profit)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
      : 0.0;
    return sum
      ? currency(sum, {
        symbol: "",
        separator: "",
      }).format()
      : "0.00";
  }, [tableData]);

  const grandTotal = useMemo(() => {
    return amountDue;
  }, [amountDue]);

  // This should come from batch, it will be done on the server
  const costOFSales = useMemo(() => {
    return tableData
      .map(
        (el) =>
          currency(el.UnitCost, { symbol: "", separator: "" }).multiply(
            convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
          ).value
      )
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b).value,
        0
      );
  }, [tableData]);

  const handlePaymentMethod = (value) => {
    if (value === "Proforma") {
      formik.setFieldValue("PaymentType", "Credit");
      formik.setFieldValue("amountPaid", 0);
      return;
    }

    formik.setFieldValue("PaymentType", value);
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    }
    // clear pos charge
    if (value !== "Credit/Debit Card") {
      formik.setFieldValue("posCharge", 0);
    }

    if (value === "Split Payment" || value === "Split Payment Multiple") {
      formik.setFieldValue(
        "cashAmount",
        currency(amountDue).subtract(chargesAfterTax).value
      );
    }

    if (value !== "Split Payment Multiple") {
      formik.setFieldValue("splitPaymentCardArray", [
        {
          amount: "",
          bank: "",
          posCharge: "",
          fixedAmount: "",
        },
      ]);
      formik.setFieldValue("amountFromCustomerBalance", 0);
    }
  };

  const handleSupplyNow = (value) => {
    formik.setFieldValue("printWaybill", false);
  };

  const getSaleRep = async () => {
    if (isEmpty(tableData)) {
      return toast.error("No item Selected");
    }
    const { Sale_Rep_Name, Sale_Rep } = await SelectSaleRepDialog({
      selectedCustomer,
      invoiceCat: formik.values.invoiceCat,
    });

    if (Sale_Rep) {
      formik.setFieldValue("Sale_Rep", Sale_Rep);
      formik.setFieldValue("Sale_Rep_Name", Sale_Rep_Name);
    }
  };

  const getSaleRepDialog = async ({ selectedCustomer, invoiceCat }) => {
    const { Sale_Rep_Name, Sale_Rep } = await SelectSaleRepDialog({
      selectedCustomer,
      invoiceCat,
    });

    if (Sale_Rep) {
      formik.setFieldValue("Sale_Rep", Sale_Rep);
      formik.setFieldValue("Sale_Rep_Name", Sale_Rep_Name);
    }
  };

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
    if (!isEmpty(tableData) && appSettings.requireSalesRep) {
      getSaleReps({
        selectedCustomer: customer,
        invoiceCat: formik.values.invoiceCat,
      });
    }

    // Reset  Selected  Data  that  may not be  theirs
    formik.setFieldValue("linkedPaymentID", "");
  };

  const setupSummary = (values) => {
    values = cloneDeep(values);
    let data =
      values.PaymentType === "Split Payment Multiple"
        ? {
          "Payment Type": values.PaymentType,
          "Amount Due": amountDue,
          Cash: values.cashAmount,
          ...values.summaryFromMultiplePayments,
          "Total Pos Charge": values.posCharge,
          "Loading Charge": values.loadingCharge,
          "Offloading Charge": values.offloadingCharge,
          "Transport Charge": values.transportCharge,
          "Total Amount Paid": totalCashFromMultiPaymentSplit,
          "Supply Now":
            invoiceType === "reoccurringinvoice"
              ? "No"
              : values?.supplyNow
                ? "Yes"
                : "No",
        }
        : values.PaymentType === "Split Payment"
          ? {
            "Payment Type": values.PaymentType,
            "Amount Due": amountDue,
            "Pos Charge": values.posCharge,
            "Loading Charge": values.loadingCharge,
            "Offloading Charge": values.offloadingCharge,
            "Transport Charge": values.transportCharge,
            Cash: values.cashAmount,
            [values?.splitPaymentType]: values.amount,
            "Supply Now":
              invoiceType === "reoccurringinvoice"
                ? "No"
                : values?.supplyNow
                  ? "Yes"
                  : "No",
          }
          : {
            "Payment Type": values?.PaymentType,
            "Amount Due": amountDue,
            "Pos Charge": values.posCharge,
            "Loading Charge": values.loadingCharge,
            "Offloading Charge": values.offloadingCharge,
            "Transport Charge": values.transportCharge,
            [`${values.PaymentType}${["Credit/Debit Card", "Direct Bank Transfer", "Cheque"].includes(
              values.PaymentType
            )
              ? ` - ${values.BankName}`
              : ""
              }`]: values.amountPaid,
            "Supply Now":
              invoiceType === "reoccurringinvoice"
                ? "No"
                : values?.supplyNow
                  ? "Yes"
                  : "No",
          };

    for (const key in data) {
      if (!Boolean(data[key])) {
        delete data[key];
      }
    }

    return data;
  };

  const getBatches = async (selectedItemFromPopover) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/items/batches-for-sales/${selectedItemFromPopover.Bar_Code
        }`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { batches = [], sizes = [], item = {} },
        } = await response.json();
        if (item) {
          quickAddItem({
            ...selectedItemFromPopover,
            ...item,
            UnitCost: !isEmpty(batches) ? batches[0].UnitCost : item.UnitCost,
            batches,
            sizes,
          });
        } else {
          toast.error("Item not found");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to load batches, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  const getBySerialNumber = async ({ serialNumber }) => {
    try {
      // Check if Serial Number has already been added
      const selectedSerialNumbers = tableData
        .map((el) => el.serialNumberItems)
        .flat()
        .map((el) => el.serialNum);
      if (selectedSerialNumbers.includes(serialNumber)) {
        toast.error("Serial Number already added");
        return;
      }

      //
      setIsLoading(true);
      const getEvenIfSold = serialNumbersForEdit.includes(serialNumber);
      let response = await fetch(
        `${backendUrl}/api/items/batches-for-sales-by-serial-number/${serialNumber}?getEvenIfSold=${getEvenIfSold}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      } else {
        const {
          data: { batch = {}, item = {}, serialNumberItem = {} },
        } = await response.json();
        if (item) {
          formik.setFieldValue("Bar_Code", "");
          quickAddItemForSerialNumber({
            ...item,
            UnitCost: batch.UnitCost,
            batch,
            serialNumberItem,
          });
        } else {
          toast.error("Item not found");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(`Unable to load batches, ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const quickAddItemForSerialNumber = async (selectedItem) => {
    let quantityInStock = selectedItem.Quantity;
    let Quantity = 0;
    /* const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === selectedItem.Bar_Code
    ); */
    /*  const foundSale = tableData[foundIndex]; */

    /*  if (foundIndex === -1) { */
    Quantity = 1;
    selectedItem.batches = [selectedItem.batch];
    selectedItem.serialNumberItems = [selectedItem.serialNumberItem];
    /* } else {
      Quantity = Number(tableData[foundIndex].Quantity) + 1;
      selectedItem.batches = [...foundSale.batches, selectedItem.batch];
      selectedItem.serialNumberItems = [
        ...foundSale.serialNumberItems,
        selectedItem.serialNumberItem,
      ];
    } */

    const data = await addSaleItem({
      selectedItem: {
        ...selectedItem,
        Quantity,
      },
      selectedCustomer,
      itemMeasurements,
    });

    handleAddItem({ ...data, quantityInStock });
  };

  const quickAddItem = async (selectedItem) => {
    let quantityInStock = selectedItem.Quantity;
    let Quantity = 0;
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === selectedItem.Bar_Code
    );

    if (foundIndex === -1) {
      Quantity = 1;
    } else {
      Quantity = Number(tableData[foundIndex].Quantity) + 1;
    }

    const data = await addSaleItem({
      selectedItem: {
        ...selectedItem,
        Quantity,
      },
      selectedCustomer,
      itemMeasurements,
    });

    handleAddItem({ ...data, quantityInStock });
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "₦";
  }, [formik.values.currency]);

  const handleLinkPayment = async (selected) => {
    const setShipTo = () => {
      //walk-In
      if (selectedCustomer?.Cust_ID === "000101") {
        formik.setFieldValue("ShipTo", selected.Remark);
      } else {
        formik.setFieldValue("ShipTo", customerFullName(selectedCustomer));
      }
      formik.setFieldValue("linkedPaymentID", selected?.value);

      if (selected?.value) {
        handlePaymentMethod("Customer Account");
      }
    };

    if (selected?.requiresApproval) {
      if (
        await ConfirmDialog({
          description:
            "Payment requires PERMISSION and cannot be posted would you like to post it for confirmation?",
        })
      ) {
        setShipTo();
      }
    } else {
      setShipTo();
    }
  };

  // Subtotal and Charges
  const salesIncome = useMemo(() => {
    return (
      currency(subTotal)
        //  .add(taxValue)
        .add(chargesAfterTax).value
    );
  }, [chargesAfterTax, subTotal, taxValue]);

  const isEdit = useMemo(() => ["edit", "edit-preorder"].includes(mode), [
    mode,
  ]);

  const creditLines = useMemo(() => {
    try {
      if (generalSettings?.insuranceCreditLines) {
        let lines = JSON.parse(generalSettings.insuranceCreditLines);
        if (isEmpty(lines)) {
          return [];
        }
        lines = lines.map((line) => ({
          ...line,
          label: line.creditLine,
          value: line.creditLine,
        }));

        lines = [{ label: "Credit", value: "Credit" }].concat(lines);

        return lines;
      } else {
        return [];
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  }, [generalSettings?.insuranceCreditLines]);

  useEffect(() => {
    if (Number(discount) > 0) {
      formik.setFieldValue("generalDiscountPercentage", 0);
      formik.setFieldValue("generalDiscount", 0);
      return;
    }

    if (formik.values.generalDiscountType === "Percentage") {
      formik.setFieldValue(
        "generalDiscount",
        currency(subTotal)
          .subtract(discount)
          .multiply(formik.values.generalDiscountPercentage)
          .divide(100)
      );
    }
  }, [formik.values.generalDiscountPercentage, subTotal, discount]);

  const fetchItems = async (inputValue, callback) => {
    let response = await fetch(
      `${backendUrl}/api/items?page=1&Limit=50&q=${inputValue}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    if (data.items)
      callback(
        (data.items = data.items.map((el) => ({
          ...el,
          label: el.Item_Name,
          value: el.Item_Name,
          Warrant_Duration: "",
        })))
      );
  };

  const debouncedFetchItems = debounce(fetchItems, 500);

  const loadOptions = (inputValue, callback) => {
    debouncedFetchItems(inputValue, callback);
  };

  return (
    <main className="create-invoice">
      <InvoiceInnerPageLayout
        name={
          mode === "edit"
            ? "Edit Invoice"
            : mode === "preorder"
              ? "Pre-order"
              : mode === "edit-preorder"
                ? "Edit Pre-order"
                : invoiceType === "reoccurringinvoice"
                  ? "Create Reoccurring Invoice"
                  : "Create Invoice"
        }
        description={
          mode === "edit"
            ? "Edit a transaction invoice for your customer.."
            : mode === "preorder" || mode === "edit-preorder"
              ? ""
              : "Create a new transaction invoice for your customer.."
        }
        icon={<InvoicePageHeaderIcon />}
      />
      <div className="p-3 content">
        <div className="d-md-flex content-holder bg-transparent  rounded gap-4  ">
          <section className="item-details border rounded-4 bg-white">
            <div>
              {/* <header className="d-flex justify-content-between">
                <div>
                  {mode === "preorder" ? (
                    <h1>Pre-order</h1>
                  ) : mode === "edit-preorder" ? (
                    <h1>Edit Pre-order {Trans_ID ? `#${Trans_ID}` : ""}</h1>
                  ) : (
                    <h1>Invoice {Trans_ID ? `#${Trans_ID}` : ""}</h1>
                  )}

                  <span>
                    {formik.values.jobNumber
                      ? `-  ${formik.values.jobNumber}`
                      : ""}
                  </span>
                </div>

                <div>
                  {!mode &&
                  generalSettings?.inventorySelector === "Multiple List" ? (
                    <ImportCartItems
                      handleAddItem={handleAddItem}
                      mode={mode}
                      previouslySelectedItems={tableData}
                    />
                  ) : null}
                </div>
              </header> */}
              <div className="actions bg-transparent">
                <div>
                  <h2>Item Details</h2>
                  <p>Add items in to the invoice table. </p>
                </div>
                <div className="d-flex gap-3">
                  <Form.Group>
                    <Select
                      classNamePrefix="form-select"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "14px",
                        }),
                      }}
                      placeholder="Select Currency"
                      className=" rounded-4"
                      isSearchable={true}
                      options={currenciesOptions}
                      value={currenciesOptions.find(
                        (el) => el.value === formik.values.currency
                      )}
                      onChange={(selected) => {
                        // console.log(selected);
                        formik.setFieldValue("currency", selected.value);
                        formik.setFieldValue(
                          "conversionAmount",
                          Number(selected.conversionAmount)
                        );
                        if (
                          selected.value !== generalSettings?.prevailingCurrency
                        )
                          handlePaymentMethod("Credit");
                      }}
                      isDisabled={formik.values.jobNumber}
                    />
                  </Form.Group>

                  {!isEmpty(formik.values?.currency) &&
                    formik.values?.currency !=
                    generalSettings?.prevailingCurrency ? (
                    <Form.Group className=" rounded-4">
                      <CurrencyCustomInput
                        currencySymbol={"Ex. Rate"}
                        name="conversionAmount"
                        value={formik.values.conversionAmount}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        placeholder="0.00"
                      />
                    </Form.Group>
                  ) : null}
                  <Form.Group className=" rounded-4">
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Search Criteria"
                      className=" rounded-4"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "14px",
                        }),
                      }}
                      isSearchable={false}
                      options={itemSearchCriteriaOptions}
                      value={itemSearchCriteriaOptions.find(
                        (el) => el.value === formik.values.itemSearchCriteria
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("itemSearchCriteria", value);
                      }}
                    />
                  </Form.Group>
                  {!formik.values.jobNumber && (
                    <>
                      {mode != "edit" ||
                        formik.values.itemSearchCriteria === "Serial Number" ? (
                        <Form.Group className="">
                          <Form.Label className="d-none">Item Code</Form.Label>
                          <div className="global-search-area rounded-4">
                            <Popover
                              isOpen={showItemsPopover}
                              onClickOutside={() => setShowItemsPopover(false)}
                              content={() => (
                                <ItemsTable
                                  mustFind={serialNumbersForEdit}
                                  handleSelectedItem={(item) => {
                                    if (
                                      formik.values.itemSearchCriteria ===
                                      "Serial Number"
                                    ) {
                                      getBySerialNumber({
                                        serialNumber: item?.serialNum,
                                      });
                                    } else {
                                      formik.setFieldValue(
                                        "Bar_Code",
                                        item.Bar_Code
                                      );
                                      getBatches(item);
                                    }
                                    setShowItemsPopover(false);
                                  }}
                                  serialNumbers={
                                    formik.values.itemSearchCriteria ===
                                    "Serial Number"
                                  }
                                />
                              )}
                              position="bottom"
                            >
                              <MagnifyIcon
                                onClick={() => {
                                  if (appSettings.hasIncentive) {
                                    toast.info(
                                      "Not Allowed for Incentive Invoicing, use '+ Add Item'"
                                    );
                                    return;
                                  }
                                  if (appSettings.requireSalesRep) {
                                    toast.info(
                                      "Not Allowed for Sales Rep Invoicing, use '+ Add Item'"
                                    );
                                    return;
                                  }
                                  setShowItemsPopover(!showItemsPopover);
                                }}
                              />
                            </Popover>

                            <Form.Control
                              type="text"
                              placeholder={`Enter ${formik.values.itemSearchCriteria
                                }`}
                              className=" rounded-4"
                              name="Bar_Code"
                              value={formik.values.Bar_Code}
                              onChange={formik.handleChange}
                              isInvalid={
                                formik.touched.Bar_Code &&
                                !!formik.errors.Bar_Code
                              }
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (
                                    formik.values.itemSearchCriteria ===
                                    "Serial Number"
                                  ) {
                                    getBySerialNumber({
                                      serialNumber: e.target.value,
                                    });
                                    return;
                                  }
                                  getBatches({ Bar_Code: e.target.value });
                                }
                              }}
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.Bar_Code}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : null}

                      {/* {formik.values.itemSearchCriteria !== "Serial Number" && (
                        <Button
                          onClick={() => setShowItemSelectorModal(true)}
                          variant="primary"
                          className="text-nowrap bg-primary-invoice-btn rounded-4"
                        >
                          + Add Item
                        </Button>
                      )} */}
                    </>
                  )}
                </div>
              </div>

              <div className="selected-data-area">
                <div className="table-holder">
                  <Table
                    responsive
                    borderless
                    hover
                    className="product-table  text-nowrap"
                  >
                    <thead className=" bg-white border-top border-bottom">
                      <tr>
                        <th>Item Name</th>
                        <th>Item Code</th>

                        {/* {generalSettings?.itemsRequiresSerialNumber ? (
                          <th>Serial Number</th>
                        ) : (
                          <th>Size/Desc</th>
                        )} */}

                        {formik.values?.jobNumber ? (
                          <th>Number of Days</th>
                        ) : null}
                        <th>Price Sold</th>
                        <th>
                          <QuantityLabel />
                        </th>
                        <th>Subtotal</th>
                        <th>Discount</th>
                        <th>Action</th>
                        {/* <th>Product name</th> */}
                        {/* <th>Unit Price</th> */}
                        {/* <th>Profit</th> */}
                        {/*  <th>...</th>
                        <th>...</th>
                        <th>Overwrite officer</th> */}
                        {/* <th>Cost</th> */}
                        {/* <th>Type</th> */}
                        {/* <th>Incentive</th> */}
                        {/* <th>Selected Batches</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <FormikProvider value={formik}>
                        <FieldArray
                          name="inlineItems"
                          render={(arrayHelpers) => (
                            <>
                              {formik.values.inlineItems.map((el, index) => (
                                <>
                                  <tr>
                                    <td>
                                      <Form.Group className="w-100">
                                        <AsyncCreatableSelect
                                          classNamePrefix="form-select"
                                          menuPosition="fixed"
                                          value={{
                                            label:
                                              formik.values.inlineItems[index]
                                                .Item_Name,
                                            value:
                                              formik.values.inlineItems[index]
                                                .Item_Name,
                                          }}
                                          onChange={(selected) => {
                                            if (selected?.__isNew__) {
                                              formik.setFieldValue(
                                                `inlineItems.${index}`,
                                                {
                                                  ...initialServiceItem(),
                                                  Item_Name: selected.value,
                                                  isNew: true,
                                                }
                                              );
                                            } else {
                                              if (
                                                formik.values.currency &&
                                                formik.values.currency !==
                                                generalSettings?.prevailingCurrency
                                              ) {
                                                selected = convertFromBaseCurrency(
                                                  {
                                                    data: selected,
                                                    conversionAmount:
                                                      formik.values
                                                        .conversionAmount,
                                                  }
                                                );
                                              }

                                              //  PriceSold: Number(

                                              formik.setFieldValue(
                                                `inlineItems.${index}`,
                                                {
                                                  ...selected,
                                                  isNew: false,
                                                  Quantity: 1,
                                                  SubTotal:
                                                    Number(selected.UnitPrice) *
                                                    1,

                                                  PriceSold: Number(
                                                    selected.UnitPrice
                                                  ),
                                                }
                                              );

                                              // formik.setFieldValue(
                                              //   `inlineItems.${index}.Item_Name`,
                                              //   selected.Item_Name
                                              // );
                                              // formik.setFieldValue(
                                              //   `inlineItems.${index}.Bar_Code`,
                                              //   selected.Bar_Code
                                              // );
                                              // formik.setFieldValue(
                                              //   `inlineItems.${index}.PriceSold`,
                                              //   Number(selected.UnitPrice)
                                              // );
                                              // formik.setFieldValue(
                                              //   `inlineItems.${index}.Quantity`,
                                              //   1
                                              // );
                                              // formik.setFieldValue(
                                              //   `inlineItems.${index}.SubTotal`,
                                              //   Number(selected.UnitPrice) * 1
                                              // );

                                              // formik.setFieldValue(
                                              //   `inlineItems.${index}.Discount`,
                                              //   0.0
                                              // );
                                              // formik.setFieldValue(
                                              //   `inlineItems.${index}.hasBatch`,
                                              //   true
                                              // );
                                            }
                                          }}
                                          defaultOptions={true}
                                          isSearchable={true}
                                          loadOptions={loadOptions}
                                        />
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <Form.Group className="w-100">
                                        <Form.Control
                                          className="w-100"
                                          type="text"
                                          name={`inlineItems.${index}.Bar_Code`}
                                          value={
                                            formik.values.inlineItems[index]
                                              .Bar_Code
                                          }
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `inlineItems.${index}.Bar_Code`,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <Form.Group className="w-100">
                                        <div style={{ maxWidth: "8rem" }}>
                                          <CustomInvoiceNumberInput
                                            placeholder=""
                                            value={
                                              formik.values.inlineItems[index]
                                                .PriceSold
                                            }
                                            onValueChange={(value, name) => {
                                              formik.setFieldValue(
                                                `inlineItems.${index}.PriceSold`,
                                                value
                                              );
                                              formik.setFieldValue(
                                                `inlineItems.${index}.SubTotal`,
                                                Number(value) *
                                                Number(
                                                  formik.values.inlineItems[
                                                    index
                                                  ].Quantity
                                                )
                                              );
                                            }}
                                          />
                                        </div>
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <Form.Group className="w-100">
                                        <div style={{ maxWidth: "8rem" }}>
                                          <CustomInvoiceNumberInput
                                            placeholder=""
                                            value={
                                              formik.values.inlineItems[index]
                                                .Quantity
                                            }
                                            onValueChange={(value, name) => {
                                              formik.setFieldValue(
                                                `inlineItems.${index}.Quantity`,
                                                value
                                              );

                                              formik.setFieldValue(
                                                `inlineItems.${index}.SubTotal`,
                                                Number(value) *
                                                Number(
                                                  formik.values.inlineItems[
                                                    index
                                                  ].PriceSold
                                                )
                                              );
                                            }}
                                          />
                                        </div>
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <Form.Group className="w-100">
                                        <Form.Control
                                          className="w-100"
                                          type="text"
                                          readOnly
                                          name={`inlineItems.${index}.SubTotal`}
                                          value={currency(
                                            formik.values.inlineItems[index]
                                              .SubTotal
                                          ).format({
                                            separator: ",",
                                            symbol: "",
                                          })}
                                          onChange={(e) => {
                                            formik.setFieldValue(
                                              `inlineItems.${index}.SubTotal`,
                                              e.target.value
                                            );
                                          }}
                                        />
                                      </Form.Group>
                                    </td>
                                    <td>
                                      <Form.Group className="w-100">
                                        <div style={{ maxWidth: "8rem" }}>
                                          <CustomInvoiceNumberInput
                                            placeholder=""
                                            value={
                                              formik.values.inlineItems[index]
                                                .Discount
                                            }
                                            onValueChange={(value, name) => {
                                              formik.setFieldValue(
                                                `inlineItems.${index}.Discount`,
                                                value
                                              );
                                            }}
                                          />
                                        </div>
                                      </Form.Group>
                                    </td>

                                    <td>
                                      <Button
                                        variant=""
                                        type="button"
                                        onClick={() => {
                                          handleRemoveItem(index);

                                          // arrayHelpers.remove(index)
                                        }}
                                      >
                                        <TrashIcon />
                                      </Button>
                                    </td>
                                  </tr>
                                </>
                              ))}

                              <tr className="">
                                <td colSpan={6}>
                                  <Button
                                    variant="light-blue"
                                    className=""
                                    onClick={() =>
                                      arrayHelpers.push({
                                        ...initialServiceItem(),
                                      })
                                    }
                                  >
                                    + Add
                                  </Button>
                                </td>
                              </tr>
                            </>
                          )}
                        />
                      </FormikProvider>
                    </tbody>
                  </Table>
                </div>
                {/*  */}

                {/* <Popover
                  isOpen={showItemsPopover}
                  reposition={true}
                  onClickOutside={() => setShowItemsPopover(false)}
                  content={() => (
                    <ItemsTable
                      hideItemsNotInStock={true}
                      handleSelectedItem={handleSelectedItem}
                    />
                  )}
                >
                  <button
                    onClick={() => setShowItemsPopover(!showItemsPopover)}
                    className="btn text-primary my-3 d-flex align-items-center gap-2"
                  >
                    <AddCircleIcon />
                    Add an Item
                  </button>
                </Popover> */}

                {/*  No item  */}
                {isEmpty(formik.values.inlineItems) ? (
                  <div className="no-item my-4">
                    <div className="info">
                      <NoSelectedItemIcon />
                      <h2 className="mb-2">Haven't selected an item yet</h2>
                      <p>
                        You can click +Add Item Button to add an item to the
                        table.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-end total-info">
              <table className="table table-borderless balance">
                <tbody>
                  <tr>
                    <td>Total Amount</td>
                    <td>
                      {currency(subTotal, {
                        symbol: currencySymbol,
                      })
                        .add(chargesAfterTax)
                        .format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Discount</td>
                    <td>
                      {currency(discount, {
                        symbol: currencySymbol,
                      })
                        .add(formik.values.generalDiscount)
                        .format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Subtotal</td>
                    <td>
                      {currency(subTotal, {
                        symbol: currencySymbol,
                      })
                        .add(chargesAfterTax)
                        .subtract(discount)
                        .subtract(formik.values.generalDiscount)
                        .format()}
                    </td>
                  </tr>

                  {formik.values?.taxType !== "None" && (
                    <tr>
                      <td>{formik.values.taxType}</td>
                      <td>
                        {currency(taxValue, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>Amount Due</td>
                    <td>
                      {currency(amountDue, {
                        symbol: currencySymbol,
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Balance</td>
                    <td>
                      {currency(balance, {
                        symbol: currencySymbol,
                      }).format()}
                    </td>
                  </tr>

                  <tr>
                    <td>Total</td>
                    <td>
                      {currency(grandTotal, {
                        symbol: currencySymbol,
                      }).format()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className="customer border bg-white rounded-4">
            <h2>Customer Name</h2>

            <div className="d-flex justify-content-between">
              <div className="avatar">
                <NewUserIcon />
              </div>
              {selectedCustomer ? (
                <div className="customer-actions d-flex justify-content-between flex-grow-1">
                  <div>
                    <h3>{selectedCustomer?.LastName}</h3>
                    <p>{selectedCustomer.Cust_ID}</p>
                  </div>

                  <div>
                    {!formik.values.pendingTransaction && (
                      <Dropdown style={{ margin: 0 }}>
                        <Dropdown.Toggle
                          variant=""
                          className=" text-primary  border rounded-2"
                          bsPrefix="change"
                        >
                          Change
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className=""
                        >
                          <Dropdown.Item
                            as="button"
                            onClick={() => setShowCustomerSelectorModal(true)}
                          >
                            Select Customer
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => setShowCreateNewCustomerModal(true)}
                          >
                            Create New Customer
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
              ) : (
                <div className="customer-actions flex-grow-1">
                  <h3>No customer selected</h3>
                  <p>Select customer or create new customer.</p>

                  <div className="d-grid mt-4">
                    <Button
                      onClick={() => setShowCustomerSelectorModal(true)}
                      variant="outline-primary"
                    >
                      Select Customer
                    </Button>
                    <Button
                      onClick={() => setShowCreateNewCustomerModal(true)}
                      variant="outline-primary"
                    >
                      + Create New Customer
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <section>
              <Form.Group className="form-mb align-items-center">
                <Form.Label className="mb-1 text-nowrap fw-bold">
                  Ship To.
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ship To"
                  name="ShipTo"
                  value={formik.values.ShipTo}
                  onChange={formik.handleChange}
                  /*   onKeyDown={(e) => {
                      if (
                        e.keyCode === 13 &&
                        generalSettings?.linkPaymentToInvoice
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                        paymentsDataQuery.refetch();
                      }
                    }} */
                  isInvalid={formik.touched.ShipTo && !!formik.errors.ShipTo}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.ShipTo}
                </Form.Control.Feedback>
              </Form.Group>
            </section>
            <hr />

            <div>
              <FormikProvider value={formik}>
                <Form
                  noValidate
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                >
                  {generalSettings?.linkPaymentToInvoice && (
                    <Form.Group className="form-mb align-items-center">
                      <Form.Label className="mb-1 text-nowrap fw-bold">
                        Link Payment to Invoice
                      </Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={paymentsData?.payments}
                        value={paymentsData?.payments.find(
                          (el) => el.value === formik.values.linkedPaymentID
                        )}
                        onChange={(selected) => {
                          handleLinkPayment(selected);
                        }}
                        isClearable={true}
                        isLoading={paymentsDataQuery.isFetching}
                        isDisabled={formik.values.pendingTransaction}
                      />
                    </Form.Group>
                  )}
                  <>
                    {formik.values.milestone && (
                      <Form.Group className="form-mb align-items-center">
                        <Form.Label>Milestone</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          value={{
                            value: formik.values.milestone,
                            label: formik.values.milestone,
                          }}
                        />
                      </Form.Group>
                    )}
                  </>

                  {generalSettings?.deliveryInformationOnInvoice && (
                    <section className="date">
                      <CustomLoadingOrderInput
                        formik={formik}
                        isCreateInvoice={true}
                        drivers={data?.drivers || []}
                        stage={[
                          "edit pending",
                          "on transit",
                          "delivered",
                          "disabled",
                        ]}
                      />
                    </section>
                  )}
                  <section className="date">
                    <h2>Invoice Date</h2>

                    <div className="row">
                      <Form.Group className="form-mb col-md-6">
                        <Form.Label>Sales Date</Form.Label>
                        <Datetime
                          timeFormat={false}
                          closeOnSelect={true}
                          closeOnClickOutside={true}
                          dateFormat="MMM DD, YYYY"
                          name="salesDate"
                          inputProps={{
                            className: `date-input form-control ${formik.touched.salesDate &&
                              !!formik.errors.salesDate
                              ? "is-invalid"
                              : ""
                              }`,
                            placeholder: "Select date",
                            readOnly: true,
                          }}
                          value={formik.values.salesDate}
                          onChange={(date) => {
                            formik.setFieldValue("salesDate", date, true);
                          }}
                          onBlur={() =>
                            formik.setFieldTouched("salesDate", true)
                          }
                        />
                      </Form.Group>
                      <Form.Group className="form-mb col-md-6">
                        <Form.Label>Invoice Due In</Form.Label>
                        <DateTime
                          timeFormat={false}
                          closeOnSelect={true}
                          closeOnClickOutside={true}
                          dateFormat="MMM DD, YYYY"
                          inputProps={{
                            className: `date-input form-control`,
                            placeholder: "Select date",
                            readOnly: true,
                          }}
                          value={formik.values.ManDate}
                          onChange={(date) => {
                            formik.setFieldValue("ManDate", date, true);
                          }}
                        />
                      </Form.Group>

                      {invoiceType === "reoccurringinvoice" && (
                        <Form.Group className="form-mb col-md-6">
                          <Form.Label>Interval</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            placeholder="Enter Interval"
                            className=" rounded-4"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: "5px",
                              }),
                            }}
                            isSearchable={false}
                            options={intervalSearchCriteriaOptions}
                            value={intervalSearchCriteriaOptions.find(
                              (el) => el.value === formik.values.interval
                            )}
                            onChange={({ value }) => {
                              if (value === "Daily") {
                                formik.setFieldValue("every", 1);
                                formik.setFieldValue(
                                  "reoccurringWeekOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnDayOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnNotDayOption",
                                  ""
                                );
                                formik.setFieldValue("everyMonth", "");
                                formik.setFieldValue(
                                  "reoccurringYearlyOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringYearlyOnDayOption",
                                  ""
                                );
                                formik.setFieldValue("reoccurringEndDate", "");

                                formik.setFieldValue(
                                  "reoccurringOccurrence",
                                  ""
                                );
                              }
                              if (value === "Weekly") {
                                formik.setFieldValue("every", 1);
                                formik.setFieldValue(
                                  "reoccurringWeekOption",
                                  "Monday"
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnDayOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnNotDayOption",
                                  ""
                                );
                                formik.setFieldValue("everyMonth", "");
                                formik.setFieldValue(
                                  "reoccurringYearlyOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringYearlyOnDayOption",
                                  ""
                                );
                                formik.setFieldValue("reoccurringEndDate", "");

                                formik.setFieldValue(
                                  "reoccurringOccurrence",
                                  ""
                                );
                              }
                              if (value === "Monthly") {
                                formik.setFieldValue("every", "");
                                formik.setFieldValue(
                                  "reoccurringWeekOption",
                                  "Monday"
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnOption",
                                  "Day"
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnDayOption",
                                  "1st"
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnNotDayOption",
                                  ""
                                );
                                formik.setFieldValue("everyMonth", 1);
                                formik.setFieldValue(
                                  "reoccurringYearlyOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringYearlyOnDayOption",
                                  ""
                                );
                                formik.setFieldValue("reoccurringEndDate", "");

                                formik.setFieldValue(
                                  "reoccurringOccurrence",
                                  ""
                                );
                              }
                              if (value === "Yearly") {
                                formik.setFieldValue("every", "");
                                formik.setFieldValue(
                                  "reoccurringWeekOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnDayOption",
                                  ""
                                );
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnNotDayOption",
                                  ""
                                );
                                formik.setFieldValue("everyMonth", "");
                                formik.setFieldValue(
                                  "reoccurringYearlyOption",
                                  "January"
                                );
                                formik.setFieldValue(
                                  "reoccurringYearlyOnDayOption",
                                  "1st"
                                );

                                formik.setFieldValue("reoccurringEndDate", "");

                                formik.setFieldValue(
                                  "reoccurringOccurrence",
                                  ""
                                );
                              }
                              formik.setFieldValue(
                                "reoccurringStartDate",
                                moment(new Date())
                              );
                              formik.setFieldValue("interval", value);
                            }}
                          />
                          {formik.touched.interval && formik.errors.interval ? (
                            <div className=" text-danger">
                              {formik.errors.interval}
                            </div>
                          ) : null}
                        </Form.Group>
                      )}

                      {invoiceType === "reoccurringinvoice" &&
                        ["daily", "weekly"].includes(
                          formik.values.interval?.toLowerCase()
                        ) && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label>
                              Every{" "}
                              {formik.values.interval?.toLowerCase() ===
                                "weekly"
                                ? "week"
                                : "day"}
                              (s)
                            </Form.Label>
                            <CustomInvoiceNumberInput
                              placeholder=""
                              value={formik.values.every}
                              onValueChange={(value, name) => {
                                formik.setFieldValue("every", value);
                              }}
                            />
                            {formik.touched.every && formik.errors.every ? (
                              <div className=" text-danger">
                                {formik.errors.every}
                              </div>
                            ) : null}
                          </Form.Group>
                        )}

                      {invoiceType === "reoccurringinvoice" &&
                        formik.values.interval?.toLowerCase() === "monthly" && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label>On</Form.Label>
                            <Select
                              classNamePrefix="form-select"
                              placeholder=""
                              className=" rounded-4"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderRadius: "5px",
                                }),
                              }}
                              isSearchable={false}
                              options={reoccurringMonthlyOnOptions}
                              value={reoccurringMonthlyOnOptions.find(
                                (el) =>
                                  el.value ===
                                  formik.values.reoccurringMonthlyOnOption
                              )}
                              onChange={({ value }) => {
                                if (!value.includes("Day")) {
                                  formik.setFieldValue("every", "");
                                  formik.setFieldValue(
                                    "reoccurringWeekOption",
                                    ""
                                  );

                                  formik.setFieldValue(
                                    "reoccurringMonthlyOnDayOption",
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "reoccurringMonthlyOnNotDayOption",
                                    "Monday"
                                  );
                                  formik.setFieldValue("everyMonth", 1);
                                  formik.setFieldValue(
                                    "reoccurringYearlyOption",
                                    ""
                                  );
                                  formik.setFieldValue(
                                    "reoccurringYearlyOnDayOption",
                                    ""
                                  );
                                }

                                formik.setFieldValue(
                                  "reoccurringMonthlyOnOption",
                                  value
                                );
                              }}
                            />
                          </Form.Group>
                        )}
                      {invoiceType === "reoccurringinvoice" &&
                        formik.values.interval?.toLowerCase() === "monthly" &&
                        formik.values.reoccurringMonthlyOnOption?.toLowerCase() ===
                        "day" && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label className=" opacity-0">at</Form.Label>
                            <Select
                              classNamePrefix="form-select"
                              placeholder=""
                              className=" rounded-4"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderRadius: "5px",
                                }),
                              }}
                              isSearchable={false}
                              options={reoccurringMonthlyOnDayOptions}
                              value={reoccurringMonthlyOnDayOptions.find(
                                (el) =>
                                  el.value ===
                                  formik.values.reoccurringMonthlyOnDayOption
                              )}
                              onChange={({ value }) => {
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnDayOption",
                                  value
                                );
                              }}
                            />
                          </Form.Group>
                        )}
                      {invoiceType === "reoccurringinvoice" &&
                        formik.values.interval?.toLowerCase() === "monthly" &&
                        !formik.values.reoccurringMonthlyOnOption
                          ?.toLowerCase()
                          .includes("day") && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label className=" opacity-0">at</Form.Label>
                            <Select
                              classNamePrefix="form-select"
                              placeholder=""
                              className=" rounded-4"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderRadius: "5px",
                                }),
                              }}
                              isSearchable={false}
                              options={reoccurringWeekOptions}
                              value={reoccurringWeekOptions.find(
                                (el) =>
                                  el.value ===
                                  formik.values.reoccurringMonthlyOnNotDayOption
                              )}
                              onChange={({ value }) => {
                                formik.setFieldValue(
                                  "reoccurringMonthlyOnNotDayOption",
                                  value
                                );
                              }}
                            />
                          </Form.Group>
                        )}
                      {invoiceType === "reoccurringinvoice" &&
                        formik.values.interval?.toLowerCase() === "monthly" && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label>Every Month(s)</Form.Label>
                            <CustomInvoiceNumberInput
                              placeholder=""
                              value={formik.values.everyMonth}
                              onValueChange={(value, name) => {
                                formik.setFieldValue("everyMonth", value);
                              }}
                            />
                          </Form.Group>
                        )}
                      {invoiceType === "reoccurringinvoice" &&
                        formik.values.interval?.toLowerCase() === "weekly" && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label>Weeks (on)</Form.Label>
                            <Select
                              classNamePrefix="form-select"
                              placeholder=""
                              className=" rounded-4"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderRadius: "5px",
                                }),
                              }}
                              isSearchable={false}
                              options={reoccurringWeekOptions}
                              value={reoccurringWeekOptions.find(
                                (el) =>
                                  el.value ===
                                  formik.values.reoccurringWeekOption
                              )}
                              onChange={({ value }) => {
                                formik.setFieldValue(
                                  "reoccurringWeekOption",
                                  value
                                );
                              }}
                            />

                            {formik.touched.reoccurringWeekOption &&
                              formik.errors.reoccurringWeekOption ? (
                              <div className=" text-danger">
                                {formik.errors.reoccurringWeekOption}
                              </div>
                            ) : null}
                          </Form.Group>
                        )}
                      {invoiceType === "reoccurringinvoice" &&
                        formik.values.interval?.toLowerCase() === "yearly" && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label>Every</Form.Label>
                            <Select
                              classNamePrefix="form-select"
                              placeholder=""
                              className=" rounded-4"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderRadius: "5px",
                                }),
                              }}
                              isSearchable={false}
                              options={reoccurringYearlyOptions}
                              value={reoccurringYearlyOptions.find(
                                (el) =>
                                  el.value ===
                                  formik.values.reoccurringYearlyOption
                              )}
                              onChange={({ value }) => {
                                formik.setFieldValue(
                                  "reoccurringYearlyOption",
                                  value
                                );
                              }}
                            />
                          </Form.Group>
                        )}

                      {invoiceType === "reoccurringinvoice" &&
                        formik.values.interval?.toLowerCase() === "yearly" && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label className=" opacity-0">at</Form.Label>
                            <Select
                              classNamePrefix="form-select"
                              placeholder=""
                              className=" rounded-4"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderRadius: "5px",
                                }),
                              }}
                              isSearchable={false}
                              options={reoccurringMonthlyOnDayOptions}
                              value={reoccurringMonthlyOnDayOptions.find(
                                (el) =>
                                  el.value ===
                                  formik.values.reoccurringYearlyOnDayOption
                              )}
                              onChange={({ value }) => {
                                formik.setFieldValue(
                                  "reoccurringYearlyOnDayOption",
                                  value
                                );
                              }}
                            />
                          </Form.Group>
                        )}

                      {invoiceType === "reoccurringinvoice" && (
                        <Form.Group className="form-mb col-md-6">
                          <Form.Label>Start Date</Form.Label>
                          <DateTime
                            timeFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside={true}
                            dateFormat="MMM DD, YYYY"
                            inputProps={{
                              className: `date-input form-control`,
                              placeholder: "Select date",
                              readOnly: true,
                            }}
                            value={formik.values.reoccurringStartDate}
                            onChange={(date) => {
                              formik.setFieldValue(
                                "reoccurringStartDate",
                                date,
                                true
                              );
                            }}
                          />

                          {formik.touched.reoccurringStartDate &&
                            formik.errors.reoccurringStartDate ? (
                            <div className=" text-danger">
                              {formik.errors.reoccurringStartDate}
                            </div>
                          ) : null}
                        </Form.Group>
                      )}

                      {invoiceType === "reoccurringinvoice" && (
                        <Form.Group className="form-mb col-md-6">
                          <Form.Label>End</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            placeholder="None"
                            className=" rounded-4"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: "5px",
                              }),
                            }}
                            isSearchable={false}
                            options={reoccuringEndOptions}
                            value={reoccuringEndOptions.find(
                              (el) =>
                                el.value === formik.values.reoccuringEndOption
                            )}
                            onChange={({ value }) => {
                              formik.setFieldValue(
                                "reoccuringEndOption",
                                value
                              );
                            }}
                          />
                          {formik.touched.reoccuringEndOption &&
                            formik.errors.reoccuringEndOption ? (
                            <div className=" text-danger">
                              {formik.errors.reoccuringEndOption}
                            </div>
                          ) : null}
                        </Form.Group>
                      )}

                      {invoiceType === "reoccurringinvoice" &&
                        formik.values.reoccuringEndOption?.toLowerCase() ===
                        "by" && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label>End Date</Form.Label>
                            <DateTime
                              timeFormat={false}
                              closeOnSelect={true}
                              closeOnClickOutside={true}
                              dateFormat="MMM DD, YYYY"
                              inputProps={{
                                className: `date-input form-control`,
                                placeholder: "Select date",
                                readOnly: true,
                              }}
                              value={formik.values.reoccurringEndDate}
                              onChange={(date) => {
                                formik.setFieldValue(
                                  "reoccurringEndDate",
                                  date,
                                  true
                                );
                              }}
                            />
                            {formik.touched.reoccurringEndDate &&
                              formik.errors.reoccurringEndDate ? (
                              <div className=" text-danger">
                                {formik.errors.reoccurringEndDate}
                              </div>
                            ) : null}
                          </Form.Group>
                        )}

                      {invoiceType === "reoccurringinvoice" &&
                        formik.values.reoccuringEndOption?.toLowerCase() ===
                        "after" && (
                          <Form.Group className="form-mb col-md-6">
                            <Form.Label>Occurrence</Form.Label>
                            <CustomInvoiceNumberInput
                              placeholder=""
                              value={formik.values.reoccurringOccurrence}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(
                                  "reoccurringOccurrence",
                                  value
                                );
                              }}
                            />

                            {formik.touched.reoccurringOccurrence &&
                              formik.errors.reoccurringOccurrence ? (
                              <div className=" text-danger">
                                {formik.errors.reoccurringOccurrence}
                              </div>
                            ) : null}
                          </Form.Group>
                        )}
                    </div>
                  </section>

                  <hr />
                  <section className="date">
                    <h2>Sales Information</h2>

                    <Form.Group className="form-mb">
                      <Form.Label>Sales Type</Form.Label>
                      <div className="d-flex gap-4 align-items-center">
                        <Form.Check
                          type="radio"
                          label="Retail"
                          name="salesType"
                          checked={formik.values.salesType === "Retail"}
                          onChange={(e) =>
                            formik.setFieldValue("salesType", "Retail")
                          }
                        />

                        <Form.Check
                          type="radio"
                          label="Wholesale"
                          name="salesType"
                          checked={formik.values.salesType === "Wholesale"}
                          onChange={(e) =>
                            formik.setFieldValue("salesType", "Wholesale")
                          }
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="form-mb">
                      <Form.Label>Printer Type</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Search Criteria"
                        className=" rounded-4"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "5px",
                            width: "100%",
                          }),
                        }}
                        isSearchable={false}
                        options={itemSearchCriteriaOptions}
                        value={itemSearchCriteriaOptions.find(
                          (el) => el.value === formik.values.itemSearchCriteria
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("itemSearchCriteria", value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="form-mb d-flex gap-4 ">
                      <Form.Check
                        type="checkbox"
                        label="Pin to selection"
                        name=""
                        size={"lg"}
                      />

                      <Form.Check
                        inline
                        label="Print Receipt"
                        name="printReciept"
                        type={"checkbox"}
                        checked={formik.values.printReciept}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  </section>
                  <hr />
                  <section className="date">
                    <h2>Invoice Details</h2>

                    <Form.Group className="form-mb">
                      <Form.Label>Pending Transaction</Form.Label>
                      <div className="row align-items-center">
                        <div className="col-7">
                          <Form.Control
                            type="text"
                            placeholder="..."
                            name="pendingTransaction"
                            value={formik.values.pendingTransaction}
                            readOnly
                            onChange={formik.handleChange}
                            onClick={() => setShowPermitModal(true)}
                          />
                        </div>

                        <div className="col px-0">
                          <Button
                            onClick={() => setShowPermitModal(true)}
                            variant="light-blue"
                            className="text-primary border"
                          >
                            Get Invoice Number
                          </Button>
                        </div>
                      </div>
                    </Form.Group>

                    <Row className="form-mb">
                      <Form.Group as={Col}>
                        <Form.Label>Invoice Cat </Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="Select"
                          isSearchable={false}
                          options={data.invoiceCat}
                          value={data.invoiceCat.find(
                            (el) => el.value === formik.values.invoiceCat
                          )}
                          onChange={({ value }) =>
                            formik.setFieldValue("invoiceCat", value)
                          }
                          onBlur={() =>
                            formik.setFieldTouched("invoiceCat", true)
                          }
                          className={
                            formik.touched.invoiceCat &&
                              !!formik.errors.invoiceCat
                              ? "is-invalid"
                              : ""
                          }
                        />
                        {formik.touched.invoiceCat &&
                          formik.errors.invoiceCat ? (
                          <span className="custom-invalid-feedback">
                            {formik.errors.invoiceCat}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Row>

                    {appSettings.requireSalesRep && (
                      <Form.Group as={Col} className="form-mb">
                        <Form.Label>Sale Rep</Form.Label>
                        <div className="row align-items-center">
                          <div className="col-8">
                            <Form.Control
                              type="text"
                              placeholder="..."
                              //name="saleRep"
                              value={formik.values.Sale_Rep_Name}
                              readOnly
                            />
                          </div>

                          <div className="col">
                            <Button
                              onClick={() => getSaleRep()}
                              variant="light-blue"
                              className="text-primary border"
                            >
                              Get Sale Rep
                            </Button>
                          </div>
                        </div>
                      </Form.Group>
                    )}

                    {/*   <Form.Group className="form-mb">
                  <Form.Label className="mb-1">Proforma No.</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Proforma No."
                    name="proformaNumber"
                    value={formik.values.proformaNumber}
                    onChange={formik.handleChange}
                  />
                </Form.Group> */}
                  </section>

                  <section className="date">
                    {/* <Form.Label>Sales Type</Form.Label>
                  <div
                  <Form.Label>Sales Type</Form.Label>
                  <p>{selectedCustomer?.TransType}</p>
                  {/* <div
                    key={`inline-radio`}
                    className="d-flex justify-content-between"
                  >
                    <Form.Check
                      inline
                      label="Retail"
                      name="OverwriteOfficer"
                      type={"radio"}
                      id={`OverwriteOfficer`}
                      checked={formik.values.OverwriteOfficer === "Retail"}
                      onChange={formik.handleChange}
                    />
                    <Form.Check
                      inline
                      label="Wholesale"
                      name="OverwriteOfficer"
                      type={"radio"}
                      id={`OverwriteOfficer`}
                      checked={formik.values.OverwriteOfficer === "Wholesale"}
                      onChange={formik.handleChange}
                    />
                    <Form.Check
                      inline
                      label="Distributor"
                      name="OverwriteOfficer"
                      type={"radio"}
                      id={`OverwriteOfficer`}
                      checked={formik.values.OverwriteOfficer === "Distributor"}
                      onChange={formik.handleChange}
                    />
                  </div> */}

                    <Form.Group className="mb-3 mt-2">
                      {/* <Form.Label>Printer Type</Form.Label> */}
                      {/* <Select
                      classNamePrefix="form-select"
                      placeholder="Select printer"
                      isSearchable={false}
                      value={printers.find(
                        (el) => el.value === formik.values.printer
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("printer", value)
                      }
                      options={printers}
                    /> */}

                      <div className="mt-2 form-mb d-flex gap-3">
                        <Form.Check
                          inline
                          label="Send Email"
                          name="sendEmail"
                          type={"checkbox"}
                          checked={formik.values.sendEmail}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3 mt-2">
                      <Form.Label>Bank Account on Invoice</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select"
                        isSearchable={false}
                        options={data.banks}
                        value={data.banks.find(
                          (el) => el.value === formik.values.bankOnInvoice
                        )}
                        onChange={(selected) =>
                          formik.setFieldValue("bankOnInvoice", selected?.value)
                        }
                        isClearable
                      />
                    </Form.Group>
                  </section>

                  <section className="date">
                    <hr />
                    <h2>Payment Details</h2>

                    {!formik.values.jobNumber && (
                      <section>
                        <Row
                          style={
                            Number(discount) > 0
                              ? { pointerEvents: "none" }
                              : {}
                          }
                        >
                          <Form.Group className="col-md-4 mb-3 pb-2">
                            <Form.Label className="mb-1">
                              Discount Type
                            </Form.Label>
                            <Select
                              classNamePrefix={"form-select"}
                              options={amountTypes}
                              value={amountTypes.find(
                                (el) =>
                                  el.value === formik.values.generalDiscountType
                              )}
                              onChange={(selected) => {
                                formik.setFieldValue(
                                  "generalDiscountType",
                                  selected.value
                                );
                                if (selected.value === "None") {
                                  formik.setFieldValue(
                                    "generalDiscountPercentage",
                                    0
                                  );
                                  formik.setFieldValue("generalDiscount", 0);
                                }
                              }}
                              isDisabled={Number(discount) > 0}
                            />
                          </Form.Group>

                          {formik.values.generalDiscountType ===
                            "Percentage" && (
                              <Form.Group className="col-md-3 mb-3 pb-2">
                                <Form.Label className="mb-1">
                                  Discount (%)
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  name="generalDiscountPercentage"
                                  value={formik.values.generalDiscountPercentage}
                                  onChange={formik.handleChange}
                                />
                              </Form.Group>
                            )}

                          <Form.Group className="col mb-3 pb-2">
                            <Form.Label className="mb-1"> Discount</Form.Label>
                            <CurrencyCustomInput
                              name="generalDiscount"
                              placeholder="0.00"
                              value={formik.values.generalDiscount}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                            />
                          </Form.Group>
                        </Row>
                      </section>
                    )}

                    {/* <Form.Label>Charges</Form.Label> */}

                    {!formik.values.jobNumber && (
                      <Row className="mb-3">
                        <Form.Group className="col-6 mb-3">
                          <Form.Label>Loading Charge</Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="loadingCharge"
                            placeholder="0.00"
                            value={formik.values.loadingCharge}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="col-6 mb-3">
                          <Form.Label>Offloading Charge</Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="offloadingCharge"
                            placeholder="0.00"
                            value={formik.values.offloadingCharge}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="col-12 mb-3">
                          <Form.Label>Transport Charge</Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="transportCharge"
                            placeholder="0.00"
                            value={formik.values.transportCharge}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="col-12 mb-3">
                          <Form.Label>
                            Clearing charges, Custom duty and Other Charges
                          </Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="otherCharges"
                            value={formik.values.otherCharges}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            placeholder="0.00"
                          />
                        </Form.Group>
                      </Row>
                    )}

                    <hr />

                    <section>
                      <div className="row mb-3">
                        <Form.Group className="col-6">
                          <Form.Label>Tax </Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            placeholder="Select"
                            isSearchable={false}
                            value={taxOptions.find(
                              (el) => el.value === formik.values.taxType
                            )}
                            options={taxOptions}
                            onChange={({ value }) =>
                              formik.setFieldValue("taxType", value)
                            }
                          />
                        </Form.Group>

                        <Form.Group className="col-6">
                          <Form.Label>
                            {formik.values.taxType &&
                              formik.values.taxType !== "None"
                              ? formik.values.taxType
                              : "VAT"}
                          </Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="tax"
                            value={taxValue}
                            onValueChange={(value, name) => {
                              //   formik.setFieldValue(name, value);
                            }}
                            placeholder="0.00"
                          />
                        </Form.Group>
                      </div>

                      {/* Add more TAX */}
                      <FieldArray
                        name="taxes"
                        render={(arrayHelpers) => (
                          <>
                            <div className="row">
                              {formik.values.taxes.map((el, index) => (
                                <div className="d-flex gap-3 mb-3 align-items-bottom">
                                  <Form.Group className="col-6">
                                    <Form.Label>Tax</Form.Label>
                                    <Select
                                      classNamePrefix="form-select"
                                      placeholder="Select"
                                      isSearchable={false}
                                      value={taxOptions.find(
                                        (el) =>
                                          el.value ===
                                          formik.values.taxes[index].taxType
                                      )}
                                      options={taxOptions}
                                      onChange={({ value }) => {
                                        formik.setFieldValue(
                                          `taxes[${index}].taxType`,
                                          value
                                        );
                                      }}
                                    />
                                  </Form.Group>

                                  <Form.Group className="col-6">
                                    <Form.Label>
                                      {formik.values.taxes[index].taxType}
                                    </Form.Label>
                                    <div className="d-flex">
                                      <CurrencyCustomInput
                                        currencySymbol={currencySymbol}
                                        value={
                                          otherTaxValues.find(
                                            (el) =>
                                              el.taxType ===
                                              formik.values.taxes[index].taxType
                                          )?.taxValue || 0
                                        }
                                        onValueChange={(value, name) => { }}
                                        placeholder="0.00"
                                      />
                                      <Button
                                        title="Remove"
                                        variant=""
                                        type="button"
                                        size="xs"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        ✖
                                      </Button>
                                    </div>
                                  </Form.Group>
                                </div>
                              ))}
                            </div>
                            <div className="d-flex justify-content-start">
                              <Button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    taxType: "WHT",
                                  })
                                }
                                variant="light-blue"
                                className="text-primary border"
                                size="sm"
                              >
                                + Add Tax
                              </Button>
                            </div>
                          </>
                        )}
                      />

                      <hr />

                      {generalSettings?.poNumber && (
                        <Form.Group className="form-mb">
                          <Form.Label className="mb-1">Po No.</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Po No."
                            name="poNumber"
                            value={formik.values.poNumber}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      )}
                    </section>

                    <section>
                      <div className="row">
                        <Form.Group className="col-6 mt-2 form-mb">
                          <Form.Label>Shipping Cost</Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="shippingCost"
                            value={formik.values.shippingCost}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            placeholder="0.00"
                          />
                        </Form.Group>

                        <Form.Group className="col-6 mt-2 form-mb">
                          <Form.Label>Due In (days)</Form.Label>
                          <NumberCustomInput
                            name="dueIn"
                            value={formik.values.dueIn}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            min={1}
                          />
                        </Form.Group>
                      </div>

                      <div className="row mb-3">
                        <Form.Group className="col-6">
                          <Form.Label>Payment method</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            menuPlacement="top"
                            placeholder="Choose method"
                            isSearchable={false}
                            value={
                              paymentMethod.find(
                                (el) => el.value === formik.values?.PaymentType
                              ) || ""
                            }
                            options={paymentMethod}
                            onChange={({ value }) => handlePaymentMethod(value)}
                            getOptionLabel={(el) => (
                              <div className="label-with-icon d-flex gap-2 align-items-center">
                                <span>{el.icon}</span>{" "}
                                <span className="fw-5">{el.label}</span>
                              </div>
                            )}
                          //  menuIsOpen={true}
                          />
                          {formik.touched.PaymentType &&
                            !!formik.errors.PaymentType ? (
                            <span className="custom-invalid-feedback">
                              {formik.errors.PaymentType}
                            </span>
                          ) : null}
                        </Form.Group>

                        {formik.values.PaymentType === "Split Payment" ||
                          formik.values.PaymentType ===
                          "Split Payment Multiple" ? (
                          <Form.Group className="col-6">
                            <Form.Label>Cash Amount</Form.Label>
                            <CurrencyCustomInput
                              currencySymbol={currencySymbol}
                              name="cashAmount"
                              value={formik.values.cashAmount}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                              placeholder="0.00"
                            />
                          </Form.Group>
                        ) : (
                          <Form.Group className="col-6">
                            <Form.Label>Amount Paid</Form.Label>
                            <CurrencyCustomInput
                              currencySymbol={currencySymbol}
                              name="amountPaid"
                              value={formik.values.amountPaid}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                              placeholder="0.00"
                              readOnly={
                                formik.values.PaymentType === "Customer Account"
                              }
                            />
                          </Form.Group>
                        )}

                        {formik.values.PaymentType === "Credit" &&
                          !isEmpty(creditLines) && (
                            <Form.Group className="my-3 col-md-6">
                              <Form.Label>Credit Line</Form.Label>
                              <Select
                                name="creditLine"
                                classNamePrefix="form-select"
                                options={creditLines}
                                defaultValue={{
                                  label: "Credit",
                                  value: "Credit",
                                }}
                                value={creditLines.find(
                                  (el) => el.value === formik.values.creditLine
                                )}
                                onChange={(selected) => {
                                  formik.setFieldValue(
                                    `creditLine`,
                                    selected?.value
                                  );
                                }}
                                isClearable
                              />
                            </Form.Group>
                          )}
                      </div>

                      {formik.values.PaymentType ===
                        "Split Payment Multiple" && (
                          <div className="border p-3 mb-3">
                            <Form.Group className="col-12 mb-3 form-group-holder">
                              <Form.Label>Credit/Debit Card (POS)</Form.Label>
                              <FieldArray
                                name="splitPaymentCardArray"
                                render={(arrayHelpers) => (
                                  <div>
                                    {formik.values.splitPaymentCardArray.map(
                                      (el, index) => (
                                        <div key={index} className="mb-3">
                                          <div className="row align-items-center">
                                            <div className="col-md-7 ">
                                              <CurrencyCustomInput
                                                currencySymbol={currencySymbol}
                                                name={`splitPaymentCardArray[${index}].amountPaid`}
                                                value={
                                                  formik.values
                                                    .splitPaymentCardArray[index]
                                                    .amountPaid
                                                }
                                                onValueChange={(value, name) => {
                                                  formik.setFieldValue(
                                                    name,
                                                    value
                                                  );
                                                  formik.setFieldValue(
                                                    `splitPaymentCardArray[${index}].amount`,
                                                    currency(value).add(
                                                      formik.values
                                                        .splitPaymentCardArray[
                                                        index
                                                      ].posCharge
                                                    ).value
                                                  );
                                                }}
                                                placeholder="0.00"
                                                precision={2}
                                              />
                                            </div>
                                            <div className="col-md-4 pe-0">
                                              <Select
                                                classNamePrefix={"form-select"}
                                                placeholder="Bank"
                                                options={data.banks}
                                                value={data.banks.find(
                                                  (el) =>
                                                    el.value ===
                                                    formik.values
                                                      .splitPaymentCardArray[
                                                      index
                                                    ].bank
                                                )}
                                                onChange={({ value }) =>
                                                  formik.setFieldValue(
                                                    `splitPaymentCardArray[${index}].bank`,
                                                    value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-1 px-1">
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                ✖
                                              </button>
                                            </div>
                                          </div>

                                          <ErrorMessage
                                            component="span"
                                            className="text-danger mb-2"
                                            name={`splitPaymentCardArray[${index}].bank`}
                                          />

                                          <Row>
                                            <Form.Group className="col-5 mb-3 mt-2">
                                              <Form.Label>POS Charge</Form.Label>
                                              <CurrencyCustomInput
                                                currencySymbol={currencySymbol}
                                                name={`splitPaymentCardArray[${index}].posCharge`}
                                                value={
                                                  formik.values
                                                    .splitPaymentCardArray[index]
                                                    .posCharge
                                                }
                                                onValueChange={(value, name) => {
                                                  formik.setFieldValue(
                                                    name,
                                                    value
                                                  );
                                                  formik.setFieldValue(
                                                    `splitPaymentCardArray[${index}].amount`,
                                                    currency(value).add(
                                                      formik.values
                                                        .splitPaymentCardArray[
                                                        index
                                                      ].amountPaid
                                                    ).value
                                                  );
                                                }}
                                              />
                                            </Form.Group>
                                            <Form.Group className="col-7 mb-3 mt-2">
                                              <Form.Label className="text-nowrap">
                                                Total Paid
                                              </Form.Label>
                                              <CurrencyCustomInput
                                                currencySymbol={currencySymbol}
                                                name={`splitPaymentCardArray[${index}].amount`}
                                                value={
                                                  formik.values
                                                    .splitPaymentCardArray[index]
                                                    .amount
                                                }
                                                onValueChange={(value, name) => {
                                                  if (
                                                    Number(value) ===
                                                    Number(
                                                      formik.values
                                                        .splitPaymentCardArray[
                                                        index
                                                      ].amount
                                                    )
                                                  ) {
                                                    formik.setFieldValue(
                                                      name,
                                                      value
                                                    );
                                                    return;
                                                  }
                                                  formik.setFieldValue(
                                                    name,
                                                    value
                                                  );
                                                }}
                                                placeholder="0.00"
                                                precision={2}
                                              />
                                            </Form.Group>
                                          </Row>
                                          <hr />
                                        </div>
                                      )
                                    )}
                                    <div className="d-flex justify-content-end px-4">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary text-nowrap"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            bank: "",
                                            amount: "",
                                            posCharge: "",
                                          })
                                        }
                                      >
                                        + Add
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            </Form.Group>

                            <hr />

                            <Form.Group className="col-12 mb-3">
                              <Form.Label>Direct Bank Transfer</Form.Label>
                              <FieldArray
                                name="splitDirectBankTransferArray"
                                render={(arrayHelpers) => (
                                  <div>
                                    {formik.values.splitDirectBankTransferArray.map(
                                      (el, index) => (
                                        <div key={index} className="mb-4">
                                          <div className="row align-items-center">
                                            <div className="col-md-7 ">
                                              <CurrencyCustomInput
                                                currencySymbol={currencySymbol}
                                                name={`splitDirectBankTransferArray[${index}].amount`}
                                                value={
                                                  formik.values
                                                    .splitDirectBankTransferArray[
                                                    index
                                                  ].amount
                                                }
                                                onValueChange={(value, name) => {
                                                  formik.setFieldValue(
                                                    name,
                                                    value
                                                  );
                                                }}
                                                placeholder="0.00"
                                              />
                                            </div>
                                            <div className="col-md-4 pe-0">
                                              <Select
                                                classNamePrefix={"form-select"}
                                                placeholder="Bank"
                                                options={data.banks}
                                                value={data.banks.find(
                                                  (el) =>
                                                    el.value ===
                                                    formik.values
                                                      .splitDirectBankTransferArray[
                                                      index
                                                    ].bank
                                                )}
                                                onChange={({ value }) =>
                                                  formik.setFieldValue(
                                                    `splitDirectBankTransferArray[${index}].bank`,
                                                    value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-1 px-1">
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                ✖
                                              </button>
                                            </div>
                                          </div>

                                          <ErrorMessage
                                            component="span"
                                            className="text-danger mb-2"
                                            name={`splitDirectBankTransferArray[${index}].bank`}
                                          />

                                          <hr />
                                        </div>
                                      )
                                    )}
                                    <div className="d-flex justify-content-end px-4">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary text-nowrap"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            bank: "",
                                            amount: "",
                                          })
                                        }
                                      >
                                        + Add
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            </Form.Group>
                            <hr />

                            <Form.Group className="col-12 mb-3">
                              <Form.Label>Cheque</Form.Label>
                              <FieldArray
                                name="splitPaymentChequeArray"
                                render={(arrayHelpers) => (
                                  <div>
                                    {formik.values.splitPaymentChequeArray.map(
                                      (el, index) => (
                                        <div key={index} className="mb-4">
                                          <div className="row align-items-center">
                                            <div className="col-md-7 ">
                                              <CurrencyCustomInput
                                                currencySymbol={currencySymbol}
                                                name={`splitPaymentChequeArray[${index}].amount`}
                                                value={
                                                  formik.values
                                                    .splitPaymentChequeArray[
                                                    index
                                                  ].amount
                                                }
                                                onValueChange={(value, name) => {
                                                  formik.setFieldValue(
                                                    name,
                                                    value
                                                  );
                                                }}
                                                placeholder="0.00"
                                              />
                                            </div>
                                            <div className="col-md-4 pe-0">
                                              <Select
                                                classNamePrefix={"form-select"}
                                                placeholder="Bank"
                                                options={data.banks}
                                                value={data.banks.find(
                                                  (el) =>
                                                    el.value ===
                                                    formik.values
                                                      .splitPaymentChequeArray[
                                                      index
                                                    ].bank
                                                )}
                                                onChange={({ value }) =>
                                                  formik.setFieldValue(
                                                    `splitPaymentChequeArray[${index}].bank`,
                                                    value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-1 px-1">
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                ✖
                                              </button>
                                            </div>
                                          </div>
                                          <ErrorMessage
                                            component="span"
                                            className="text-danger mb-2"
                                            name={`splitPaymentChequeArray[${index}].bank`}
                                          />

                                          <Form.Group className="col-12 mb-3 mt-2">
                                            <Form.Label>Cheque Number</Form.Label>
                                            <Form.Control
                                              name={`splitPaymentChequeArray[${index}].chequeNumber`}
                                              value={
                                                formik.values
                                                  .splitPaymentChequeArray[index]
                                                  .chequeNumber
                                              }
                                              onChange={formik.handleChange}
                                            />
                                            <ErrorMessage
                                              component="span"
                                              className="text-danger mb-2"
                                              name={`splitPaymentChequeArray[${index}].chequeNumber`}
                                            />
                                          </Form.Group>
                                          <hr />
                                        </div>
                                      )
                                    )}
                                    <div className="d-flex justify-content-end px-4">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary text-nowrap"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            bank: "",
                                            amount: "",
                                          })
                                        }
                                      >
                                        + Add
                                      </button>
                                    </div>
                                  </div>
                                )}
                              />
                            </Form.Group>

                            <hr />

                            {/* Customer Account */}
                            {mode !== "edit" && (
                              <Form.Group className="col-12 mb-3">
                                <Form.Label>Customer Balance</Form.Label>
                                <CurrencyCustomInput
                                  currencySymbol={currencySymbol}
                                  name="customerBalance"
                                  value={selectedCustomer?.balance}
                                  readOnly
                                  placeholder="0.00"
                                />
                              </Form.Group>
                            )}

                            <Form.Group className="col-12 mb-3">
                              <Form.Label>
                                Amount from Customer Balance
                              </Form.Label>
                              <CurrencyCustomInput
                                currencySymbol={currencySymbol}
                                name="amountFromCustomerBalance"
                                value={formik.values.amountFromCustomerBalance}
                                onValueChange={(value, name) => {
                                  formik.setFieldValue(name, value);
                                }}
                                placeholder="0.00"
                              />
                            </Form.Group>
                          </div>
                        )}

                      {formik.values.PaymentType === "Customer Account" ? (
                        <Form.Group className="col-12 mb-3">
                          <Form.Label>Customer Balance</Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="customerBalance"
                            value={selectedCustomer?.balance}
                            readOnly
                            placeholder="0.00"
                          />
                        </Form.Group>
                      ) : null}

                      {/* Split Payment */}
                      {formik.values.PaymentType === "Split Payment" ? (
                        <div>
                          <div className="d-flex justify-content-between mb-2 pb-1">
                            <Form.Check
                              inline
                              label="Card"
                              name="splitPaymentType"
                              value="card"
                              type={"radio"}
                              id={`splitPaymentType-1`}
                              checked={
                                formik.values.splitPaymentType === "card"
                              }
                              onChange={formik.handleChange}
                            />
                            <Form.Check
                              inline
                              label="Direct Transfer"
                              value="directTransfer"
                              name="splitPaymentType"
                              type={"radio"}
                              id={`splitPaymentType-2`}
                              checked={
                                formik.values.splitPaymentType ===
                                "directTransfer"
                              }
                              onChange={formik.handleChange}
                            />
                            <Form.Check
                              inline
                              label="Cheque"
                              value="cheque"
                              name="splitPaymentType"
                              type={"radio"}
                              id={`splitPaymentType-3`}
                              checked={
                                formik.values.splitPaymentType === "cheque"
                              }
                              onChange={formik.handleChange}
                            />
                          </div>

                          <Form.Group className="form-mb">
                            <Form.Label>Amount</Form.Label>
                            <CurrencyCustomInput
                              currencySymbol={currencySymbol}
                              value={amount}
                              placeholder="0.00"
                            />
                          </Form.Group>
                        </div>
                      ) : null}

                      {formik.values.PaymentType === "Credit/Debit Card" ||
                        formik.values.PaymentType === "Customer Account" ||
                        (formik.values.splitPaymentType === "card" &&
                          formik.values.PaymentType === "Split Payment") ? (
                        <Form.Group className="col-12 mb-3">
                          <Form.Label>POS Charge</Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="posCharge"
                            value={formik.values.posCharge}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            placeholder="0.00"
                          />
                        </Form.Group>
                      ) : null}

                      {!formik.values.jobNumber && (
                        <>
                          {!mode && (
                            <div className="d-flex gap-3">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Supply now"
                                className="mb-3"
                                name="supplyNow"
                                checked={formik.values.supplyNow}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  handleSupplyNow(e.target.checked);
                                }}
                                disabled={!generalSettings.supplyNow}
                              />
                              {/*  <Form.Check
                              type="switch"
                              id="custom-switch"
                              name="printWaybill"
                              label="Print Waybill"
                              className="mb-3"
                              checked={formik.values.printWaybill}
                              onChange={formik.handleChange}
                              disabled={!generalSettings.supplyNow}
                            /> */}
                            </div>
                          )}
                        </>
                      )}
                    </section>
                    <section className="buttons">
                      <Button
                        type="button"
                        variant="outline-primary"
                        // className="border-0"
                        onClick={() => discard()}
                      >
                        Discard Invoice
                      </Button>
                      <Button type="submit" variant="primary">
                        {isEdit ? "Save" : !mode ? "Post Invoice" : "Post"}
                      </Button>
                    </section>
                  </section>
                </Form>
              </FormikProvider>
            </div>
          </section>
        </div>
      </div>

      {/*   Modals */}

      {showCustomerSelectorModal && (
        <CustomerSelectModal
          setShowCustomerSelectorModal={setShowCustomerSelectorModal}
          setSelectedCustomer={handleSelectedCustomer}
          selectedCustomer={selectedCustomer}
          withCredit={true}
        />
      )}

      {showItemSelectorModal && (
        <AddItemModal
          showItemSelectorModal={showItemSelectorModal}
          setShowItemSelectorModal={setShowItemSelectorModal}
          handleAddItem={handleAddItem}
          saleTypes={saleTypes}
          lockedTableData={lockedTableData}
          selectedCustomer={selectedCustomer}
          withQuantity={true}
          conversionAmount={formik.values.conversionAmount}
          currencySymbol={currencySymbol}
          currencyText={formik.values.currency}
          previouslySelectedItems={tableData}
          mode={mode}
          oldInvoiceData={oldInvoiceData}
        />
      )}

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}

      {editedItemIndex !== null && !formik.values.jobNumber ? (
        <EditIronRodItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItemToEdit={{
            ...tableData[editedItemIndex],
            //  quantityInStock: tableData[editedItemIndex].Quantity,
          }}
          saleTypes={saleTypes}
          conversionAmount={formik.values.conversionAmount}
          currencySymbol={currencySymbol}
          currencyText={formik.values.currency}
          mode={mode}
        />
      ) : null}

      {editedItemIndex !== null && formik.values.jobNumber ? (
        <AddServiceItemModal
          tableDataToEdit={tableData}
          setEditedItemIndex={setEditedItemIndex}
          handleAddItem={handleAddItem}
        />
      ) : null}

      {showPermitModal && (
        <PermitModal
          setShowPermitModal={setShowPermitModal}
          handleSelectedPermit={handleSelectedPermit}
          batchData={true}
        />
      )}

      {showNewRequisitionModal && (
        <NewRequisitionModal
          modalTitle="Send for Approval"
          dataForPendingPermit={JSON.stringify({
            items: tableData.map((el) => ({
              ...el,
              Quantity: el.Quantity,
              requirePermission: true,
            })),
            ...formik.values,
            subTotal,
            discount,
            amountDue,
            profit,
            balance,
            amount,
            customer: selectedCustomer,
            taxValue,
            costOFSales,
          })}
          defaultFormValues={{
            title: location.state.job?.title,
            description: location.state.job?.description,
            type: "Pending Service Invoice",
            cost: location.state.job?.jobCost,
            vendor: "  ",
            jobNumber: location.state.job?.jobNumber,
          }}
          setShowNewRequisitionModal={setShowNewRequisitionModal}
        /* handleCreatedRequisition={handleCreatedRequisition} */
        />
      )}

      <ModalLoader
        show={
          createRodPermitMutation.isLoading ||
          createRodInvoiceMutation.isLoading ||
          isLoading ||
          preorderInvoiceMutation.isLoading
        }
      />
    </main>
  );
}
