import {
  Dropdown,
  Table,
  Form,
  Button,
  InputSignatory,
  Nav,
} from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  EditIcon,
  SignatureIcon,
  GearIcon,
  SearchIconV2,
  RefreshIcon,
} from "./../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useEffectOnce,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
  useSingleAndDoubleClick,
} from "./../../utils/hooks";
import {
  cacheTimes,
  queryActions,
  reportActions,
} from "./../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  scrollToTop,
} from "./../../utils/helpers";
import queryString from "query-string";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useAuth } from "./../../hooks/useAuth";
import UpdateNumberSystemModal from "../NumberSystem/UpdateNumberSystemModal";
import { padStart } from "lodash";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";

import { isEmpty } from "lodash";
import { Modal, FormCheck } from "react-bootstrap";
import { useBackendUrl } from "../../utils/hooks";
import { FieldArray, FormikProvider, useFormik } from "formik";
import NoTableItem from "../utils/NoTableItem";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { StaffSelectorMultiple } from "../GeneralSettingsModal";
import ApprovalBadge from "../utils/ApprovalBadge";

const lf = new Intl.ListFormat("en");

export default function SystemForms({ withNav = true }) {
  useScrollTop();
  const navigate = useNavigate();

  const initialFilterParams = {};

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, user: authUser } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [selectedNumberSystem, setSelectedNumberSystem] = useState();
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const getNumberSystems = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/numbersystem?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { numberSystems: [] }, refetch } = useQuery(
    ["NUMBER_SYSTEMS", queryParams],
    () => getNumberSystems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  return (
    <>

      <main className="cash-book">
        <div className="content">


          <div className="content-main">
            <div className="content-body">
              <header>
                <div className="d-flex gap-3 flex-grow-1">
                  <h1>
                    System Forms
                    {/*  <button
                      onClick={() => refetch()}
                      className="btn text-primary"
                    >
                      <RefreshIcon />
                    </button> */}
                  </h1>
                </div>
                <div className="actions mr-5">{""}</div>
              </header>

              {withNav && (
                <div className="dashboard-tabs innerpage-tabs px-4">
                  <ul className="nav nav-tabs">
                    <Nav>
                      <NavLink
                        to="/requisition/forms/templates"
                        className={`nav-link ga-nav-tab`}
                      >
                        Templates
                      </NavLink>
                      <NavLink
                        to="/requisition/forms/company"
                        className={`nav-link ga-nav-tab`}
                      >
                        {authUser.account_type === 1 ? 'Personal' : 'Company'}
                      </NavLink>
                      <NavLink
                        to="/requisition/forms/public"
                        className={`nav-link ga-nav-tab`}
                      >
                        Public
                      </NavLink>
                      <NavLink
                        to="/requisition/forms/system"
                        className={`nav-link ga-nav-tab`}
                      >
                        System Forms
                      </NavLink>
                    </Nav>
                  </ul>
                </div>
              )}

              <div className="requisition-control-area align-items-center">
                <div className="search-area">
                  <SearchIconV2 />
                  <Form.Control
                    id="queryParams-q"
                    className=""
                    name="q"
                    value={filterParams.q}
                    onChange={(e) => handleFilterParamsChange(e)}
                    placeholder="Search Title..."
                  />
                </div>

                <Button
                  onClick={() => refetch()}
                  variant="outline-primary"
                  className="border-0"
                  title="Refresh"
                >
                  <RefreshIcon />
                </Button>
              </div>

              <div className="px-0 pt-0">
                <Table
                  responsive
                  borderless
                  striped
                  hover
                  className="product-table text-nowrap"
                >
                  <thead>
                    <tr>
                      <th />
                      <th>S/N</th>
                      <th scope="col">Title</th>
                      <th scope="col">Signatories</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.numberSystems
                      .filter(
                        (el) =>
                          ![
                            "Customer",
                            "Vendor",
                            "Invoice",
                            "Inventory Entry",
                          ].includes(el.documentType)
                      )
                      ?.map((el, index) => (
                        <tr>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                              >
                                <DotsVerticalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => setSelectedNumberSystem(el)}
                                >
                                  <GearIcon /> Signatory Settings
                                </Dropdown.Item>
                              </Dropdown.Menu>{" "}
                            </Dropdown>
                          </td>
                          <td>{index + 1}</td>
                          <td>
                            {el.documentType === "Inventory"
                              ? "Sales Inventory Request"
                              : el.documentType}
                          </td>
                          <td>
                            <p className="d-flex gap-3">
                              {el.enablePreSelectSignatory ? (
                                <ApprovalBadge
                                  text={"Enabled"}
                                  className="approval"
                                  bg="success"
                                />
                              ) : (
                                <ApprovalBadge
                                  text={"Disabled"}
                                  className="approval"
                                  bg="danger"
                                />
                              )}

                              {el?.selectedSignatory
                                ? lf.format(
                                  JSON.parse(el.selectedSignatory).map(
                                    (el) =>
                                      el?.staffName || el?.department || "..."
                                  )
                                )
                                : "..."}
                            </p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        {selectedNumberSystem && (
          <>
            <AssignSignatoryModal
              refetch={refetch}
              systemForm={selectedNumberSystem}
              setShowAssignSignatoryModal={setSelectedNumberSystem}
            />{" "}
          </>
        )}
      </main>
    </>
  );
}

function AssignSignatoryModal({
  showAssignSignatoryModal = true,
  setShowAssignSignatoryModal,
  systemForm,
  refetch: refetchTable,
}) {
  const backendUrl = useBackendUrl();

  const createSignatorys = async (payload) => {
    let response = await fetch(`${backendUrl}/api/numbersystem/update`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createSignatorysMutation = useMutation(
    (payload) => createSignatorys(payload),
    {
      onSuccess: ({ message }) => {
        toast.success("Success");
        formik.resetForm();
        refetchTable();
        setShowAssignSignatoryModal(false);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      enablePreSelectSignatory: false,
      selectedSignatory: [
        {
          department: "",
          jobGrade: "",
          staff: "",
          staffName: "Requester",
        },
        {
          department: "",
          jobGrade: "",
          staff: "",
          staffName: "",
        },
      ],
    },
    onSubmit: async (values) => {
      createSignatorysMutation.mutate({
        ...values,
        selectedSignatory: JSON.stringify(values.selectedSignatory),
        documentType: systemForm.documentType,
      });
    },
  });

  const getDepartmentsForForm = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
    isSuccess,
    refetch,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartmentsForForm(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  useEffectOnce(() => {
    if (systemForm.selectedSignatory) {
      formik.setFieldValue(
        "selectedSignatory",
        JSON.parse(systemForm.selectedSignatory)
      );
    }

    formik.setFieldValue(
      "enablePreSelectSignatory",
      Boolean(systemForm?.enablePreSelectSignatory)
    );
  });

  return (
    <Modal
      show={showAssignSignatoryModal}
      onHide={() => setShowAssignSignatoryModal(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">
            Assign Signatories to{" "}
            {systemForm?.documentType === "Inventory"
              ? "Sales Inventory Request"
              : systemForm?.documentType}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="col mb-3">
              <div className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  id="enablePreSelectSignatory"
                  label="Enable"
                  className="mb-0"
                  name="enablePreSelectSignatory"
                  checked={formik.values.enablePreSelectSignatory}
                  onChange={formik.handleChange}
                />{" "}
                <label for="enablePreSelectSignatory" className="m-0">
                  Enable
                </label>
              </div>
            </Form.Group>

            <div
              style={
                !formik.values.enablePreSelectSignatory
                  ? { opacity: "0.5", pointerEvents: "none" }
                  : {}
              }
            >
              <FieldArray
                name="selectedSignatory"
                render={(arrayHelpers) => (
                  <>
                    <div className="row">
                      {formik.values.selectedSignatory?.map((el, index) => (
                        <>
                          <StaffSelectorMultiple
                            data={data}
                            name="selectedSignatory"
                            index={index}
                            formik={formik}
                            arrayHelpers={arrayHelpers}
                            hasLimit={false}
                            disabled={index == 0}
                            isRequester={index == 0}
                          />
                        </>
                      ))}
                    </div>
                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            department: "",
                            jobGrade: "",
                            staff: "",
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div />
              <Button
                disabled={isFetching || createSignatorysMutation.isLoading}
                variant="primary"
                className="p-2 px-4"
                type="submit"
              >
                {isFetching || createSignatorysMutation.isLoading
                  ? "Please wait…"
                  : "Save"}
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}
