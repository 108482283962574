import { isEmpty } from "lodash";
import { forwardRef, Fragment, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import Select from "react-select";
import { convertMomentDate, scrollToElement } from "../../utils/helpers";
import {
  AddCircleIcon,
  CashSelectIcon,
  ChequeSelectIcon,
  CreditSelectIcon,
  DeleteIcon,
  DirectTransferSelectIcon,
  NoSelectedItemIcon,
} from "../Icons";
import Datetime from "react-datetime";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { Popover } from "react-tiny-popover";
import { FieldArray, FormikProvider, useFormik } from "formik";
import moment from "moment/moment";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import useDebounce, { useEffectOnce, useTaxOptions } from "../../utils/hooks";
import currency from "currency.js";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SelectBankDialog from "../SelectBankDialog";
import { last } from "lodash";
import ConfirmDialog from "../ConfirmDialogue";
// import { appSettings } from "../../config";
import VendorSelectModal from "./VendorPostSelector";
import { IsPrivileged } from "../DisplayChildElement";
import { useStoreState } from "easy-peasy";
import { currenciesOptions } from "../../utils/currencies";

const paymentMethod = [
  {
    icon: <CashSelectIcon />,
    label: "Cash",
    value: "Cash",
  },
  {
    icon: <ChequeSelectIcon />,
    label: "Cheque",
    value: "Cheque",
  },
  {
    icon: <CreditSelectIcon />,
    label: "Credit/Debit Card",
    value: "Credit/Debit Card",
  },
  /*  {
    icon: <CreditMemoSelectIcon />,
    label: "Credit Memo",
    value: "Credit Memo",
  }, */
  {
    icon: <DirectTransferSelectIcon />,
    label: "Direct Bank Transfer",
    value: "Direct Bank Transfer",
  },
];

const TaxPicker = forwardRef(({
  newInstallmentBeforeTax,
  setNewInstallmentBeforeTax,
  newInstallment,
  setTaxes,
  setNewInstallment,
  setNewInstallmentPlusTax,
  newInstallmentPlusTax,
  paymentCurrency = "",
  deductTax, setDeductTax
}, ref) => {
  const generalSettings = useStoreState((state) => state.generalSettings);
  const taxOptions = useTaxOptions();
  const formik = useFormik({
    initialValues: {
      taxes: [],
    },
  });

  // Calculate other taxValues
  const otherTaxValues = useMemo(() => {
    return formik.values.taxes
      .map((el) => {
        const taxType = taxOptions.find(
          (taxOption) => taxOption.value === el.taxType
        );
        return {
          ...el,
          taxValue: currency(newInstallmentBeforeTax, {
            precision: 4,
          }).multiply(taxType?.percentage).value,
        };
      })
      .filter((el) => el.taxType);
  }, [newInstallmentBeforeTax, formik.values.taxes, taxOptions]);

  useEffect(() => {
    setTaxes(otherTaxValues);
    setNewInstallment(
      currency(newInstallmentBeforeTax).value
      /* subtract(
        otherTaxValues.reduce(
          (sum, tax) => currency(sum).add(tax.taxValue).value,
          0
        )
      ) */
    );
    setNewInstallmentPlusTax(
      currency(newInstallmentBeforeTax).add(
        otherTaxValues.reduce(
          (sum, tax) => currency(sum).add(tax.taxValue).value,
          0
        )
      ).value
    );
  }, [otherTaxValues, newInstallmentBeforeTax]);


  const initialTaxes = (taxes) => {
    formik.setFieldValue("taxes", taxes)
  }

  // Expose the function to the parent
  useImperativeHandle(ref, () => ({
    initialTaxes
  }));

  return (
    <FormikProvider value={formik}>
      <Form noValidate className="">
        <Form.Group className="mb-3 d-flex gap-2 align-items-center">
          {!isEmpty(formik.values.taxes) && (
            <Form.Label className="m-0">Amount:</Form.Label>
          )}
          <CurrencyCustomInput
            currencySymbol={
              currenciesOptions.find(
                (curr) =>
                  curr.cc ===
                  (paymentCurrency
                    ? paymentCurrency
                    : generalSettings?.prevailingCurrency)
              )?.symbol
            }
            name="newInstallmentBeforeTax"
            placeholder="0.00"
            defaultValue=""
            value={newInstallmentBeforeTax}
            onValueChange={(value, name) => {
              setNewInstallmentBeforeTax(value);
            }}
          />{" "}
        </Form.Group>

        {/* Add more TAX */}
        <FieldArray
          name="taxes"
          render={(arrayHelpers) => (
            <>
              <div className="row">
                {formik.values.taxes.map((el, index) => (
                  <div className="d-flex gap-3 mb-3 align-items-bottom">
                    <Form.Group className="col-6">
                      {/*   <Form.Label>Tax</Form.Label> */}
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Tax"
                        isSearchable={false}
                        value={taxOptions.find(
                          (el) =>
                            el.value === formik.values.taxes[index].taxType
                        )}
                        options={taxOptions}
                        onChange={({ value }) => {
                          formik.setFieldValue(
                            `taxes[${index}].taxType`,
                            value
                          );
                        }}
                        menuPosition="fixed"
                      />
                    </Form.Group>

                    <Form.Group className="col-6">
                      {/*  <Form.Label>
                        {formik.values.taxes[index].taxType}
                      </Form.Label> */}
                      <div className="d-flex">
                        <CurrencyCustomInput
                          currencySymbol={
                            currenciesOptions.find(
                              (curr) =>
                                curr.cc ===
                                (paymentCurrency
                                  ? paymentCurrency
                                  : generalSettings?.prevailingCurrency)
                            )?.symbol
                          }
                          value={
                            otherTaxValues.find(
                              (el) =>
                                el.taxType ===
                                formik.values.taxes[index].taxType
                            )?.taxValue || 0
                          }
                          onValueChange={(value, name) => { }}
                          placeholder="0.00"
                        />
                        <Button
                          title="Remove"
                          variant=""
                          type="button"
                          size="xs"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          ✖
                        </Button>
                      </div>
                    </Form.Group>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-start">
                <Button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      taxType: "",
                    })
                  }
                  variant="light-blue"
                  className="text-primary border"
                  size="sm"
                >
                  + Add Tax Deductions
                </Button>
              </div>
            </>
          )}
        />

        {!isEmpty(formik.values.taxes) && (
          <Form.Group className="my-3 d-flex gap-2 align-items-center">
            <Form.Label className="m-0">Total Amount:</Form.Label>
            <CurrencyCustomInput
              currencySymbol={
                currenciesOptions.find(
                  (curr) =>
                    curr.cc ===
                    (paymentCurrency
                      ? paymentCurrency
                      : generalSettings?.prevailingCurrency)
                )?.symbol
              }
              placeholder="0.00"
              defaultValue=""
              title={newInstallment}
              value={newInstallmentPlusTax}
              onValueChange={(value, name) => { }}
            />

            <Form.Switch
              className="text-nowrap"
              label="Deduct Tax"
              checked={deductTax}
              onChange={() =>
                setDeductTax(!formik.values.deductTax)
              }
            />
          </Form.Group>
        )}
      </Form>
    </FormikProvider>
  );
});

function CustomerRow({
  index,
  customer,
  editTable,
  remove,
  customers = [],
  addNewRow,
  backendUrl,
  getCustomerBalanceAndCredit,
}) {
  const generalSettings = useStoreState((state) => state.generalSettings);
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);
  const [duePaymentOptions, setDuePaymentOptions] = useState([]);
  const [invoiceCatOptions, setInvoiceCatOptions] = useState([]);

  const initialValues = {
    Post_Date: moment(),
    newInstallment: "",
    Remark: "",
    PaymentType: "Cash",
    duePaymentTransactionID: "",
    chequeNumber: "",
    BankName: "",
    Vendor_ID: "",
    InvoiceCat: "None",
    otherTaxValues: [],
    newInstallmentPlusTax: "", deductTax: customer.deductTax || false,
  };
  const formik = useFormik({
    initialValues,
    // validationSchema: yup.object().shape({}),
  });
  const formValues = useDebounce(formik.values, 500);

  const setUp = async (Vendor_ID) => {
    const customer = customers.find((el) => el.Vendor_ID === Vendor_ID);

    // GET CUSTOMER BALANCE & CREDIT
    const duePayments = customer?.duePayments ? customer.duePayments : [];
    const invoiceCats = customer?.invoiceCats ? customer.invoiceCats : [];
    if (
      isEmpty(customer?.balance) ||
      isEmpty(duePayments) ||
      isEmpty(invoiceCats)
    ) {
      getCustomerBalanceAndCredit(Vendor_ID);
    }

    setDuePaymentOptions([
      {
        label: "None",
        value: "",
      },
      ...duePayments.map((el) => ({
        ...el,
        label: `${el.Trans_ID} - ${currency(el.Balance, {
          symbol: "",
        }).format()} ${el?.InvoiceCat || ""} - ${el?.Installment_Count} `,
        // trans id may be duplicate
        value: `${el.Trans_ID} - ${el.Installment_Count}`,
      })),
    ]);

    setInvoiceCatOptions([
      {
        label: "None",
        value: "None",
      },
      ...invoiceCats.map((el) => ({
        label: el.InvoiceCat,
        value: el.InvoiceCat,
      })),
    ]);

    formik.setFieldValue("newInstallment", duePayments[0]?.Balance);
    formik.setFieldValue("Vendor_ID", Vendor_ID);
  };

  useEffect(() => {
    if (customer?.duePayments) {
      setDuePaymentOptions([
        {
          label: "None",
          value: "",
        },
        ...customer?.duePayments.map((el) => ({
          ...el,
          label: `${el.Trans_ID} - ${currency(el.Balance, {
            symbol: "",
          }).format()} ${el?.InvoiceCat || ""}  - ${el?.Installment_Count}`,
          // trans id may be duplicate
          value: `${el.Trans_ID} - ${el.Installment_Count}`,
        })),
      ]);
    }
  }, [customer?.duePayments]);

  useEffect(() => {
    if (customer?.invoiceCats) {
      setInvoiceCatOptions([
        {
          label: "None",
          value: "None",
        },
        ...customer?.invoiceCats.map((el) => ({
          label: el.InvoiceCat,
          value: el.InvoiceCat,
        })),
      ]);
    }
  }, [customer?.invoiceCats]);

  useEffectOnce(() => {
    if (customer?.Vendor_ID) setUp(customer.Vendor_ID);
  });

  useEffect(() => {
    // formik values is set empty to undefined
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }

    editTable({
      index,
      formValues: {
        ...(formValues?.Vendor_ID
          ? customers.find((el) => el.Vendor_ID === formValues.Vendor_ID)
          : {}),
        ...formValues,
      },
    });
  }, [formValues]);

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      currencyCode: "NGN",
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
  }, [formik.values.PaymentType]);

  const handleCustomerChange = (value) => {
    formik.setFieldValue("Vendor_ID", value);
    if (value) {
      console.log(value);
      setUp(value);
      addNewRow(index);
    }
  };

  const childRef = useRef();

  return (
    <tr>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="bg-white border-0"
            bsPrefix="print more"
            type="button"
          >
            <DotsVerticalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            <Dropdown.Item
              as="button"
              type="button"
              onClick={() => remove(index)}
            >
              <DeleteIcon />
              Remove Customer
            </Dropdown.Item>
          </Dropdown.Menu>{" "}
        </Dropdown>
      </td>
      {/*   <td>{customer.Vendor_ID}</td> */}
      <td>
        {/* {customer?.Vendor_ID} */}
        <Select
          classNamePrefix="form-select"
          menuPlacement="bottom"
          menuPosition="fixed"
          placeholder="Choose method"
          isSearchable={false}
          value={paymentMethod.find(
            (el) => el.value === formik.values.PaymentType
          )}
          options={paymentMethod}
          onChange={({ value }) => formik.setFieldValue("PaymentType", value)}
          getOptionLabel={(el) => (
            <div className="label-with-icon d-flex gap-2 align-items-center">
              <span>{el.icon}</span> <span className="fw-5">{el.label}</span>
            </div>
          )}
        />
      </td>
      <td>
        <Select
          classNamePrefix={"form-select"}
          className="customer-select-dropdown"
          data={customers}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={true}
          isClearable={true}
          value={customers.find((el) => el.value === formik.values.Vendor_ID)}
          options={customers}
          onChange={(selection) =>
            handleCustomerChange(selection?.value ? selection?.value : "")
          }
          id={`newInstallment-customer-${index}`}
        />{" "}
      </td>

      <td>
        <Form.Control
          placeholder="Enter Remark"
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
          style={{ width: "15rem" }}
        />
      </td>
      {/*    <td>
        <CurrencyCustomInput
          id={`newInstallment-${customer.Vendor_ID}`}
          name="newInstallment"
          placeholder="0.00"
          defaultValue=""
          value={formik.values.newInstallment}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        // disabled={formik.values.duePaymentTransactionID}
        />
      </td> */}

      {/* <td>
        <Select
          classNamePrefix={"form-select"}
          menuPlacement="bottom"
          menuPosition="fixed"
          options={duePaymentOptions}
          key={duePaymentOptions}
          isSearchable={false}
          onChange={(selected) => {
            formik.setFieldValue("duePaymentTransactionID", selected.value);
            formik.setFieldValue("newInstallment", selected.Balance);
          }}
        />
      </td> */}

      <td>
        {/*  {JSON.stringify(duePaymentOptions)} */}
        <Select
          classNamePrefix={"form-select"}
          className="customer-select-dropdown"
          menuPlacement="bottom"
          menuPosition="fixed"
          options={duePaymentOptions.filter(
            (el) =>
              !el?.currency || el?.currency === formik.values.currency
          )}
          key={duePaymentOptions}
          isSearchable={false}
          onChange={(selected) => {
            //    console.log(selected)
            if (selected?.value) {
              const amountDue =
                selected?.currency &&
                  selected?.currency !==
                  generalSettings?.prevailingCurrency
                  ? currency(selected.AmountDue).divide(
                    selected?.conversionAmount
                  ).value
                  : selected.AmountDue;

              let defaultTaxValue = 0;
              let otherTaxValuesTotal = 0;
              let otherTaxes = []
              try {
                // figure out tax value
                defaultTaxValue =/*  selected?.taxValue  || */ 0;
                otherTaxes = (selected?.otherTaxValues ? JSON.parse(selected?.otherTaxValues) : []);
                otherTaxValuesTotal = otherTaxes.reduce(
                  (sum, tax) => currency(sum).add(tax.taxValue).value,
                  0
                );

              } catch (err) {
                console.log(err);
                toast.error("Issue getting Tax, please set manually if any");
                defaultTaxValue = 0;
                otherTaxValuesTotal = 0;
                otherTaxes = [];
              }

              //    console.log(otherTaxValuesTotal, defaultTaxValue);

              const valueWithOutTax = currency(amountDue).subtract(defaultTaxValue).subtract(otherTaxValuesTotal).value;

              const taxes = [...(selected?.taxValue ? [{ taxValue: selected?.taxValue, taxType: selected?.taxType }] : []), ...otherTaxes];
              if (childRef.current) {
                childRef.current.initialTaxes(taxes); // Call the child's function
              }

              formik.setFieldValue("duePaymentTransactionID", selected.value);
              formik.setFieldValue("newInstallment", amountDue);
              formik.setFieldValue(
                "currency",
                selected?.currency || "NGN"
              );
              formik.setFieldValue(
                "conversionAmount",
                selected?.conversionAmount
              );

              if (customer.Cust_ID === "000101") {
                formik.setFieldValue(
                  "Remark",
                  selected?.ShipTo || ""
                );
              }

              formik.setFieldValue(
                "Sale_Rep",
                selected?.Sale_Rep
              );

              formik.setFieldValue("newInstallmentBeforeTax", valueWithOutTax);
            }
          }}
          value={duePaymentOptions
            .filter(
              (el) =>
                el?.Payment_Due_Transaction?.currency === formik.values.currency
            )
            .find(
              (el) => el.Trans_ID === formik.values?.duePaymentTransactionID
            )}
        />
      </td>

      <td>
        {/*   {JSON.stringify(formik.values.otherTaxValues)} */}
        <TaxPicker
          ref={childRef}
          newInstallmentBeforeTax={formik.values.newInstallmentBeforeTax}
          setNewInstallmentBeforeTax={(amount) =>
            formik.setFieldValue("newInstallmentBeforeTax", amount)
          }
          newInstallment={formik.values.newInstallment}
          setNewInstallment={(amount) =>
            formik.setFieldValue("newInstallment", amount)
          }
          setTaxes={(otherTaxValues) =>
            formik.setFieldValue("otherTaxValues", otherTaxValues)
          }
          newInstallmentPlusTax={formik.values.newInstallmentPlusTax}
          setNewInstallmentPlusTax={(amount) =>
            formik.setFieldValue("newInstallmentPlusTax", amount)
          }
          paymentCurrency={formik.values.currency}
          deductTax={formik.values.deductTax}
          setDeductTax={(value) => formik.setFieldValue("deductTax", value)}
        />
      </td>

      <td>
        {currency(customer.balance, {
          symbol: "",
        }).format()}
      </td>

      <td>
        <Popover
          reposition={false}
          isOpen={datePopoverOpened}
          onClickOutside={() => setDatePopoverOpened(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              dateFormat="MM DD, YYYY"
              className="date-picker-2"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              name="Post_Date"
              inputProps={{
                className: `date-input form-control ${formik.touched.Post_Date && !!formik.errors.Post_Date
                  ? "is-invalid"
                  : ""
                  }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={formik.values.Post_Date}
              onChange={(date) => {
                formik.setFieldValue("Post_Date", date, true);
                setDatePopoverOpened(false);
              }}
              onBlur={() => formik.setFieldTouched("Post_Date", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDatePopoverOpened(!datePopoverOpened)}
            value={convertMomentDate(formik.values.Post_Date)}
          />
        </Popover>
      </td>
      <td>{customer.TransactionID}</td>
      <td>
        <Select
          classNamePrefix={"form-select"}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={false}
          options={invoiceCatOptions}
          key={invoiceCatOptions}
          onChange={({ value }) => formik.setFieldValue("InvoiceCat", value)}
        />
      </td>
    </tr>
  );
}

export default function PostPayment() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { backendUrl, token } = useAuth();
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );

  const [tableData, setTableData] = useState([
    {
      Vendor_ID: "",
      TransactionID: `STN${Date.now()}1`,
    },
  ]);
  const setSelectedCustomer = (customer) => {
    const lastitem = last(tableData);
    customer.TransactionID = `STN${Date.now()}`;

    if (lastitem?.Vendor_ID === "") {
      setTableData([
        ...tableData.filter((el, index) => index !== tableData.length - 1),
        customer,
        { Vendor_ID: "", TransactionID: `STN${Date.now()}2` },
      ]);
    } else {
      setTableData([
        ...tableData,
        customer,
        { Vendor_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const editTable = ({ index, formValues }) => {
    // console.log(formValues.newInstallment);
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const addNewRow = (index) => {
    if (index === tableData.length - 1) {
      setTableData([
        ...tableData,
        { Vendor_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const remove = (index) => {
    const newTableData = tableData.filter((el, i) => index !== i);
    setTableData([...newTableData]);
  };

  const updateCustomersLedger = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/vendors/post-payments`, {
      method: "POST",
      headers: {
        /*   Accept: "Application/json",
        "Content-Type": "Application/json", */
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updateCustomersLedgerMutation = useMutation(
    (payload) => updateCustomersLedger(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setTableData([{ Vendor_ID: "", TransactionID: `STN${Date.now()}1` }]);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const post = async () => {
    if (
      !(await ConfirmDialog({
        title: "Post Transaction",
        description: "Are you sure, you want to make this transaction",
      }))
    ) {
      return;
    }
    const table =
      tableData.length > 1
        ? tableData.filter((el, index) => index !== tableData.length - 1)
        : tableData;

    const foundEmptyCustomerIndex = table.findIndex(
      (el) => el.Vendor_ID === ""
    );
    if (foundEmptyCustomerIndex !== -1) {
      console.log(foundEmptyCustomerIndex);
      scrollToElement(`#newInstallment-customer-${foundEmptyCustomerIndex}`);
      return toast.error(`Select a Customer`);
    }

    const foundEmptyInstallment = table.find(
      (el) => Number(el.newInstallment) <= 0
    );
    if (foundEmptyInstallment) {
      scrollToElement(`#newInstallment-${foundEmptyInstallment.Vendor_ID}`);
      return toast.error(`Invalid installment added`);
    }

    // Ensure only valid data are sent
    /* console.log(tableData);
    return; */
    const customersData = tableData.filter(
      (el) => el.Vendor_ID && el.newInstallment
    );
    // console.log(customersData);
    updateCustomersLedgerMutation.mutate(
      {
        customers: customersData,
      },
      {
        onSuccess: () => {
          if (customersData && customersData.length === 1) {
            // const customer = first(customersData);
            // window.open(
            //   `${backendUrl}/api/invoice/pdf/receipt/${
            //     customer.TransactionID
            //   }?thermalPrinter=${appSettings.requireSalesRep}`,
            //   "_blank",
            //   "noopener,noreferrer"
            // );
          }
        },
      }
    );
  };

  const getCustomer = async () => {
    let response = await fetch(
      // `${backendUrl}/api/customers?withCredit=${true}&withPaymentDue=${true}`,
      `${backendUrl}/api/vendors`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.vendors = data.vendors.map((el) => ({
      ...el,
      label: el.CompanyName,
      value: el.Vendor_ID,
    }));
    return data;
  };

  const { data = { vendors: [] }, isFetching } = useQuery(
    ["GETVENDORS"],
    () => getCustomer(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const getCustomerBalanceAndCredit = async (Vendor_ID) => {
    // return new Promise(async (resolve, reject) => {
    try {
      setIsLoading(true);
      //await waitFor(4000);
      let response = await fetch(
        `${backendUrl}/api/vendors/balance-and-credit/${Vendor_ID}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { balance, duePayments, invoiceCats },
        } = await response.json();
        // resolve({ balance, duePayments });
        queryClient.setQueryData(["GETVENDORS"], (data) => {
          data.vendors = data.vendors.map((el) =>
            el.Vendor_ID === Vendor_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          );
          return data;
        });

        setTableData((tableData) =>
          tableData.map((el) =>
            el.Vendor_ID === Vendor_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          )
        );
      }
    } catch (err) {
      console.log(err);

      // reject();
    } finally {
      setIsLoading(false);
    }
    //  });
  };

  // SupplierEvaluation

  return (
    <IsPrivileged roleName="Pay a Vendor">
      <main className="create-invoice">
        <div className="p-3 content">
          <div className="d-flex content-holder rounded">
            <section className="item-details customers">
              <div>
                <header>
                  <h1>Post Payment</h1>
                </header>

                <div className="selected-data-area mt-3">
                  {/*  */}
                  <div className="table-holder">
                    {!isEmpty(tableData) ? (
                      <Table
                        responsive
                        borderless
                        hover
                        striped
                        className="product-table text-nowrap post-payment"
                      >
                        <thead>
                          <tr>
                            <th />
                            {/* <th>Customer ID</th> */}
                            <th>Payment Type</th>
                            <th>Vendor</th>
                            <th>Remark</th>

                            <th>New Installment</th>
                            <th>Link Payment to Invoice</th>
                            <th>Balance on Ledger</th>

                            <th>Transaction Date</th>
                            <th>Transaction ID</th>
                            <th>Invoice Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((el, index) => (
                            <Fragment key={el.TransactionID}>
                              <CustomerRow
                                customers={data?.vendors}
                                index={index}
                                customer={el}
                                editTable={editTable}
                                remove={remove}
                                addNewRow={addNewRow}
                                backendUrl={backendUrl}
                                getCustomerBalanceAndCredit={
                                  getCustomerBalanceAndCredit
                                }
                              />
                            </Fragment>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>

                  <button
                    onClick={() => setShowCustomerSelectorModal(true)}
                    type="button"
                    className="btn text-primary my-3 d-flex align-items-center gap-2"
                  >
                    <AddCircleIcon />
                    Add Vendor
                  </button>

                  {/*  No item  */}
                  {isEmpty(tableData) ? (
                    <div className="no-item my-5">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">
                          Haven't selected a Customer yet
                        </h2>
                        <p>
                          You can click +Add Customer Button to add a Customer
                          to the table.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {!isEmpty(tableData) && (
                <div className="d-flex justify-content-between total-info">
                  <div />
                  <div>
                    <Button
                      onClick={() => post()}
                      disabled={updateCustomersLedgerMutation.isLoading}
                      type="button"
                      className="py-3 px-5"
                    >
                      Post Payments
                    </Button>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>

        {/*   Modals */}
        {showCustomerSelectorModal && (
          <VendorSelectModal
            setShowVendorSelectorModal={setShowCustomerSelectorModal}
            setSelectedVendor={setSelectedCustomer}
            withCredit={true}
            withPaymentDue={true}
            isMulti={false}
            alreadySelectedCustomers={tableData}
            withInvoiceCat={true}
          />
        )}

        <ModalLoader
          show={updateCustomersLedgerMutation.isLoading || isFetching}
        />

        <ModalLoader
          show={isLoading}
          title="Please wait, getting customer balance and due payments..."
        />
      </main>
    </IsPrivileged>
  );
}
