import {
  Modal,
  Form,
  Button,
  InputGroup,
  Table,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";

import "./../../assets/scss/new-entity-modal.scss";
import Select from "react-select";

import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { DeleteIcon, EditIcon, NoSelectedItemIcon } from "../Icons";
import NumberCustomInput from "../utils/NumberCustomInput";
import DateTime from "react-datetime";
import { useAuth } from "../../hooks/useAuth";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";
import currency from "currency.js";
import moment from "moment";
import { toast } from "react-toastify";
import CachedIcon from "mdi-react/CachedIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";

import {
  qtyFormat,
  qtyFormatToString,
  scrollToElement,
  unitsResolver,
  amountTypes,
  formatDate,
} from "../../utils/helpers";
import ConfirmDialogue from "../ConfirmDialogue";
import { isEmpty } from "lodash";
import LoginDialog from "../LoginDialog";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useRef, useEffect } from "react";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import ModalLoader from "../utils/ModalLoader";
import { appSettings, durationOptions } from "../../config";
import { trim } from "lodash";
import AddItemSizeDialog from "../modals/AddItemSizeModal";
import {
  useEffectOnce,
  useIsAdmin,
  useTaxOptions,
  useUnits,
  useIsCashier,
} from "../../utils/hooks";
import ConfirmDialog from "../ConfirmDialogue";
import { addDays, addMonths, addWeeks } from "date-fns";
import BatchSelector from "../utils/BatchSelector";
import SelectBatchDialog from "../SalesAndInvoicing/SelectBatchDialog";
import { fetchActionsUtil } from "../../utils/helpers";
import WarehouseItemTable from "./WarehouseItemTable";
import ItemsPayment from "../ItemsPayment";

const usageOptions = [
  {
    label: "Sales",
    value: "Sales",
  },
  {
    label: "Office Use",
    value: "Office Use",
  },
  {
    label: "Promo",
    value: "Promo",
  },
];

const discountTypes = [
  {
    label: "Per Item",
    value: "Per Item",
  },
  {
    label: "Total Invoice",
    value: "Total Invoice",
  },
];

export default function EditWarehousePurchaseHistoryModal({
  showEditPurchaseHistoryModal,
  setShowEditPurchaseHistoryModal,
  refetch,
  isMulti = true,
  printGRN,
  purchaseHistoryToEdit = [],
  pendingId = false,
  registerAsset,
  requiresPONumber = false,
  warehouseUsed,
  disabledQuantity = false,
  disableVendorPayment = false
}) {
  // console.log(purchaseHistoryToEdit);
  const [isLoading, setIsLoading] = useState(false);
  const barcodeInputRef = useRef();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, token } = useAuth();
  const queryClient = useQueryClient();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showBatchItemsPopover, setShowBatchItemsPopover] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState(purchaseHistoryToEdit);
  const generalSettings = useStoreState((state) => state.generalSettings);
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const { Units, getUnitLabelByValue } = useUnits();
  const taxOptions = useTaxOptions();
  const [batchItemToDelete, setBatchItemToDelete] = useState([]);
  const [payment, setPayment] = useState({
    Payment: "Credit",
    From: "",
  });
  const [paymentFreight, setPaymentFreight] = useState({
    Payment: "Credit",
    From: "",
  });

  const wareshouseLocal = !isEmpty(warehouseUsed)
    ? warehouseUsed
    : JSON.parse(window.localStorage.getItem("warehouseName"));

  const setUp = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/items/set-up?withVendorBalance=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    if (registerAsset) {
      const warehouses = await fetchActionsUtil(
        `${backendUrl}/api/warehouse/categories/Assets`,
        "GET"
      );

      data.warehouses = warehouses?.warehouses?.map((el) => ({
        value: el.W_ID,
        label: el.W_name,
      }));
    }

    data.vendors = data?.vendors.map((el) => ({
      ...el,
      label: trim(el.CompanyName),
      value: el.Vendor_ID,
    }));
    data.itemMeasurement = data?.itemMeasurement.map((el) => ({
      label: el.Size,
      value: el.Size,
    }));

    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    return data;
  };
  const {
    data = {
      vendors: [],
      itemMeasurement: [],
    },
  } = useQuery([queryActions.ITEMS_SETUP], () => setUp(), {
    enabled: true,
  });

  const formik = useFormik({
    initialValues: {
      Vendor: "",
      Item_Type: "Inventory",
      Bar_Code: "",
      Item_Name: "",
      InvoiceNo: "",
      Size: "" /* appSettings.isBatchStandard ? "" : "8 mm" */, //`This is saved to Item_Desc
      Unit:
        /* appSettings.isBatchStandard && !appSettings.hasIncentive
              ? "Each"
              : appSettings.hasIncentive
              ? "Carton"
              : "Tons", */ "",
      Usage: "Sales",
      UnitPrice: 0,
      PurchasePrice: 0,
      Freight: 0,
      UnitCost: 0,
      Quantity: "",
      ManDate: moment(),
      ExpireDate: moment(),
      Date_Log: moment(),
      Product_Name: "",
      Cat_Name: "",
      Branch: "HQ",

      Tax: 0,
      Vat_5: 0,

      //--------
      DriverName: "",
      DriverPhoneNumber: "",
      TruckNumber: "",
      DeliveryDate: moment(),

      // ---
      Reorder_Level: 0,
      sizes: [],

      //---
      Margin: 0,
      Incentive: 0,
      freightVendor: "",
      discountType: "Per Item",
      discount: 0,

      //-----------------------------------------
      marginType: "Fixed",
      marginPercentage: "",
      incentiveType: "Fixed",
      incentivePercentage: "",
      Batch_Name: "",
      age: 0,
      ageDurationValue: "Week",
      parentBatchBarCode: "",
      parentBatchItemName: "",
      parentBatchName: "",
      pcsPriceDistributor: 0,
      markUp: 0,
      markUpType: "Fixed",
      markUpPercentage: "",
      taxType: "None",
      Warehouse: "",
      StorageLocation: "",
      POId: "",

      currency: generalSettings?.prevailingCurrency,
      conversionAmount: generalSettings.dollarInBaseCurrency,
      deductTax: false
    },
    validationSchema: yup.object().shape({
      Bar_Code: yup.string().required("Item Code is required"),
      Item_Name: yup.string().required("Item Name is required"),
      InvoiceNo: yup.string().required("Invoice number is required"),
      Product_Name: yup.string().required("required"),
      Cat_Name: yup.string().required("required"),
      Vendor: yup.string().required(),
      UnitPrice: yup.string().required(),
      UnitCost: yup.string().required(),
      Quantity: yup.string().required(),
      Unit: yup.string().required(),
      ...(requiresPONumber
        ? { POId: yup.string().required("PO  number is required") }
        : {}),
    }),
    onSubmit: async (values) => {
      //  Settings
      if (
        Number(values?.PurchasePrice) === 0 &&
        generalSettings.isPurchasePriceRequired &&
        !isAdmin
      ) {
        return formik.setFieldError("PurchasePrice", "Cost Price is required");
      }

      if (
        Number(values?.PurchasePrice) === 0 &&
        isAdmin &&
        !(await ConfirmDialog({
          title: "Cost Price Required",
          description: "Cost not set do you want to submit",
        }))
      ) {
        return;
      }

      values.vendorName = data?.vendors.find(
        (el) => el.value === values.Vendor
      )?.label;
      values.InvoiceAmount = InvoiceAmount;
      values.AmountDue = InvoiceAmount;
      values.Total_Freight = Total_Freight;

      // save for original values incase needed
      values.ogUnitPrice = selectedItem.UnitPrice;
      values.ogUnitCost = selectedItem.UnitCost;
      values.ogTax = selectedItem.Tax;
      values.ogVat_5 = selectedItem.Vat_5;

      values.totalLandingCost = totalLandingCost;

      if (isMulti) {
        // Check if vendor balance is enough ---
        if (appSettings.isGigc) {
          const vendorBalance = await getSelectedVendorBalance(values.Vendor);
          const isNegative = Number(vendorBalance) < 0;
          const notEnoughError =
            "Not enough balance on Vendor ledger to make this purchase";

          if (isNegative) {
            toast.error(notEnoughError);
            if (
              !(await LoginDialog({
                loginRequired: true,
                title: "Admin approval",
                description: notEnoughError,
              }))
            ) {
              return;
            }
          } else {
            const balance = Math.abs(vendorBalance);

            // sum invoice amt in table excluding - current edited item
            const invoiceAmountAlreadyInTable = tableData
              .filter((el) => el.Bar_Code !== values.Bar_Code)
              .map((el) => el.InvoiceAmount)
              .reduce((a, b) => a + b, 0);

            const totalInvoiceAmount =
              invoiceAmountAlreadyInTable + InvoiceAmount;

            if (Number(balance) < Number(totalInvoiceAmount)) {
              toast.error(notEnoughError);
              if (
                !(await LoginDialog({
                  loginRequired: true,
                  title: "Admin approval",
                  description: notEnoughError,
                }))
              ) {
                return;
              }
            }
          }
        }
        //------------------------------------------

        const foundIndex = tableData.findIndex(
          (el) => el.Bar_Code === values.Bar_Code
        );

        // check if batch has been sold
        const foundPurchaseToEdit = purchaseHistoryToEdit.find(
          (el) => el.Bar_Code === values.Bar_Code
        );

        values.originalPurchasedQuantity = foundPurchaseToEdit?.Quantity
          ? foundPurchaseToEdit?.Quantity
          : 0;
        if (wareshouseLocal?.cater === "Assets") {
        } else {
          if (foundPurchaseToEdit && foundPurchaseToEdit?.batchHasBeenSold) {
            const { batches } = await SelectBatchDialog({
              quantityToSell: Number(foundPurchaseToEdit?.Quantity),
              generalSettings,
              Bar_Code: values.Bar_Code,
              loadBatchFromServer: true,
              previouslySelectedBatches:
                foundIndex !== -1 ? tableData[foundIndex].selectedBatches : [],
              itemsApiUrl: "/api/warehouse",
              warehouse: foundPurchaseToEdit?.Warehouse,
            });
            values.selectedBatches = batches ? batches : [];
          }

          // if is in delete list, it means the item was added back,
          const foundBatchItemToDelete = batchItemToDelete.find(
            (el) => el.Bar_Code === values.Bar_Code
          );

          if (!isEmpty(foundBatchItemToDelete)) {
            values.originalPurchasedQuantity = 0;
          }
        }

        if (foundIndex !== -1) {
          tableData[foundIndex] = values;
          setTableData([...tableData]);
        } else {
          setTableData([...tableData, values]);
        }

        formik.resetForm();
        formik.setFieldValue("InvoiceNo", values.InvoiceNo);
        formik.setFieldValue("Vendor", values.Vendor);
        formik.setFieldValue("discountType", values.discountType);
        formik.setFieldValue("discount", values.discount);
        formik.setFieldValue("taxType", values.taxType);
        formik.setFieldValue("POId", values.POId);

        barcodeInputRef.current.focus();
        return;
      }
      updateMutation.mutate({
        editedItem: values,
        vendorName: values.vendorName,
      });
    },
    onReset: () => {
      // handleSelectedItem(null);
    },
  });

  const discountPerItem = useMemo(() => {
    return formik.values.discountType === "Per Item"
      ? formik.values.discount
      : 0;
  }, [formik.values.discountType, formik.values.discount]);

  const discountTotalInvoice = useMemo(() => {
    return formik.values.discountType === "Total Invoice"
      ? formik.values.discount
      : 0;
  }, [formik.values.discountType, formik.values.discount]);

  const [invoiceDiscount, setInvoiceDiscount] = useState(0);
  const totalDiscount = useMemo(() => {
    const totalDiscountOnTableData = tableData
      .map((el) => el.discount)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b).value,
        0
      );
    return currency(totalDiscountOnTableData).add(invoiceDiscount).value;
  }, [tableData, invoiceDiscount]);

  const totalInvoiceAmount = useMemo(() => {
    return currency(
      tableData
        .map((el) => el.InvoiceAmount)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        ),
      {
        symbol: "",
      }
    ).format();
  }, [tableData]);

  const totalLandingCost = useMemo(() => {
    return currency(formik.values.UnitCost, {
      symbol: "",
      separator: "",
    })
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.UnitCost, formik.values.Quantity]);

  useEffectOnce(() => {
    const foundPurchaseToEdit = purchaseHistoryToEdit[0];
    if (foundPurchaseToEdit) {
      formik.setFieldValue("InvoiceNo", foundPurchaseToEdit.InvoiceNo);
      formik.setFieldValue("POId", foundPurchaseToEdit.PONumber);
      formik.setFieldValue("Vendor", foundPurchaseToEdit.Vendor);
      formik.setFieldValue("taxType", foundPurchaseToEdit?.taxType || "None");

      if (foundPurchaseToEdit?.payment) {
        setPayment(foundPurchaseToEdit?.payment);
      }
      if (foundPurchaseToEdit?.paymentFreight) {
        setPaymentFreight(foundPurchaseToEdit?.paymentFreight);
      }
    }
  });

  useEffect(() => {
    const UnitCost = currency(formik.values.PurchasePrice, {
      symbol: "",
      separator: "",
    })
      .subtract(discountPerItem)
      .add(formik.values.Freight)
      .format();
    formik.setFieldValue("UnitCost", UnitCost);
  }, [formik.values.PurchasePrice, formik.values.Freight, discountPerItem]);

  //For Incentive
  useEffect(() => {
    if (formik.values.markUpType === "Percentage") {
      formik.setFieldValue(
        "markUp",
        currency(formik.values.markUpPercentage)
          .divide(100)
          .multiply(formik.values.UnitCost).value
      );
    }

    if (formik.values.incentiveType === "Percentage") {
      formik.setFieldValue(
        "Incentive",
        currency(formik.values.incentivePercentage)
          .divide(100)
          .multiply(formik.values.markUp).value
      );
    }

    if (formik.values.marginType === "Percentage") {
      formik.setFieldValue(
        "Margin",
        currency(formik.values.marginPercentage)
          .divide(100)
          .multiply(formik.values.markUp).value
      );
    }
  }, [
    formik.values.UnitCost,
    formik.values.marginPercentage,
    formik.values.incentivePercentage,
    formik.values.marginType,
    formik.values.incentiveType,
  ]);

  useEffect(() => {
    if (appSettings.hasIncentive) {
      const UnitPrice = currency(formik.values.UnitCost)
        .add(formik.values.Incentive)
        .add(formik.values.Margin).value;

      formik.setFieldValue("UnitPrice", UnitPrice);
      formik.setFieldValue("Tax", UnitPrice);
      formik.setFieldValue("Vat_5", UnitPrice);
    }
  }, [formik.values.UnitCost, formik.values.Margin, formik.values.Incentive]);

  const updateItem = async (payload) => {
    payload.mode = "edit";
    payload.batchItemToDelete = batchItemToDelete;
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/warehouse/update-stock`, {
      method: "POST",
      credentials: "include",
      body: formData,
      headers: {
        /*  Accept: "Application/json",
            "Content-Type": "Application/json", */
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updateMutation = useMutation((payload) => updateItem(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      if (printGRN) printGRN(tableData[0].InvoiceNo);
      formik.resetForm();
      if (refetch) refetch();
      setShowEditPurchaseHistoryModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const InvoiceAmount = useMemo(
    () =>
      currency(formik.values.PurchasePrice, {
        symbol: "",
        separator: "",
      })
        .multiply(formik.values.Quantity)
        .format(),
    [formik.values.PurchasePrice, formik.values.Quantity]
  );

  const Total_Freight = useMemo(
    () =>
      currency(formik.values.Freight, {
        symbol: "",
        separator: "",
      })
        .multiply(formik.values.Quantity)
        .format(),
    [formik.values.Freight, formik.values.Quantity]
  );

  /*   const addItemMeasurement = async () => {
        const newSize = await ItemSizeDialog();
        if (newSize) {
          queryClient.setQueryData([queryActions.ITEMS_SETUP], (data) => {
            data.itemMeasurement = [
              ...data.itemMeasurement,
              { value: newSize.Size, label: newSize.Size },
            ];
            return data;
          });
          formik.setFieldValue("Size", newSize.Size);
        }
      }; */

  const getItemSizes = (item) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let response = await fetch(
          `${backendUrl}/api/items/get-item/${item.Bar_Code}`,
          {
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          response = await response.json();
          toast.error(response.message);
        } else {
          const {
            data: { sizes },
          } = await response.json();

          resolve(sizes);
        }
      } catch (err) {
        console.log(err);
        toast.error("Unable to perform action");
        reject();
      } finally {
        setIsLoading(false);
      }
    });
  };

  const handleSelectedItem = async (item) => {
    // console.log(item);
    setShowItemsPopover(false);
    const sizes = appSettings.hasSize ? await getItemSizes(item) : [];

    formik.resetForm();
    item.Vendor = trim(item.Vendor);

    const oldVendor = data.vendors.find(
      (el) => el.value === item.Vendor || el.label === item.Vendor
    );

    // console.log("oldVendor => ", oldVendor);

    const foundItem = tableData.find((el) => el.Bar_Code === item.Bar_Code);
    const canPresetItemValues =
      (!foundItem && generalSettings.presetItemValues) || isAdmin;

    formik.setValues({
      ...formik.values,
      ...(!isEmpty(tableData) ? { Vendor: tableData[0].Vendor } : {}),
      Item_Type: item.Item_Type,
      Bar_Code: item.Bar_Code,
      Item_Name: item.Item_Name,
      // InvoiceNo: "",
      Size: item.Item_Desc,
      Unit: item.Product_Model,
      Usage: item.Usage,
      UnitPrice: canPresetItemValues ? item.UnitPrice : 0,
      UnitCost: canPresetItemValues ? item.UnitCost : 0,
      Quantity: "",
      ManDate: moment(new Date(item.ManDate)),
      ExpireDate: moment(new Date(item.ExpireDate)),
      Date_Log: !isEmpty(tableData)
        ? tableData[0].Date_Log
        : moment(new Date()),
      Product_Name: item.Product_Name,
      Cat_Name: item.Cat_Name,
      Branch: item.Branch,

      Tax: canPresetItemValues ? item.Tax : 0,
      Vat_5: canPresetItemValues ? item.Vat_5 : 0,
      PurchasePrice: canPresetItemValues ? item.UnitCost : 0,
      Reorder_Level: item.Reorder_Level,
      sizes: sizes ? sizes.map((el) => ({ ...el, Quantity: 0 })) : [],

      //-----------------------
      Margin: canPresetItemValues ? item.Margin : 0,
      Incentive: canPresetItemValues ? item.Incentive : 0,
      pcsPriceDistributor: canPresetItemValues ? item?.pcsPriceDistributor : 0,

      Warehouse: registerAsset ? "" : item.Warehouse,
      StorageLocation: registerAsset
        ? ""
        : item.StorageLocation
          ? item.StorageLocation
          : "",
    });

    // save for original values incase needed
    item.ogUnitPrice = item.UnitPrice;
    item.ogUnitCost = item.UnitCost;
    item.ogTax = item.Tax;
    item.ogVat_5 = item.Vat_5;

    setSelectedItem(item);
    scrollToElement('input[name="Quantity"]', { scroll: false });
  };

  const handleSelectedItemToEdit = (item) => {
    console.log(item);
    formik.resetForm();
    formik.setValues({
      ...formik.values,
      ...item,
    });
    setSelectedItem(item);
    setShowItemsPopover(false);
    scrollToElement('input[name="Quantity"]', { scroll: false });
  };

  const deleteItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteItem(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      setSelectedItem(null);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleDeleteItem = async () => {
    if (isEmpty(selectedItem)) return toast.error("No Item Selected");
    if (await LoginDialog()) {
      if (
        await ConfirmDialogue({
          title: "Delete Item",
          description:
            "Are you sure you want to delete this Item from your inventory",
        })
      ) {
        deleteMutation.mutate(selectedItem);
      }
    }
  };

  const handleRemoveItem = async (index, item) => {
    // check if batch has been sold
    const foundPurchaseToEdit = purchaseHistoryToEdit.find(
      (el) => el.Bar_Code === item.Bar_Code
    );
    // Check if Item has been removed before

    if (wareshouseLocal?.cater === "Assets") {
      setBatchItemToDelete([
        ...batchItemToDelete,
        {
          ...foundPurchaseToEdit,
          originalPurchasedQuantity: foundPurchaseToEdit.Quantity,
        },
      ]);
    } else {
      const foundBatchItemToDelete = batchItemToDelete.find(
        (el) => el.Bar_Code === item.Bar_Code
      );

      if (foundPurchaseToEdit && !foundBatchItemToDelete) {
        if (foundPurchaseToEdit?.batchHasBeenSold) {
          const { batches = [] } = await SelectBatchDialog({
            quantityToSell: Number(item.Quantity),
            generalSettings,
            Bar_Code: item.Bar_Code,
            loadBatchFromServer: true,
            previouslySelectedBatches:
              index !== -1 ? tableData[index].selectedBatches : [],
            itemsApiUrl: "/api/warehouse",
            warehouse: foundPurchaseToEdit?.Warehouse,
          });

          setBatchItemToDelete([
            ...batchItemToDelete,
            {
              ...foundPurchaseToEdit,
              originalPurchasedQuantity: foundPurchaseToEdit.Quantity,
              selectedBatches: batches,
            },
          ]);
        } else {
          // batch has not been sold
          setBatchItemToDelete([
            ...batchItemToDelete,
            {
              ...foundPurchaseToEdit,
              originalPurchasedQuantity: foundPurchaseToEdit.Quantity,
              selectedBatches: foundPurchaseToEdit.selectedBatches,
            },
          ]);
        }
      }
    }
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const saveMulti = async () => {
    // Validations
    if (payment.Payment !== "Credit") {
      if (isEmpty(payment.From)) {
        return toast.error("Select Payment Source");
      }
      if (!payment.vendorPaymentAmount) {
        return toast.error("Select Payment Amount");
      }
    }

    if (paymentFreight.Payment !== "Credit") {
      if (isEmpty(paymentFreight.From)) {
        return toast.error("Select Payment Source for Freight");
      }
      if (!paymentFreight.vendorPaymentAmount) {
        return toast.error("Select Payment Amount for Freight");
      }
    }

    // Check if batches have been sold
    // console.log(batchItemToDelete);

    if (
      await ConfirmDialog({
        title: pendingId ? "Approve" : "Save",
        description: "Are you sure ?",
      })
    ) {
      updateMutation.mutate({
        isMulti: true,
        stockItems: tableData,
        recordAccounting: true,
        pendingId,
        taxValue,
        taxType: formik.values.taxType,
        deductTax: formik.values.deductTax,
        vendorPaymentData: payment,
        vendorFreightPaymentData: paymentFreight,
      });
    }
  };

  const getSelectedVendorBalance = (vendor) => {
    const vendorData = data?.vendors?.find(
      (el) => el.value === vendor || el.label === vendor
    );
    return vendorData ? vendorData?.balance : 0;
  };

  const sizeAndColor = async () => {
    if (Number(formik.values.Quantity) === 0) {
      toast.error("Please enter quantity");
      formik.setFieldError("Quantity", "Please enter quantity");
      return;
    }

    const data = await AddItemSizeDialog({
      item: formik.values,
      maxQuantity: formik.values.Quantity,
      previousSizes: formik.values.sizes,
    });

    if (data === false) return;

    formik.setFieldValue(
      "sizes",
      data?.sizesAndColours ? data.sizesAndColours : []
    );
  };

  const getBatches = async (selectedItemFromPopover) => {
    //  check if  batch has been  fetched before
    /* const previouslySelectedItem = tableData.find(
          (el) => el.Bar_Code === selectedItemFromPopover.Bar_Code
        );
        if (previouslySelectedItem) {
        } */

    try {
      setIsLoading(true);

      let response = await fetch(
        `${backendUrl}/api/items/batches-for-sales/${selectedItemFromPopover.Bar_Code
        }`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { /*  batches = [], sizes = [], */ item = {} },
        } = await response.json();
        if (item) {
          handleSelectedItem(item);
        } else {
          toast.error("Item not found");
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to load batches, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const age = formik.values.age;
    const ageDurationValue = formik.values.ageDurationValue;

    const manufacturedDate =
      ageDurationValue === "Day"
        ? addDays(new Date(), -Number(age))
        : ageDurationValue === "Week"
          ? addWeeks(new Date(), -Number(age))
          : ageDurationValue === "Month"
            ? addMonths(new Date(), -Number(age))
            : new Date();

    formik.setFieldValue("ManDate", manufacturedDate);
  }, [formik.values.age, formik.values.ageDurationValue]);

  const handleSelectedBatchItem = (batchItem) => {
    formik.setFieldValue("parentBatchBarCode", batchItem.Bar_Code);
    formik.setFieldValue("parentBatchItemName", batchItem.ItemName);
    formik.setFieldValue("parentBatchName", batchItem.Batch_Name);
    setShowBatchItemsPopover(false);
  };

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(totalInvoiceAmount, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType?.percentage)
      .format();
  }, [totalInvoiceAmount, formik.values.taxType]);

  const totalFreight = useMemo(() => {
    return tableData
      .map((el) => el.Total_Freight)
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b).value,
        0
      );
  });

  return (
    <Modal
      show={showEditPurchaseHistoryModal}
      onHide={() => setShowEditPurchaseHistoryModal(false)}
      dialogClassName={`new-entity-modal ${isMulti ? "multi-update" : ""}`}
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>
            Edit Purchase{" "}
            <CachedIcon
              onClick={() => formik.resetForm()}
              className="text-primary"
            />{" "}
          </h1>
          <p>Replenish your Inventory with fresh stock or supplies </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-0 ${isMulti ? "d-md-flex pb-1" : ""}`}>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className={`row new-item-form w-100 m-0 ${isMulti ? "flex-column border rounded mx-4" : ""
            } `}
          autoComplete="off"
        >
          <div className={`${isMulti ? "w-auto pt-4" : "col-md-6 px-4"}`}>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Code</Form.Label>
              <InputGroup>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Item Code"
                  name="Bar_Code"
                  value={formik.values.Bar_Code}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Bar_Code && !!formik.errors.Bar_Code
                  }
                  ref={barcodeInputRef}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      e.stopPropagation();
                      getBatches({ Bar_Code: e.target.value });
                    }
                  }}
                />

                <Popover
                  isOpen={showItemsPopover}
                  onClickOutside={() => setShowItemsPopover(false)}
                  content={() => (
                    <WarehouseItemTable
                      handleSelectedItem={handleSelectedItem}
                    />
                  )}
                  position="bottom"
                >
                  <InputGroup.Text
                    onClick={() => setShowItemsPopover(!showItemsPopover)}
                  >
                    <MagnifyIcon />
                  </InputGroup.Text>
                </Popover>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {formik.errors.Bar_Code}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Name</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Item Name"
                name="Item_Name"
                value={formik.values.Item_Name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.Item_Name && !!formik.errors.Item_Name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Item_Name}
              </Form.Control.Feedback>
            </Form.Group>

            {generalSettings.isFarm && (
              <>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">
                    {generalSettings.Batch_Name}
                  </Form.Label>
                  <Form.Control
                    className=""
                    type="text"
                    placeholder={`Enter ${generalSettings.Batch_Name}`}
                    name="Batch_Name"
                    value={formik.values.Batch_Name}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.Batch_Name && !!formik.errors.Batch_Name
                    }
                  />
                </Form.Group>

                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Parent Batch</Form.Label>
                  <InputGroup>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Select Parent Batch"
                      name="Bar_Code"
                      value={`${formik.values.parentBatchBarCode ||
                        "..."} / ${formik.values.parentBatchItemName ||
                        "..."} / ${formik.values.parentBatchName || "..."}`}
                      onChange={() => { }}
                    />

                    <Popover
                      isOpen={showBatchItemsPopover}
                      onClickOutside={() => setShowBatchItemsPopover(false)}
                      content={() => (
                        <BatchSelector
                          handleSelectedBatchItem={handleSelectedBatchItem}
                        />
                      )}
                      position="bottom"
                    >
                      <InputGroup.Text
                        onClick={() =>
                          setShowBatchItemsPopover(!showBatchItemsPopover)
                        }
                      >
                        <MagnifyIcon />
                      </InputGroup.Text>
                    </Popover>
                  </InputGroup>
                </Form.Group>
              </>
            )}

            <Form.Group className="mb-3 pb-2">
              <Form.Label>
                Quantity ({getUnitLabelByValue(formik.values.Unit)})
              </Form.Label>
              <NumberCustomInput
                placeholder="0"
                name="Quantity"
                value={formik.values.Quantity}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                onBlur={() => formik.setFieldTouched("Quantity", true)}
                disabled={disabledQuantity}
              />
              {formik.touched.Quantity && !!formik.errors.Quantity ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.Quantity}
                </span>
              ) : null}
            </Form.Group>

            {appSettings.hasSize ? (
              <Form.Group className="mb-3 pb-2">
                <Button size="sm" type="button" onClick={() => sizeAndColor()}>
                  + Size / Color
                </Button>

                <span>
                  {formik.values.sizes.map((el) => (
                    <span>{` ${el.Size_Color} - ${el.Quantity},`}</span>
                  ))}
                </span>
              </Form.Group>
            ) : null}

            <div className="row text-nowrap align-items-center">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Unit</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={unitsResolver(Units, formik.values.Unit)}
                  value={Units.find((el) => el.value === formik.values.Unit)}
                  onChange={({ value }) => {
                    formik.setFieldValue("Unit", value);
                  }}
                />
                {formik.touched.Unit && !!formik.errors.Unit ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Unit}
                  </span>
                ) : null}
              </Form.Group>

              {["Tons", "Pieces"].includes(formik.values.Unit) ? (
                <>
                  <Form.Group className="col-6 mb-3 pb-2">
                    <Form.Label className="mb-1">Size</Form.Label>
                    <Select
                      classNamePrefix={`form-select`}
                      options={data?.itemMeasurement}
                      value={data?.itemMeasurement.find(
                        (el) => el.value === formik.values.Size
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("Size", value);
                      }}
                      isDisabled
                    />
                  </Form.Group>
                </>
              ) : (
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">
                    Item Description (Optional)
                  </Form.Label>
                  <Form.Control
                    className=""
                    as="textarea"
                    placeholder="Enter Description"
                    name="Size"
                    value={formik.values.Size}
                    rows={2}
                    onChange={formik.handleChange}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group>
              )}
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor Invoice No</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Vendor Invoice No"
                name="InvoiceNo"
                value={formik.values.InvoiceNo}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.InvoiceNo && !!formik.errors.InvoiceNo
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.InvoiceNo}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">PO Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter PO No"
                name="POId"
                value={formik.values.POId}
                onChange={formik.handleChange}
                isInvalid={formik.touched.POId && !!formik.errors.POId}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.POId}
              </Form.Control.Feedback>
            </Form.Group>

            <hr />
            <h2 className="mb-3 mt-4 pb-1">Delivery Information</h2>

            <Form.Group className="mb-3 pb-1">
              <Form.Label className="mb-1">Driver</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Driver's Name"
                name="DriverName"
                value={formik.values.DriverName}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3 pb-1">
              <Form.Label className="mb-1">Driver Phone Number</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter Phone number"
                name="DriverPhoneNumber"
                value={formik.values.DriverPhoneNumber}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Row>
              <Form.Group className="col mb-3 pb-1">
                <Form.Label className="mb-1">Truck Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Truck Number"
                  name="TruckNumber"
                  value={formik.values.TruckNumber}
                  onChange={formik.handleChange}
                />
              </Form.Group>

              <Form.Group className="col mb-3 pb-2">
                <Form.Label className="mb-1">Date Delivered</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.DeliveryDate}
                  onChange={(date) => {
                    formik.setFieldValue("DeliveryDate", date, true);
                  }}
                />
              </Form.Group>
            </Row>

            <hr />

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Manufacturer</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data.products}
                value={data?.products?.find(
                  (el) => el.value === formik.values.Product_Name
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("Product_Name", value)
                }
              />
              {formik.touched.Product_Name && formik.errors.Product_Name ? (
                <span className="text-danger mt-2">
                  {formik.errors.Product_Name}
                </span>
              ) : null}
            </Form.Group>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Category</Form.Label>
              <CreatableSelect
                classNamePrefix={`form-select`}
                options={data.categories}
                value={data?.categories?.find(
                  (el) => el.value === formik.values.Cat_Name
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("Cat_Name", value)
                }
              />
              {formik.touched.Cat_Name && formik.errors.Cat_Name ? (
                <span className="text-danger mt-2">
                  {formik.errors.Cat_Name}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label>Quantity In Stock</Form.Label>
              <Form.Control
                value={
                  selectedItem?.Quantity && formik.values.Bar_Code
                    ? qtyFormatToString(
                      qtyFormat(
                        selectedItem?.Quantity,
                        selectedItem?.Item_Desc,
                        itemMeasurements
                      )
                    )
                    : ""
                }
                readOnly
              />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={data.vendors}
                value={
                  data?.vendors?.find(
                    (el) => el.value === formik.values.Vendor
                  ) || ""
                }
                onChange={({ value }) => formik.setFieldValue("Vendor", value)}
                isSearchable={true}
              />
              {formik.touched.Vendor && formik.errors.Vendor ? (
                <span className="text-danger mt-2">{formik.errors.Vendor}</span>
              ) : null}
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Vendor Balance</Form.Label>
              <CurrencyCustomInput
                placeholder="0.00"
                value={getSelectedVendorBalance(formik.values.Vendor)}
                readOnly
              />
            </Form.Group>

            <div className="row">
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Usage</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={usageOptions}
                  value={usageOptions.find(
                    (el) => el.value === formik.values.Usage
                  )}
                  onChange={({ value }) => formik.setFieldValue("Usage", value)}
                />
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Reorder Level</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="Reorder Lvl"
                  name="Reorder_Level"
                  value={formik.values.Reorder_Level}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <Form.Group className="col-md-6 mb-3 pb-2">
              {/*  <Form.Label className="mb-1">Cost Price</Form.Label> */}
              <Form.Label className="mb-1">Unit Cost</Form.Label>
              <CurrencyCustomInput
                name="PurchasePrice"
                placeholder="0.00"
                value={formik.values.PurchasePrice}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.PurchasePrice && !!formik.errors.PurchasePrice
                }
                onBlur={() => formik.setFieldTouched("PurchasePrice", true)}
              />
              {formik.touched.PurchasePrice && !!formik.errors.PurchasePrice ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.PurchasePrice}
                </span>
              ) : null}
            </Form.Group>
            <Form.Group className="col-md-6 mb-3 pb-2">
              <Form.Label className="mb-1">Freight</Form.Label>
              <CurrencyCustomInput
                name="Freight"
                placeholder="0.00"
                value={formik.values.Freight}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Freight && !!formik.errors.Freight}
                onBlur={() => formik.setFieldTouched("Freight", true)}
              />
              {formik.touched.Freight && !!formik.errors.Freight ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.Freight}
                </span>
              ) : null}
            </Form.Group>
            {/*  <Form.Group className="col-md-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Discount Type</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    options={discountTypes}
                    value={discountTypes.find(
                      (el) => el.value === formik.values.discountType
                    )}
                    onChange={(selected) =>
                      formik.setFieldValue("discountType", selected?.value)
                    }
                  />
                </Form.Group> */}
            {/*   <Form.Group className="col-md-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Vendor Discount For Item</Form.Label>
                  <CurrencyCustomInput
                    name="discount"
                    placeholder="0.00"
                    value={formik.values.discount}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={formik.touched.discount && !!formik.errors.discount}
                    onBlur={() => formik.setFieldTouched("discount", true)}
                  />
                  {formik.touched.discount && !!formik.errors.discount ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.discount}
                    </span>
                  ) : null}
                </Form.Group> */}
          </div>

          {generalSettings.hasfreightVendor && (
            <Form.Group className="col-md-12 mb-3 pb-2">
              <Form.Label className="mb-1">Freight Vendor</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={data.vendors}
                value={
                  data?.vendors?.find(
                    (el) => el.value === formik.values.freightVendor
                  ) || ""
                }
                onChange={({ value }) =>
                  formik.setFieldValue("freightVendor", value)
                }
              />
            </Form.Group>
          )}

          <div className={`${isMulti ? "w-auto" : "col-md-6 px-4"}`}>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Landing Unit Cost</Form.Label>
              <CurrencyCustomInput
                name="UnitCost"
                placeholder="0.00"
                value={formik.values.UnitCost}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.UnitCost && !!formik.errors.UnitCost}
                onBlur={() => formik.setFieldTouched("UnitCost", true)}
              />
              {formik.touched.UnitCost && !!formik.errors.UnitCost ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.UnitCost}
                </span>
              ) : null}
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            {appSettings.hasIncentive ? (
              <>
                <Row>
                  <Form.Group className="col-md-4 mb-3 pb-2">
                    <Form.Label className="mb-1">Mark Up Type</Form.Label>
                    <Select
                      classNamePrefix={"form-select"}
                      options={amountTypes}
                      value={amountTypes.find(
                        (el) => el.value === formik.values.markUpType
                      )}
                      onChange={(selected) =>
                        formik.setFieldValue("markUpType", selected.value)
                      }
                    />
                  </Form.Group>

                  {formik.values.markUpType === "Percentage" && (
                    <Form.Group className="col-md-3 mb-3 pb-2">
                      <Form.Label className="mb-1">Mark Up (%)</Form.Label>
                      <Form.Control
                        type="number"
                        name="markUpPercentage"
                        value={formik.values.markUpPercentage}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  )}

                  <Form.Group className="col-md-5 mb-3 pb-2">
                    <Form.Label className="mb-1">Mark Up</Form.Label>
                    <CurrencyCustomInput
                      name="markUp"
                      placeholder="0.00"
                      value={formik.values.markUp}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                      isInvalid={
                        formik.touched.markUp && !!formik.errors.markUp
                      }
                      onBlur={() => formik.setFieldTouched("markUp", true)}
                      disabled={formik.values.markUpType === "Percentage"}
                    />
                    {formik.touched.markUp && !!formik.errors.markUp ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.markUp}
                      </span>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="col-md-4 mb-3 pb-2">
                    <Form.Label className="mb-1">Profit Type</Form.Label>
                    <Select
                      classNamePrefix={"form-select"}
                      options={amountTypes}
                      value={amountTypes.find(
                        (el) => el.value === formik.values.marginType
                      )}
                      onChange={(selected) =>
                        formik.setFieldValue("marginType", selected.value)
                      }
                    />
                  </Form.Group>

                  {formik.values.marginType === "Percentage" && (
                    <Form.Group className="col-md-3 mb-3 pb-2">
                      <Form.Label className="mb-1">Profit (%)</Form.Label>
                      <Form.Control
                        type="number"
                        name="marginPercentage"
                        value={formik.values.marginPercentage}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  )}

                  <Form.Group className="col-md-5 mb-3 pb-2">
                    <Form.Label className="mb-1">Profit</Form.Label>
                    <CurrencyCustomInput
                      name="Margin"
                      placeholder="0.00"
                      value={formik.values.Margin}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                      isInvalid={
                        formik.touched.Margin && !!formik.errors.Margin
                      }
                      onBlur={() => formik.setFieldTouched("Margin", true)}
                      disabled={formik.values.marginType === "Percentage"}
                    />
                    {formik.touched.Margin && !!formik.errors.Margin ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Margin}
                      </span>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="col-md-4 mb-3 pb-2">
                    <Form.Label className="mb-1">Incentive Type</Form.Label>
                    <Select
                      classNamePrefix={"form-select"}
                      options={amountTypes}
                      value={amountTypes.find(
                        (el) => el.value === formik.values.incentiveType
                      )}
                      onChange={(selected) =>
                        formik.setFieldValue("incentiveType", selected.value)
                      }
                    />
                  </Form.Group>

                  {formik.values.incentiveType === "Percentage" && (
                    <Form.Group className="col-md-3 mb-3 pb-2">
                      <Form.Label className="mb-1 text-nowrap">
                        Incentive (%)
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="incentivePercentage"
                        value={formik.values.incentivePercentage}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                  )}

                  <Form.Group className="col-md-5 mb-3 pb-2">
                    <Form.Label className="mb-1">Incentive</Form.Label>
                    <CurrencyCustomInput
                      name="Incentive"
                      placeholder="0.00"
                      value={formik.values.Incentive}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                      isInvalid={
                        formik.touched.Incentive && !!formik.errors.Incentive
                      }
                      onBlur={() => formik.setFieldTouched("Incentive", true)}
                      disabled={formik.values.incentiveType === "Percentage"}
                    />
                    {formik.touched.Incentive && !!formik.errors.Incentive ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Incentive}
                      </span>
                    ) : null}
                  </Form.Group>
                </Row>
              </>
            ) : null}

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">
                Unit Price (Retail/Walk In){" "}
              </Form.Label>
              <CurrencyCustomInput
                placeholder="0.00"
                name="UnitPrice"
                value={formik.values.UnitPrice}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.UnitPrice && !!formik.errors.UnitPrice
                }
                onBlur={() => formik.setFieldTouched("UnitPrice", true)}
              />
            </Form.Group>

            <div className="row">
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">
                  Unit Price{" "}
                  {["Tons", "Pieces"].includes(formik.values.Unit)
                    ? "(Pieces)"
                    : "(Wholesale)"}
                </Form.Label>
                <CurrencyCustomInput
                  name="Tax"
                  placeholder="0.00"
                  value={formik.values.Tax}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Tax && !!formik.errors.Tax}
                  onBlur={() => formik.setFieldTouched("Tax", true)}
                />
                {formik.touched.Tax && !!formik.errors.Tax ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Tax}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">
                  Unit Price (Distributor)
                </Form.Label>
                <CurrencyCustomInput
                  name="Vat_5"
                  placeholder="0.00"
                  value={formik.values.Vat_5}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Vat_5 && !!formik.errors.Vat_5}
                  onBlur={() => formik.setFieldTouched("Vat_5", true)}
                />
                {formik.touched.Vat_5 && !!formik.errors.Vat_5 ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Vat_5}
                  </span>
                ) : null}
              </Form.Group>

              {formik.values.Unit === "Tons" && (
                <Form.Group className="col-md-6 mb-3 pb-2 d-flex flex-column justify-content-between">
                  <Form.Label>Pieces Price (Distributor) </Form.Label>
                  <CurrencyCustomInput
                    type="text"
                    name="pcsPriceDistributor"
                    value={formik.values.pcsPriceDistributor}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={
                      formik.touched.pcsPriceDistributor &&
                      !!formik.errors.pcsPriceDistributor
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.pcsPriceDistributor}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1" title={totalLandingCost}>
                Amount Rcv
              </Form.Label>
              <CurrencyCustomInput
                placeholder="0.00"
                value={InvoiceAmount}
                readOnly
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>
            {generalSettings.isFarm && (
              <Form.Group className="mb-3 pb-2">
                <Form.Label>Batch Age</Form.Label>
                <Row>
                  <Col>
                    <NumberCustomInput
                      placeholder="0"
                      name="age"
                      value={formik.values.age}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value, true);
                      }}
                    />
                  </Col>
                  <Col>
                    <Select
                      classNamePrefix={"form-select"}
                      placeholder={""}
                      value={durationOptions.find(
                        (el) => el.value === formik.values.ageDurationValue
                      )}
                      options={durationOptions}
                      onChange={({ value }) =>
                        formik.setFieldValue("ageDurationValue", value)
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>
            )}

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Branch</Form.Label>
                <Form.Control defaultValue={formik.values.Branch} readOnly />
              </Form.Group>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Man Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.ManDate}
                  onChange={(date) => {
                    formik.setFieldValue("ManDate", date, true);
                  }}
                />
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-1 pb-2">
                <Form.Label className="mb-1">Expire Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.ExpireDate}
                  onChange={(date) => {
                    formik.setFieldValue("ExpireDate", date, true);
                  }}
                />
              </Form.Group>

              <Form.Group className="col-6 mb-1 pb-2">
                <Form.Label className="mb-1">Post Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.Date_Log}
                  onChange={(date) => {
                    formik.setFieldValue("Date_Log", date, true);
                  }}
                />
              </Form.Group>
            </div>
          </div>

          {isMulti && (
            <div className="d-flex justify-content-end py-3 add-to-cart-holder">
              <Button
                variant="outline-primary"
                type="submit"
                className="action-btn"
              >
                Add To Cart
              </Button>
            </div>
          )}
        </Form>

        {isMulti && (
          <div className="item-cart-area px-4">
            {!isEmpty(tableData) ? (
              <>
                {" "}
                <h2 className="mb-4">Item Cart</h2>
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th />
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Vendor</th>
                      <th>Unit Cost</th>
                      <th>Unit Price</th>
                      <th>Quantity</th>
                      {/*   <th>Discount</th> */}
                      <th>Amount Rcv</th>
                      <th>Freight Vendor</th>
                      <th>Freight</th>
                      {/*  <th>Discount Type</th> */}
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((el, index) => (
                      <tr key={index} className="p-cursor">
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-white border-0"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleSelectedItemToEdit(el)}
                              >
                                <EditIcon />
                                Edit Item
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleRemoveItem(index, el)}
                              >
                                <DeleteIcon />
                                Remove Item
                              </Dropdown.Item>
                            </Dropdown.Menu>{" "}
                          </Dropdown>
                        </td>
                        <td>{el.Bar_Code}</td>
                        <td>{el.Item_Name}</td>
                        <td>{el?.vendorName}</td>
                        <td>
                          {currency(el.UnitCost, { symbol: "" }).format()}
                        </td>
                        <td>
                          {currency(el.UnitPrice, { symbol: "" }).format()}
                        </td>
                        <td>
                          {currency(el.Quantity, { symbol: "" }).format()}{" "}
                          {["Tons", "Pieces"].includes(el.Unit) ? el.Unit : ""}
                        </td>
                        {/*   <td>
                              {currency(el.discount, { symbol: "" }).format()}
                            </td> */}
                        <td>
                          {currency(el.InvoiceAmount, { symbol: "" }).format()}
                        </td>{" "}
                        <td>
                          {el.freightVendorName ||
                            data?.vendors.find(
                              (vendor) => vendor.value === el?.freightVendor
                            )?.label}
                        </td>
                        <td>
                          {" "}
                          {currency(el.Total_Freight, { symbol: "" }).format()}
                        </td>
                        {/*  <td>{el.discountType}</td> */}
                        <td className="text-nowrap">
                          {el.Date_Log ? formatDate(el.Date_Log) : "..."}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <hr />
                <div className="col-8 row mb-3">
                  <Form.Group className="col-6">
                    <Form.Label className="fw-5">Tax </Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Select"
                      isSearchable={false}
                      value={taxOptions.find(
                        (el) => el.value === formik.values.taxType
                      )}
                      options={taxOptions}
                      onChange={({ value }) => {
                        formik.setFieldValue("taxType", value);
                        setTableData((oldData) =>
                          oldData.map((el) => ({ ...el, taxType: value }))
                        );
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="col-4">
                    <Form.Label className="fw-5">
                      {formik.values.taxType}
                    </Form.Label>
                    <CurrencyCustomInput
                      name="tax"
                      value={taxValue}
                      onValueChange={(value, name) => {
                        //   formik.setFieldValue(name, value);
                      }}
                      placeholder="0.00"
                    />
                  </Form.Group>

                  <Form.Label className="col-2">
                    <Form.Label className="fw-5">&nbsp;</Form.Label>
                    <Form.Switch
                      className="text-nowrap"
                      label="Deduct Tax"
                      checked={formik.values.deductTax}
                      onChange={() =>
                        formik.setFieldValue("deductTax", !formik.values.deductTax)
                      }
                    />
                  </Form.Label>
                </div>{" "}
                {isAdmin || isCashier ? (
                  <>
                    <hr />
                    <div className="col-12">
                      <Form.Label className="fw-bold">
                        Vendor Payment
                      </Form.Label>
                      <ItemsPayment payment={payment} disableVendorPayment={disableVendorPayment} setPayment={setPayment} />

                      {generalSettings.hasfreightVendor && (
                        <div className="mt-3">
                          <Form.Label className="fw-bold">
                            Freight Vendor Payment
                          </Form.Label>
                          <ItemsPayment
                            payment={paymentFreight}
                            setPayment={setPaymentFreight}
                            disableVendorPayment={disableVendorPayment}
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
                <hr />
                {/*  <div>
                      <hr />
                      <Form.Group className="col-md-6 mb-3 pb-2">
                        <Form.Label className="mb-1">
                          Vendor Discount For Invoice
                        </Form.Label>
                        <CurrencyCustomInput
                          name="invoiceDiscount"
                          placeholder="0.00"
                          value={invoiceDiscount}
                          onValueChange={(value, name) => {
                            setInvoiceDiscount(value);
                          }}
                        />
                      </Form.Group>
                    </div> */}
                <div className="d-flex justify-content-between save-btn-holder mt-4">
                  <div className="d-flex  flex-column gap-3">
                    {/* {formik.values.discountType === "Per Item" ? (
                          <>
                            {" "}
                            <div className="gridChild gridChildBorderLeftBlue">
                              <div className="gridChildLeft">
                                <p className="gridChld1">
                                  {currency(totalInvoiceAmount, {
                                    symbol: "",
                                  }).format()}
                                </p>
                                <p className="gridChld2">Total</p>
                              </div>
                            </div>
                          </>
                        ) : ( */}
                    <>
                      <div className="d-flex gap-3">
                        <div className="gridChild gridChildBorderLeftBlue">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalInvoiceAmount, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Invoice Amount</p>
                          </div>
                        </div>

                        <div className="gridChild gridChildBorderLeftOrange">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(totalFreight, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Freight</p>
                          </div>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftDarkerGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(totalInvoiceAmount, {
                              symbol: "",
                            })
                              .add(taxValue)
                              .add(totalFreight)
                              .format()}
                          </p>
                          <p className="gridChld2">Total</p>
                        </div>
                      </div>
                      {/*   <div className="gridChild gridChildBorderLeftOrange">
                            <div className="gridChildLeft">
                              <p className="gridChld1">
                                {currency(totalDiscount, {
                                  symbol: "",
                                }).format()}
                              </p>
                              <p className="gridChld2">Discount</p>
                            </div>
                          </div>
                          <div className="gridChild gridChildBorderLeftDarkerGreen">
                            <div className="gridChildLeft">
                              <p className="gridChld1">
                                {currency(totalInvoiceAmount, {
                                  symbol: "",
                                })
                                  .subtract(totalDiscount)
                                  .format()}
                              </p>
                              <p className="gridChld2">Total</p>
                            </div>
                          </div> */}
                    </>
                    {/*  )} */}
                  </div>

                  <Button
                    disabled={updateMutation.isLoading}
                    className="action-btn save-btn"
                    onClick={() => saveMulti()}
                  >
                    {pendingId ? "Approve" : "Save"}
                  </Button>
                </div>
              </>
            ) : (
              <div className="no-item my-5 py-4">
                <div className="info">
                  <NoSelectedItemIcon />
                  <h2 className="mb-1">No Item Selected</h2>
                  <p>
                    Please select an item and fill in the <br />
                    pricing details.
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      {isMulti ? (
        <></>
      ) : (
        <Modal.Footer className="border-0 justify-content-between footer">
          <Button
            disabled={updateMutation.isLoading}
            variant="outline-danger"
            className="p-3 py-2"
            onClick={() => handleDeleteItem()}
          >
            Remove Item
          </Button>

          <div className="d-flex gap-3 ">
            <Button
              disabled={updateMutation.isLoading}
              onClick={() => formik.submitForm()}
              className="p-3 py-2"
            >
              {updateMutation.isLoading ? "Please wait..." : "Update Inventory"}
            </Button>
          </div>
        </Modal.Footer>
      )}
      <ModalLoader show={updateMutation.isLoading || isLoading} />
    </Modal>
  );
}
