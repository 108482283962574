import React, { useEffect, useState } from "react";

function Industry({ saveIndustry, next }) {
  const [selected, setSelected] = useState(null);
  const industry = [
    { value: "manufacturing", label: "Manufacturing" },
    { value: "agriculture", label: "Agriculture" },
    { value: "oil-gas", label: "Oil and Gas" },
    { value: "finance", label: "Finance" },
    { value: "retail", label: "Retail" },
    { value: "services", label: "Services" },
    { value: "wholesales", label: "Wholesale Distribution" },
    { value: "transportation", label: "Transportation" },
    { value: "logistics", label: "Logistics" },
    { value: "healthcare", label: "Healthcare" },
    { value: "technology", label: "Technology" },
    { value: "construction", label: "Construction" },
    { value: "education", label: "Education" },
    { value: "government", label: "Government Agency" },
    { value: "engineering", label: "Engineering" },
    { value: "media", label: "Media" },
    { value: "gas-station", label: "Gas Station" },
    { value: "other", label: "Other" },
  ];

  const submit = () => {
    // submit industry to the server
    console.log(selected);
    // reset the industry state
    // setIndustry([]);
    next();
  };

  useEffect(() => {
    saveIndustry(submit);
  }, [selected]);

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };
  return (
    <div className="contents">
      <div className="wrapper">
        <h1>What's your industry?</h1>
        <p>Select the option that best fit. You can always change it later.</p>

        <label htmlFor="industry">Industry</label>
        <select list="industry-options" id="industry" onChange={handleSelect}>
          <option value="">Select industry</option>
          {industry.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default Industry;
