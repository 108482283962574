import Select from "react-select"
import { useAuth } from "../../hooks/useAuth";
import { useQuery } from "react-query";
import { Button, Form } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useMemo } from "react";
import { useIsAdmin, useIsCashier } from "../../utils/hooks";

const SelectAccountMap = ({ formik }) => {
  const { backendUrl,  user: authUser} = useAuth()
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();

  const getInvoiceAccountsMapping = async () => {
    let response = await fetch(
      `${backendUrl}/api/accounts/invoice-mapping`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    // cleanup
    data.InvoiceAccountsMapping = data.InvoiceAccountsMapping.map((item) => ({
      ...item,
      label: item.title,
      value: item.id,
      accountsToPost: item.accountsToPost ? JSON.parse(item.accountsToPost) : []
    }))

    return data;
  };

  const {
    data = { InvoiceAccountsMapping: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["GET_BUISINESS_InvoiceAccountsMapping_OPTIONS"],
    () => getInvoiceAccountsMapping(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );


  const filteredStaff = useMemo(() => {
if(isAdmin || isCashier) return data?.InvoiceAccountsMapping;

  return data?.InvoiceAccountsMapping.filter(account => {
    const permissions = JSON.parse(account.Staffs);
 let  foundSome = permissions.some(permission =>
      permission.staff === authUser.Staff_ID ||
      (permission.department === "" && permission.staff === "") ||
      (permission.department === authUser.Department && permission.jobGrade === "") ||
      (permission.department === authUser.Department && permission.jobGrade === authUser.jobGrade && permission.staff === "")
    );

  //  console.log(authUser, foundSome)

    return foundSome;
  });

  },[authUser, data?.InvoiceAccountsMapping, isAdmin, isCashier] );



  return (<Form.Group className="my-3">
    <Form.Label className="fw-6">Revenue mapping <Button onClick={() => refetch()} size="sm" variant=""> <CachedIcon /> </Button> </Form.Label>
    <Select classNamePrefix="form-select" value={formik.values?.selectedAccountToPost} options={filteredStaff || []} onChange={(selected) => {
      formik.setFieldValue("selectedAccountToPost", selected);
    }} />
  </Form.Group>)
}

export default SelectAccountMap
