import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  ExcelIcon,
  PDFIcon,
  NoSelectedItemIcon,
  DeleteIcon,
  LinkVariantIcon,
  EditIcon,
  DropdownEyeIcon,
  MoneyDollarIcon,
  CheckCircleIcon,
  CircleTickedIcon,
  DropdownCloseIcon,
  DropdownCheckIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useIsCashier,
  useIsSales,
  useIsWaiter,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { appSettings, backendApis, services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery, useMutation, useQueryClient } from "react-query";
import currency from "currency.js";
import { endOfDay, format, parse, startOfDay } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  electronPrint,
  formatDate,
  paginationOptions,
  reactSelectTheme,
  transactionFromOptions,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import TableComponent from "../TableComponent";
import TransactionsTable from "../utils/TransactionsTable";
import { useStoreState } from "easy-peasy";
import { IsPrivileged } from "../DisplayChildElement";
import PageHeader from "../PageHeader";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";

const approvedOptions = [{ label: 'All', value: "All" }, { label: 'Approved', value: 1 }, { label: 'Not Approved', value: 0 }]

export default function ApproveJournalEntry({ isWarehouse }) {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    /* customerName: "",
    customerId: "", */
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    /* enableDateRange: true,
    showDeleted: true,
    Warehouse: "All",
    creditLine: "", */
    isApproved: 0
  };

  const isSales = useIsSales();
  const isWaiter = useIsWaiter();

  const { backendUrl } = useAuth();
  const isCashier = useIsCashier();
  const isAdmin = useIsAdmin();
  const [activeRow, setActiveRow] = useState(null);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [showTransactionPopover, setShowTransactionPopover] = useState(false);
  const CSVLinkRef = useRef(null);
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const queryClient = useQueryClient();

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    excludeBranches: generalSettings?.excludeBranchesInSalesByInvoice,
    branchesNames: backendApis.map((el) => el.name).join(","),
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchtransactions = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/transaction/transaction-audit?&${queryString.stringify({
        ...queryParams,
        isWaiter,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    /*  data.invoiceCat = data.invoiceCat.map((el) => ({
       value: el,
       label: el,
     })); */
    return data;
  };

  const {
    data = {
      count: 0,
      transactions: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["TRANSACTION_AUDIT_FOR_APPROVAL", queryParams],
    () => fetchtransactions(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const approveSelectedApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/transaction/approve-journal`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...payload,
      }),
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const approveSelectedApiMutation = useMutation((payload) => approveSelectedApi(payload), {
    onSuccess: () => {
      toast.success(`Success: Done`);
      refetch()
    },
    onError: async ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const [selectedTransactions, setSelectedTransactions] = useState({});

  // Sync `data.transactions` with `selectedTransactions`

  useEffect(() => {
    setSelectedTransactions((prev) => {
      const updatedTransactions = data.transactions.reduce((acc, el) => {
        // Retain existing state if it exists, otherwise set the default state
        // console.log(el.TransID, prev[el.TransID])
        acc[el.TransID] = prev[el.TransID] || { checked: Boolean(el.isApproved), modified: false };
        return acc;
      }, {});

      return { ...prev, ...updatedTransactions };
    });
  }, [data.transactions]);

  const approve = ({ TransID, checked }) => {
    setSelectedTransactions((prev) => ({
      ...prev,
      [TransID]: { ...prev[TransID], checked, modified: true },
    }));
  };

  const approveSelected = async () => {
    if (await ConfirmDialog({
      title: 'Approve Selected', description: 'Are you sure ?'
    })) {
      const modifiedTransactions = Object.entries(selectedTransactions)
        .filter(([_, value]) => value.modified)
        .map(([TransID, value]) => ({
          TransID,
          checked: value.checked,
        }));

      console.log("Modified Transactions:", modifiedTransactions);
      approveSelectedApiMutation.mutate({ modifiedTransactions })
    }
  };


  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/transaction/transaction-audit?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.transactions.map((row) => ({
      ...row,
      Post_Time: format(new Date(row.Post_Time), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map((d) => [
      d.customer.LastName,
      d.Post_Time,
      d.DocType,
      d.Trans_ID,
      d.PaymentType,
      d.Debit,
      d.Credit,
      d.Username,
      d.InvoiceCat,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Customer Invoices and Payments Report"],
      [date],
      [""],
      [
        "Business Name",
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Debit Amount",
        "Credit Amount",
        "Username",
        "InvoiceCat",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Invoice Amount",
        currency(data?.totalInvoiceAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Payment Amount",
        currency(data?.totalPaymentAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Balance (LCY)",
        currency(data?.balance, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Invoice Amount",
        currency(data?.reversedInvoiceAmount, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Payment Amount",
        currency(data?.reversedPaymentAmount, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  const manageTransaction = (event, el) => {
    if (event.detail === 2 && el.TransID) {
      navigate(`/journal-entry/edit/${el.TransID}`);
    }
  };

  const handleSelectedTransaction = (transaction) => {
    setFilterParams({
      ...filterParams,
      TransactionID: transaction.TransactionID,
      ...(transaction?.Date_Log ? {
        startDate: format(startOfDay(new Date(transaction?.Date_Log)), "yyyy-MM-dd"),
        endDate: format(endOfDay(new Date(transaction?.Date_Log)), "yyyy-MM-dd")
      } : {})
    });
    setShowTransactionPopover(false);
  };
  // isprivileged

  const warehouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  useEffect(() => {
    if (isWarehouse) {
      setFilterParams({
        ...filterParams,
        Warehouse: warehouseLocal?.whID,
      });
    }
  }, [isWarehouse]);

  const approveOne = ({ TransID }) => {
    approveSelectedApiMutation.mutate({ modifiedTransactions: [{ TransID, checked: true }] })
  }

  const rejectOne = ({ TransID }) => {
    approveSelectedApiMutation.mutate({ modifiedTransactions: [{ TransID, checked: false }] })
  }

  return (
    /*  <IsPrivileged roleName={["Sale by Invoice", "Invoice List"]}> */
    <main className="inner-page-outlet">
      <PageHeader
        name="Approve Journal Entry"
        description=""
        icon={<MoneyDollarIcon />}
      />

      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">

                    <Form.Group className="mb-2-5">
                      <Form.Label>Approval</Form.Label>{" "}
                      <Select options={approvedOptions}
                        value={approvedOptions.filter(el => el.value === filterParams?.isApproved)}
                        onChange={(selected) => setFilterParams({ ...filterParams, isApproved: selected?.value })} />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Transaction ID</Form.Label>{" "}
                      <InputGroup>
                        <Form.Control
                          name="TransactionID"
                          value={filterParams?.TransactionID}
                          placeholder="Enter Transaction ID"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />{" "}
                        {/*  <Popover
                          isOpen={showTransactionPopover}
                          onClickOutside={() =>
                            setShowTransactionPopover(false)
                          }
                          content={() => (
                            <TransactionsTable
                              handleSelectedTransaction={
                                handleSelectedTransaction
                              }
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowTransactionPopover(!showTransactionPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover> */}{" "}
                      </InputGroup>
                    </Form.Group>
                    <hr className="mt-3 mb-4" />

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  {" "}
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions">
                  {/* <button className="btn print">
              Filters <FilterIcon />
            </button> */}
                  <CSVLink
                    className="btn print d-none"
                    filename={`Customer Invoices and Payments(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />
                  {/*
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/reports/pdf/sale-by-invoice?${queryString.stringify(
                            rest
                          )}`}
                          target="blank"
                        >
                          PDF <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                  <Button onClick={() => approveSelected()}>
                    Approve Selected
                  </Button>

                  <button
                    onClick={() => setBtnSummaryActive(true)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </header>


              <div>
                {data.transactions?.map((el, index) => (
                  <div key={el.TransID} className="px-4 mb-5">
                    <div className="d-flex gap-3 align-items-center mb-3">
                      <>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant=""
                            bsPrefix="print more"
                          >
                            <DotsVerticalIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            {isAdmin || isCashier ? (<>
                              {!el.isApproved ? <Dropdown.Item onClick={() => approveOne({
                                TransID: el.TransID
                              })} >
                                <CheckCircleIcon />
                                Approve
                              </Dropdown.Item> :
                                <Dropdown.Item onClick={() => rejectOne({
                                  TransID: el.TransID
                                })}>
                                  <DropdownCloseIcon />
                                  Reject
                                </Dropdown.Item>}
                              <Dropdown.Divider></Dropdown.Divider>
                              <Dropdown.Item as="div">
                                <Link
                                  to={`/journal-entry/edit/${el.TransID}`}
                                >
                                  <EditIcon />
                                  Edit
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item as="div">
                                <Link
                                  to={`/journal-entry/delete/${el.TransID}`}
                                >
                                  <DeleteIcon />
                                  Delete
                                </Link>
                              </Dropdown.Item>
                            </>

                            ) : null}


                            {/* <Dropdown.Item
                              as="button"
                              onClick={async () => {
                                if (
                                  await ConfirmDialog({
                                    title: "Supply All",
                                    description:
                                      "Are you sure you want to supply all?",
                                  })
                                ) {
                                  supplyAll({
                                    TransactionID: el.TransactionID,
                                  });
                                }
                              }}
                            >
                              <ChartPieSliceIcon className="text-primary" />
                              Supply All
                            </Dropdown.Item> */}
                          </Dropdown.Menu>{" "}
                        </Dropdown>
                      </>
                      <div>
                        {/*  {JSON.stringify(selectedTransactions[`${el.TransID}`])} */}
                        <Form.Check label="Select" id={`${el.TransID}`} checked={selectedTransactions[`${el.TransID}`]?.checked}
                          onChange={(e) => {
                            approve({
                              TransID: el.TransID, checked: e.target.checked
                            })
                          }} />
                      </div>
                      <h2 className="h5 m-0">
                        Trans No. {el?.TransID} - {el?.Username}
                      </h2>

                      {el?.PostTime ? (
                        <>
                          {el.PostTime
                            ? formatDate(new Date(el.PostTime), "dd MMM yyyy hh:mm:a")
                            : "..."}
                        </>
                      ) : null}

                    </div>
                    <Table
                      responsive
                      borderless
                      striped
                      hover
                      className="product-table text-nowrap mb-3"
                    >
                      <thead>
                        <tr>
                          <th>Trans Date</th>
                          <th>Account</th>
                          <th>Description</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        {el?.correspondingAccounts?.map((el, index) => (
                          <tr key={el.TransID + index}>
                            {/*   <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVerticalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <>
                                    <Dropdown.Item as="div">
                                      <Link
                                        to={`/journal-entry/edit/${el.TransID}`}
                                      >
                                        <EditIcon />
                                        Edit
                                      </Link>
                                    </Dropdown.Item>

                                  </>{" "}
                                  {el.Ref.includes("INVOICE") ? (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() =>
                                        navigate(
                                          `/inventory-for-sales/manage-transaction`,
                                          {
                                            state: {
                                              TransactionID: el.TransID,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      <DropdownEyeIcon className="text-light" />
                                      Open Transaction
                                    </Dropdown.Item>
                                  ) : null}
                                  {el.Ref.includes("EXPENSE") ? (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() =>
                                        navigate(`/reports/expenses`, {
                                          state: {
                                            TransactionID: el.TransID,
                                          },
                                        })
                                      }
                                    >
                                      <DropdownEyeIcon className="text-light" />
                                      Open Expense
                                    </Dropdown.Item>
                                  ) : null}
                                  {isAdmin || isCashier ? (
                                    <Dropdown.Item as="div">
                                      <Link
                                        to={`/journal-entry/delete/${el.TransID}`}
                                      >
                                        <DeleteIcon />
                                        Delete
                                      </Link>
                                    </Dropdown.Item>
                                  ) : null}
                                </Dropdown.Menu>{" "}
                              </Dropdown>
                            </td> */}
                            <td className="text-nowrap">
                              {el.DateLog
                                ? format(new Date(el.DateLog), "dd MMM yyyy")
                                : "..."}
                            </td>
                            <td>{el.AccountID}</td>
                            <td> {el.Description} </td>
                            <td>
                              {![null, 0].includes(el.ignoredDebit)
                                ? `(${currency(el.ignoredDebit, {
                                  symbol: "",
                                }).format()})`
                                : currency(el.Debit, {
                                  symbol: "",
                                }).format()}
                            </td>
                            <td>
                              {![null, 0].includes(el.ignoredCredit)
                                ? `(${currency(el.ignoredCredit, {
                                  symbol: "",
                                }).format()})`
                                : currency(el.Credit, {
                                  symbol: "",
                                }).format()}
                            </td>
                            <td>{el.Ref}</td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className="fw-5 fs-6">
                        <tr >
                          {/* <td></td> */}
                          <td><b>Total</b></td>
                          <td>{"  "}</td>
                          <td>{"  "}</td>
                          <td className={`${el.isAccountBalanced ? '' : 'text-danger'} p-cursor`}><b>{currency(el.totalDebit, {
                            symbol: ""
                          }).format()}</b></td>
                          <td className={`${el.isAccountBalanced ? '' : 'text-danger'} p-cursor`}><b>{currency(el.totalCredit, {
                            symbol: ""
                          }).format()}</b></td>
                          <td>{"  "}</td>
                        </tr>
                      </tfoot>
                    </Table>

                    <hr />
                  </div>
                ))}

                {!isFetching && isSuccess && isEmpty(data?.transactions) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>


              {/*  <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={data.transactions.filter((el) =>
                    filterParams.showDeleted
                      ? true
                      : !filterParams.showDeleted
                        ? Boolean(el?.TrackID !== "Deleted")
                        : true
                  )}
                  tableDataRowFunction={tableData}
                  className="product-table text-nowrap"
                  tableRowClick={(e, el) => manageTransaction(e, el)}
                />

                {!isFetching && isSuccess && isEmpty(data?.transactions) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div> */}

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>{" "}
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
              {/* <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="summaryHeader">
                  <h2>Summary</h2>
                  {btnSummryActive && (
                    <button onClick={() => close()} className="btn p-0">
                      <SummaryCloseIcon />
                    </button>
                  )}
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalInvoiceAmt, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Invoice Amt</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalPaymentAmt, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Payment Amt</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBrown">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.balance, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Balance (LCY)</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftDarkerGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.reversedInvoiceAmount, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Reversed Invoice Amt</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftDarkGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.reversedPaymentAmount, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2"> Reversed Payment Amt</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <ModalLoader show={isfetchingExcel || approveSelectedApiMutation.isLoading} />
      </main>
    </main >
    /*   </IsPrivileged> */
  );
}
