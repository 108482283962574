import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnce } from "../utils/hooks";
import ModalLoader from "./utils/ModalLoader";
import { useMutation } from "react-query";
import { fetchActionsUtil } from "../utils/helpers";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { appSettings, backendApis } from "../config";

export default function Verify() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const verifyMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${payload.backendUrl}/api/auth/verify`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success("Account Verified, You can Login");
        if (searchParams.get("st") && searchParams.get("st") == "true") {
          navigate(`${appSettings.storeBaseUrl}?action=login`, {
            replace: true,
          });
        } else {
          navigate("/", {
            replace: true,
          });
        }
      },
      onError: (error) => {
        toast.error(`Unable to perform action: reload`);
      },
    }
  );

  useEffectOnce(() => {
    const verifyToken = searchParams.get("verifyToken");
    const company = searchParams.get("company");
    const backendUrl = company ? backendApis.find((el) => el.name === company)?.url : backendApis[0].url;

    verifyMutation.mutate({
      verifyToken,
      backendUrl,
    });
  });

  return <ModalLoader show={verifyMutation.isLoading} />;
}
