import React, { useState } from 'react';
import { Form, Accordion } from 'react-bootstrap';
import Select from "react-select";
import CustomInvoiceNumberInput from '../utils/CustomInvoiceNumberInput';
import moment from 'moment';
import Datetime from "react-datetime";


const intervalSearchCriteriaOptions = [
  {
    label: "Daily",
    value: "Daily",
  },
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Yearly",
    value: "Yearly",
  },
];

const reoccuringEndOptions = [
  { label: "None", value: "None" },
  { label: "By", value: "By" },
  { label: "After", value: "After" },
];

const reoccurringWeekOptions = [
  { label: "Sunday", value: "Sunday" },
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
];
const reoccurringMonthlyOnOptions = [
  { label: "Day", value: "Day" },
  { label: "First", value: "First" },
  { label: "Second", value: "Second" },
  { label: "Third", value: "Third" },
  { label: "Fourth", value: "Fourth" },
  { label: "Last", value: "Last" },
];
const reoccurringMonthlyOnDayOptions = [
  { label: "1st", value: "1st" },
  { label: "2nd", value: "2nd" },
  { label: "3rd", value: "3rd" },
  { label: "4th", value: "4th" },
  { label: "5th", value: "5th" },
  { label: "6th", value: "6th" },
  { label: "7th", value: "7th" },
  { label: "8th", value: "8th" },
  { label: "9th", value: "9th" },
  { label: "10th", value: "10th" },
  { label: "11th", value: "11th" },
  { label: "12th", value: "12th" },
  { label: "13th", value: "13th" },
  { label: "14th", value: "14th" },
  { label: "15th", value: "15th" },
  { label: "16th", value: "16th" },
  { label: "17th", value: "17th" },
  { label: "18th", value: "18th" },
  { label: "19th", value: "19th" },
  { label: "20th", value: "20th" },
  { label: "21st", value: "21st" },
  { label: "22nd", value: "22nd" },
  { label: "23rd", value: "23rd" },
  { label: "24th", valuel: "24th" },
  { label: "25th", value: "25th" },
  { label: "26th", value: "26th" },
  { label: "27th", value: "27th" },
  { label: "28th", value: "28th" },
  { label: "Last", value: "Last" },
];

const reoccurringYearlyOptions = [
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

const ScheduledPostingForm = ({
  invoiceType = "reoccurringinvoice", formik
}) => {

  try {

    return (
      <div>
        <div>
          <Form.Check
            type="checkbox"
            className="mb-3"
            label="Schedule Posting"
            checked={formik.values.schedulePosting}
            onChange={() => formik.setFieldValue("schedulePosting", !formik.values.schedulePosting)}
          />

          <Accordion defaultActiveKey="0" activeKey={formik.values.schedulePosting ? "0" : null}>
            <Accordion.Collapse eventKey="0">
              <div className="row">
                {invoiceType === "reoccurringinvoice" && (
                  <Form.Group className="form-mb col-md-6">
                    <Form.Label>Interval</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Enter Interval"
                      className=" rounded-4"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "5px",
                        }),
                      }}
                      isSearchable={false}
                      options={intervalSearchCriteriaOptions}
                      value={intervalSearchCriteriaOptions.find(
                        (el) => el.value === formik.values.interval
                      )}
                      onChange={({ value }) => {
                        if (value === "Daily") {
                          formik.setFieldValue("every", 1);
                          formik.setFieldValue("reoccurringWeekOption", "");
                          formik.setFieldValue("reoccurringMonthlyOnOption", "");
                          formik.setFieldValue("reoccurringMonthlyOnDayOption", "");
                          formik.setFieldValue("reoccurringMonthlyOnNotDayOption", "");
                          formik.setFieldValue("everyMonth", "");
                          formik.setFieldValue("reoccurringYearlyOption", "");
                          formik.setFieldValue("reoccurringYearlyOnDayOption", "");
                          formik.setFieldValue("reoccurringEndDate", "");
                          formik.setFieldValue("reoccurringOccurrence", "");
                        }
                        if (value === "Weekly") {
                          formik.setFieldValue("every", 1);
                          formik.setFieldValue("reoccurringWeekOption", "Monday");
                          formik.setFieldValue("reoccurringMonthlyOnOption", "");
                          formik.setFieldValue("reoccurringMonthlyOnDayOption", "");
                          formik.setFieldValue("reoccurringMonthlyOnNotDayOption", "");
                          formik.setFieldValue("everyMonth", "");
                          formik.setFieldValue("reoccurringYearlyOption", "");
                          formik.setFieldValue("reoccurringYearlyOnDayOption", "");
                          formik.setFieldValue("reoccurringEndDate", "");
                          formik.setFieldValue("reoccurringOccurrence", "");
                        }
                        if (value === "Monthly") {
                          formik.setFieldValue("every", "");
                          formik.setFieldValue("reoccurringWeekOption", "Monday");
                          formik.setFieldValue("reoccurringMonthlyOnOption", "Day");
                          formik.setFieldValue("reoccurringMonthlyOnDayOption", "1st");
                          formik.setFieldValue("reoccurringMonthlyOnNotDayOption", "");
                          formik.setFieldValue("everyMonth", 1);
                          formik.setFieldValue("reoccurringYearlyOption", "");
                          formik.setFieldValue("reoccurringYearlyOnDayOption", "");
                          formik.setFieldValue("reoccurringEndDate", "");
                          formik.setFieldValue("reoccurringOccurrence", "");
                        }
                        if (value === "Yearly") {
                          formik.setFieldValue("every", "");
                          formik.setFieldValue("reoccurringWeekOption", "");
                          formik.setFieldValue("reoccurringMonthlyOnOption", "");
                          formik.setFieldValue("reoccurringMonthlyOnDayOption", "");
                          formik.setFieldValue("reoccurringMonthlyOnNotDayOption", "");
                          formik.setFieldValue("everyMonth", "");
                          formik.setFieldValue("reoccurringYearlyOption", "January");
                          formik.setFieldValue("reoccurringYearlyOnDayOption", "1st");
                          formik.setFieldValue("reoccurringEndDate", "");
                          formik.setFieldValue("reoccurringOccurrence", "");
                        }
                        formik.setFieldValue("reoccurringStartDate", moment(new Date()));
                        formik.setFieldValue("interval", value);
                      }}
                    />
                    {formik.touched.interval && formik.errors.interval ? (
                      <div className=" text-danger">
                        {formik.errors.interval}
                      </div>
                    ) : null}
                  </Form.Group>
                )}

                {invoiceType === "reoccurringinvoice" &&
                  ["daily", "weekly"].includes(
                    formik.values.interval?.toLowerCase()
                  ) && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label>
                        Every{" "}
                        {formik.values.interval?.toLowerCase() === "weekly"
                          ? "week"
                          : "day"}
                        (s)
                      </Form.Label>
                      <CustomInvoiceNumberInput
                        placeholder=""
                        value={formik.values.every}
                        onValueChange={(value, name) => {
                          formik.setFieldValue("every", value);
                        }}
                      />
                      {formik.touched.every && formik.errors.every ? (
                        <div className=" text-danger">
                          {formik.errors.every}
                        </div>
                      ) : null}
                    </Form.Group>
                  )}

                {invoiceType === "reoccurringinvoice" &&
                  formik.values.interval?.toLowerCase() === "monthly" && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label>On</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder=""
                        className=" rounded-4"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "5px",
                          }),
                        }}
                        isSearchable={false}
                        options={reoccurringMonthlyOnOptions}
                        value={reoccurringMonthlyOnOptions.find(
                          (el) =>
                            el.value === formik.values.reoccurringMonthlyOnOption
                        )}
                        onChange={({ value }) => {
                          if (!value.includes("Day")) {
                            formik.setFieldValue("every", "");
                            formik.setFieldValue("reoccurringWeekOption", "");
                            formik.setFieldValue("reoccurringMonthlyOnDayOption", "");
                            formik.setFieldValue("reoccurringMonthlyOnNotDayOption", "Monday");
                            formik.setFieldValue("everyMonth", 1);
                            formik.setFieldValue("reoccurringYearlyOption", "");
                            formik.setFieldValue("reoccurringYearlyOnDayOption", "");
                          }
                          formik.setFieldValue("reoccurringMonthlyOnOption", value);
                        }}
                      />
                    </Form.Group>
                  )}
                {invoiceType === "reoccurringinvoice" &&
                  formik.values.interval?.toLowerCase() === "monthly" &&
                  formik.values.reoccurringMonthlyOnOption?.toLowerCase() === "day" && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label className=" opacity-0">at</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder=""
                        className=" rounded-4"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "5px",
                          }),
                        }}
                        isSearchable={false}
                        options={reoccurringMonthlyOnDayOptions}
                        value={reoccurringMonthlyOnDayOptions.find(
                          (el) =>
                            el.value === formik.values.reoccurringMonthlyOnDayOption
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("reoccurringMonthlyOnDayOption", value);
                        }}
                      />
                    </Form.Group>
                  )}
                {invoiceType === "reoccurringinvoice" &&
                  formik.values.interval?.toLowerCase() === "monthly" &&
                  !formik.values.reoccurringMonthlyOnOption?.toLowerCase().includes("day") && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label className=" opacity-0">at</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder=""
                        className=" rounded-4"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "5px",
                          }),
                        }}
                        isSearchable={false}
                        options={reoccurringWeekOptions}
                        value={reoccurringWeekOptions.find(
                          (el) =>
                            el.value === formik.values.reoccurringMonthlyOnNotDayOption
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("reoccurringMonthlyOnNotDayOption", value);
                        }}
                      />
                    </Form.Group>
                  )}
                {invoiceType === "reoccurringinvoice" &&
                  formik.values.interval?.toLowerCase() === "monthly" && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label>Every Month(s)</Form.Label>
                      <CustomInvoiceNumberInput
                        placeholder=""
                        value={formik.values.everyMonth}
                        onValueChange={(value, name) => {
                          formik.setFieldValue("everyMonth", value);
                        }}
                      />
                    </Form.Group>
                  )}
                {invoiceType === "reoccurringinvoice" &&
                  formik.values.interval?.toLowerCase() === "weekly" && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label>Weeks (on)</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder=""
                        className=" rounded-4"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "5px",
                          }),
                        }}
                        isSearchable={false}
                        options={reoccurringWeekOptions}
                        value={reoccurringWeekOptions.find(
                          (el) =>
                            el.value === formik.values.reoccurringWeekOption
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("reoccurringWeekOption", value);
                        }}
                      />
                      {formik.touched.reoccurringWeekOption &&
                        formik.errors.reoccurringWeekOption ? (
                        <div className=" text-danger">
                          {formik.errors.reoccurringWeekOption}
                        </div>
                      ) : null}
                    </Form.Group>
                  )}
                {invoiceType === "reoccurringinvoice" &&
                  formik.values.interval?.toLowerCase() === "yearly" && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label>Every</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder=""
                        className=" rounded-4"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "5px",
                          }),
                        }}
                        isSearchable={false}
                        options={reoccurringYearlyOptions}
                        value={reoccurringYearlyOptions.find(
                          (el) =>
                            el.value === formik.values.reoccurringYearlyOption
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("reoccurringYearlyOption", value);
                        }}
                      />
                    </Form.Group>
                  )}
                {invoiceType === "reoccurringinvoice" &&
                  formik.values.interval?.toLowerCase() === "yearly" && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label className=" opacity-0">at</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder=""
                        className=" rounded-4"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderRadius: "5px",
                          }),
                        }}
                        isSearchable={false}
                        options={reoccurringMonthlyOnDayOptions}
                        value={reoccurringMonthlyOnDayOptions.find(
                          (el) =>
                            el.value === formik.values.reoccurringYearlyOnDayOption
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("reoccurringYearlyOnDayOption", value);
                        }}
                      />
                    </Form.Group>
                  )}
                {invoiceType === "reoccurringinvoice" && (
                  <Form.Group className="form-mb col-md-6">
                    <Form.Label>Start Date</Form.Label>
                    <div style={{ position: "relative", display: "inline-block", left: 0 }}>
                      <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        dateFormat="MMM DD, YYYY"
                        inputProps={{
                          className: `date-input form-control`,
                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        key={formik.values.reoccurringStartDate}
                        value={formik.values.reoccurringStartDate}
                        onChange={(date) => {
                          formik.setFieldValue("reoccurringStartDate", date, true);
                        }}
                      /> </div>
                    {formik.touched.reoccurringStartDate &&
                      formik.errors.reoccurringStartDate ? (
                      <div className=" text-danger">
                        {formik.errors.reoccurringStartDate}
                      </div>
                    ) : null}

                  </Form.Group>
                )}
                {invoiceType === "reoccurringinvoice" && (
                  <Form.Group className="form-mb col-md-6">
                    <Form.Label>End</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="None"
                      className=" rounded-4"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderRadius: "5px",
                        }),
                      }}
                      isSearchable={false}
                      options={reoccuringEndOptions}
                      value={reoccuringEndOptions.find(
                        (el) => el.value === formik.values.reoccuringEndOption
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("reoccuringEndOption", value);
                      }}
                    />
                    {formik.touched.reoccuringEndOption &&
                      formik.errors.reoccuringEndOption ? (
                      <div className=" text-danger">
                        {formik.errors.reoccuringEndOption}
                      </div>
                    ) : null}
                  </Form.Group>
                )}
                {invoiceType === "reoccurringinvoice" &&
                  formik.values.reoccuringEndOption?.toLowerCase() === "by" && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label>End Date</Form.Label>
                      <Datetime
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        dateFormat="MMM DD, YYYY"
                        inputProps={{
                          className: `date-input form-control`,
                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.reoccurringEndDate}
                        onChange={(date) => {
                          formik.setFieldValue("reoccurringEndDate", date, true);
                        }}
                      />
                      {formik.touched.reoccurringEndDate &&
                        formik.errors.reoccurringEndDate ? (
                        <div className=" text-danger">
                          {formik.errors.reoccurringEndDate}
                        </div>
                      ) : null}
                    </Form.Group>
                  )}
                {invoiceType === "reoccurringinvoice" &&
                  formik.values.reoccuringEndOption?.toLowerCase() === "after" && (
                    <Form.Group className="form-mb col-md-6">
                      <Form.Label>Occurrence</Form.Label>
                      <CustomInvoiceNumberInput
                        placeholder=""
                        value={formik.values.reoccurringOccurrence}
                        onValueChange={(value, name) => {
                          formik.setFieldValue("reoccurringOccurrence", value);
                        }}
                      />
                      {formik.touched.reoccurringOccurrence &&
                        formik.errors.reoccurringOccurrence ? (
                        <div className=" text-danger">
                          {formik.errors.reoccurringOccurrence}
                        </div>
                      ) : null}
                    </Form.Group>
                  )}
              </div>
            </Accordion.Collapse>
          </Accordion>
        </div>
      </div>
    );
  } catch (err) {
    console.log("Unable to set up schedule form", err)
  }
};

export default ScheduledPostingForm;
