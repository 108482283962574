import { Dropdown, Table, Form, Button, Badge } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  ExcelIcon,
  PDFIcon,
  DeleteIcon,
  EditIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useCurrencies,
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useReconcilation,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  fetchActionsUtil,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import ReconcilationCheckbox from "../utils/ReconcilationCheckbox";
import { Link, useNavigate } from "react-router-dom";
import { IsPrivileged } from "../DisplayChildElement";
import PaymentAccountMultiDialog from "../PaymentAccountMultiDialog";
import { read, utils } from "xlsx";
import { cloneDeep } from "lodash";

function Expenses({ hasReconcilation = false }) {
  useScrollTop();
  const initialFilterParams = {
    ExpID: "",
    header: "",
    description: "",
    branch: "",
    startDate: "", // format(new Date(), "yyyy-MM-dd"),
    endDate: "", // format(new Date(), "yyyy-MM-dd"),
  };

  const navigate = useNavigate();

  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const isAdmin = useIsAdmin();
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const {
    addToReconciledList,
    isReconciled,
    PostReconciledBtn,
    setUpReconcilations,
    postReconcilationMutation,
  } = useReconcilation({
    refetch: () => refetch(),
  });

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
    currency: "",
    convertCurrency: prevailingCurrency,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchExpenses = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/expenses?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch.map((el) => ({
        label: el.Department,
        value: el.Department,
      })),
    ];

    setUpReconcilations(data.expenses);
    return data;
  };

  const {
    data = { count: 0, expenses: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.EXPENSES, queryParams],
    () => fetchExpenses(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/expenses?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.expenses.map((row) => ({
      ...row,
      ReqDate: format(new Date(row.ReqDate), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map((d) => [
      d.Header,
      d.ReqName,
      d.Department,
      currency(d.Amount, {
        symbol: "",
      }).format(),
      d.Purpose,
      d.ReqDate,
      d.ExpID,
      d.username,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Expenses Report"],
      [date],
      [""],
      [
        "Exp Header",
        "Requester Name",
        "Department",
        "Amount",
        "Purpose",
        "Exp Date",
        "Exp ID",
        "Username",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Expenses",
        currency(data?.totalExpenses, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const deleteExpense = async (payload) => {
    let response = await fetch(`${backendUrl}/api/transaction/delete-expense`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteExpense(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleDeleteExpense = async (expense) => {
    const formValues = await PaymentAccountMultiDialog({
      Trans_ID: expense.Trans_ID,
      amountRequired: expense.Amount,
      disableAccountSelect: true,
    });
    if (formValues) {
      if (
        await ConfirmDialog({
          title: "Delete Expense",
          description: `Are you sure you want to delete this expense \n ${expense?.Purpose
            } - ${expense.Amount}`,
        })
      ) {
        deleteMutation.mutate({ expense, accounts: formValues.accounts });
      }
    }
  };

  const postExpense = useMutation((payload) => fetchActionsUtil(
    `${backendUrl}/api/invexcloud/post-expense-excel-import`,
    "POST",
    "",
    payload
  ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  function handleFile({ eventData: e, type }) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          }
        );


        rows = cloneDeep(rows).filter(
          (row) =>
            row["TO BE REMOVED"] || row["REMARK"] || row["CHANGE OF DATE"]
        );

        rows = rows.map(({ __EMPTY, ...row }) => {
          if (row["TO BE REMOVED"] && row["REMARK"] && row["CHANGE OF DATE"]) {
            return row;
          }
          if (row["TO BE REMOVED"] === "TO BE REMOVED") {
            row = { ...row, toBeRemoved: true };
          }
          if (row["REMARK"]) {
            row = { ...row, isRemark: true };
          }
          if (row["CHANGE OF DATE"]) {
            const date = row["CHANGE OF DATE"].split("TO")[1];

            const [day, month, year] = date.split("/");

            const finalDate = new Date(`${month}/${day}/${year}`)

            row = {
              ...row,
              isChangeDate: true,
              newDate: finalDate.setHours(finalDate.getHours() + 1),
            };
          }
          return row;
        });

        console.log(rows)

        try {
          postExpense.mutate(rows);
          // await schema.validate({ items: rows });
          // Clean Up
          /* rows = rows.map((el) => ({
                Item_Name: el.Item_Name,
                Bar_Code: el.Item_Code,
                QtyCounted: el?.Quantity ? el?.Quantity : el?.Quantity_Counted,
              })); */
          // createInvoicesMutation.mutate({ invoices: rows });
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  // isprivileged
  return (
    <IsPrivileged roleName="Expenses">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Branch</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="All"
                        name="branch"
                        theme={reactSelectTheme}
                        isSearchable={true}
                        key={data?.branch}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            branch: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.branch,
                          data?.branch,
                          {
                            value: "",
                            label: "All",
                          }
                        )}
                        options={data?.branch || []}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label> Invoice/Ref</Form.Label>
                      <Form.Control
                        name="ExpID"
                        placeholder="Enter  Invoice/Ref"
                        value={filterParams?.ExpID}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Header</Form.Label>
                      <Form.Control
                        name="header"
                        placeholder="Enter Header"
                        value={filterParams?.header}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        name="description"
                        value={filterParams?.description}
                        placeholder="Enter Description"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="auto"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>

                    <hr />

                    <Form.Group className="mb-2-5">
                      <Form.Label>Filter By</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="All Currencies"
                        isSearchable={true}
                        options={currenciesOptions}
                        value={currenciesOptions.find(
                          (el) => el.value == filterParams.currency
                        )}
                        onChange={(selected) =>
                          setFilterParams({
                            ...filterParams,
                            page: 1,
                            currency: selected?.value,
                          })
                        }
                        isClearable={true}
                        menuPosition="fixed"
                      />
                    </Form.Group>

                    <Form.Group className="">
                      <Form.Label>Report In</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select Currency"
                        isSearchable={true}
                        options={currenciesOptions}
                        value={currenciesOptions.find(
                          (el) => el.value === filterParams.convertCurrency
                        )}
                        onChange={({ value }) =>
                          setFilterParams({
                            ...filterParams,
                            page: 1,
                            convertCurrency: value,
                          })
                        }
                        menuPosition="fixed"
                      />
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Department Expenses History
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>

                <button className="btn print">
                  Export <ExportIcon />
                </button>

                <button className="btn sendSms">
                  Update Expenses <ExchangeFunds />
                </button> */}

                  {hasReconcilation && <PostReconciledBtn />}

                  <CSVLink
                    className="btn print d-none"
                    filename={`Expenses(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  <label
                    variant=""
                    className="btn print rounded-4 border"
                    title="Import"
                    bsPrefix=""
                  >
                    <input
                      type="file"
                      className="d-none"
                      onChange={(e) =>
                        handleFile({
                          eventData: e,
                          type: "",
                        })
                      }
                      accept=".xlsx"
                    />
                    Import <ExcelIcon color="#008000" /> {postExpense?.isLoading && 'Please wait...'}
                  </label>

                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >

                      <ExportIcon color="#008000" />Export
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/reports/pdf/expenses?${queryString.stringify(
                            rest
                          )}`}
                          target="blank"
                        >
                          PDF <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Link to="/expenses-entry-v2" className="btn  btn-primary">
                    Create New
                  </Link>
                </div>
              </header>

              <div className="px-md-4">
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th />
                      {hasReconcilation && <th>Reconcile</th>}
                      <th>Exp Header</th>
                      <th>Requester Name</th>
                      <th>Department</th>
                      <th>Amount</th>
                      <th>Purpose</th>
                      <th>Exp Date</th>
                      <th>Exp ID</th>
                      <th>Username</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.expenses.map((el, index) => (
                      <tr key={index}>
                        <td>
                          {el?.Trans_ID && (
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                              >
                                <DotsVerticalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="dropdown-with-icons"
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                              >
                                {isAdmin ? (
                                  <>
                                    {/* <Dropdown.Item
                                      as="button"
                                      onClick={() =>
                                        navigate(
                                          `/journal-entry/edit/${el.Trans_ID}`
                                        )
                                      }
                                    >
                                      <EditIcon /> Edit
                                    </Dropdown.Item> */}
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => handleDeleteExpense(el)}
                                    >
                                      <DeleteIcon /> Delete
                                    </Dropdown.Item>
                                  </>
                                ) : null}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </td>

                        {hasReconcilation && (
                          <td>
                            {el?.Trans_ID && hasReconcilation ? (
                              <ReconcilationCheckbox
                                checked={isReconciled({
                                  ...el,
                                  TransactionID: el.Trans_ID,
                                })}
                                onChange={(e) =>
                                  addToReconciledList({
                                    ...el,
                                    TransactionID: el.Trans_ID,
                                    checked: e.target.checked,
                                  })
                                }
                                Post_Time={el.ReqDate}
                                reconciled={el?.reconciled}
                              />
                            ) : null}
                          </td>
                        )}
                        <td>{el.Header}</td>
                        <td>{el.ReqName || "..."}</td>
                        <td>{el.Department}</td>
                        <td>
                          {currency(el.Amount, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>{el.Purpose}</td>
                        <td>
                          {el.ReqDate
                            ? format(new Date(el.ReqDate), "dd MMM yyyy")
                            : "..."}
                        </td>
                        <td>{el.ExpID}</td>
                        <td>{el.username}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {!isFetching && isSuccess && isEmpty(data?.expenses) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>{" "}
                    <option value="500">500 rows</option>{" "}
                    <option value="1000">1000 rows</option>{" "}
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>

              <div className="summaryNew ">
                <div className="gridCont">
                  {hasReconcilation && (
                    <>
                      <div className="gridChild gridChildBorderLeftDarkerGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.reconciledData?.reconciledData, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Reconciled</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftOrange">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.reconciledData?.nonReconciledData, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Not Reconciled</p>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalExpenses, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Expenses</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLoader show={isfetchingExcel || deleteMutation.isLoading || postExpense.isLoading} />
      </main>
    </IsPrivileged>
  );
}

export default Expenses;
