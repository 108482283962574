import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import Select from "react-select";
import {
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
} from "../Icons";
import { isEmpty } from "lodash";
import { queryActions } from "../../utils/reactQueryActions";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const paymentMethod = [
  {
    icon: <CashSelectIcon />,
    label: "Cash",
    value: "Cash",
  },
  {
    icon: <CreditSelectIcon />,
    label: "Credit/Debit Card (POS)",
    value: "Credit/Debit Card",
  },
  {
    icon: <DirectTransferSelectIcon />,
    label: "Direct Bank Transfer",
    value: "Direct Bank Transfer",
  },
];

export default function WarehouseSettingsModal({
  warehouseSetting,
  show,
  setShow,
}) {
  const { backendUrl, token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(true);
  const location = useLocation();

  const fetchSetUpData = async () => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/warehouseInvoice/set-up?Warehouse=${warehouseSetting?.W_ID
      }`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));
    data.banks = data?.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    // if (isEmpty(location.state)) {
    //   const chosenBankOnInvoice = data.banks.find(
    //     (el) => el.showOnSalesInvoice === "yes"
    //   );
    //   if (chosenBankOnInvoice && isEmpty(formik.values.bankOnInvoice)) {
    //     formik.setFieldValue("bankOnInvoice", chosenBankOnInvoice?.BankName);
    //   }
    // }
    return data;
  };

  const { data: invoiceData = { invoiceCat: [], banks: [] } } = useQuery(
    [queryActions.INVOICE_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const submit = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/add-warehouse-settings`,
        "POST",
        token,
        payload
      ),
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: () => {
        toast.success("Settings Saved");
        setLoading(false);
        setEdit(false);
      },
      onError: () => {
        toast.success("unable to save settings");

        setLoading(false);
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      inventoryWebHook: "",
      locationID: "",
      W_ID: warehouseSetting?.W_ID,
      token: "",
      PaymentType: "",
      bankOnInvoice: "",
      inventoryPriceHook: "",

    },
    onSubmit: (values) => {
      const payload = [
        {
          W_ID: values.W_ID,
          Settings_Name: "Inventory Webhook URL",
          Settings: values.inventoryWebHook,
        },
        {
          W_ID: values.W_ID,
          Settings_Name: "Location ID",
          Settings: values.locationID,
        },
        {
          W_ID: values.W_ID,
          Settings_Name: "token",
          Settings: values.token,
        },
        {
          W_ID: values.W_ID,
          Settings_Name: "Payment Method",
          Settings: values.PaymentType,
        },
        {
          W_ID: values.W_ID,
          Settings_Name: "Bank On Invoice",
          Settings: values.bankOnInvoice,
        },
        {
          W_ID: values.W_ID,
          Settings_Name: "Inventory Price URL",
          Settings: values.inventoryPriceHook,
        },
        // ===
        {
          W_ID: values.W_ID,
          Settings_Name: "authorizationUrl",
          Settings: values.authorizationUrl,
        },
        {
          W_ID: values.W_ID,
          Settings_Name: "authorizationUsername",
          Settings: values.authorizationUsername,
        },
        {
          W_ID: values.W_ID,
          Settings_Name: "authorizationPassword",
          Settings: values.authorizationPassword,
        },
      ];

      submit.mutate(payload);
      //   console.log(payload);
    },
  });

  const { data } = useQuery(
    "GET_WAREHOUSE_SETTINGS",
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/warehouse-settings/${warehouseSetting?.W_ID
        }`,
        "GET"
      ),
    {
      onSuccess: ({ data }) => {
        formik.setFieldValue("inventoryWebHook", data[0]?.Settings || "");
        formik.setFieldValue("locationID", data[1]?.Settings || "");
        formik.setFieldValue("token", data[2]?.Settings || "");
        formik.setFieldValue("PaymentType", data[3]?.Settings || "");
        formik.setFieldValue("bankOnInvoice", data[4]?.Settings || "");
        formik.setFieldValue("inventoryPriceHook", data[5]?.Settings || "");
        formik.setFieldValue("authorizationUrl", data[6]?.Settings || "");
        formik.setFieldValue("authorizationUsername", data[7]?.Settings || "");
        formik.setFieldValue("authorizationPassword", data[8]?.Settings || "");
        setEdit(false);
      },
    }
  );

  return (
    <Modal onHide={() => setShow(false)} show={show} centered>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            {" "}
            {warehouseSetting?.warehouseName} Settings
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className=" mb-3">
            <Form.Group>
              <Form.Label htmlFor="inventoryWebHook ">
                Inventory Webhook URL
              </Form.Label>
              <Form.Control
                name="inventoryWebHook"
                disabled={edit}
                id="inventoryWebHook"
                placeholder="Enter inventory webhook"
                onChange={formik.handleChange}
                value={formik.values.inventoryWebHook}
              />
            </Form.Group>
          </div>
          <div className=" mb-3">
            <Form.Group>
              <Form.Label htmlFor="inventoryPriceHook">
                Inventory Price URL
              </Form.Label>
              <Form.Control
                name="inventoryPriceHook"
                disabled={edit}
                id="inventoryPriceHook"
                placeholder="Enter inventory price webhook"
                onChange={formik.handleChange}
                value={formik.values.inventoryPriceHook}
              />
            </Form.Group>
          </div>

          <hr />
          <div className="mb-3">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="authorizationUrl">
                Authorization URL
              </Form.Label>
              <Form.Control
                name="authorizationUrl"
                disabled={edit}
                id="authorizationUrl"
                placeholder="Enter text here..."
                onChange={formik.handleChange}
                value={formik.values.authorizationUrl}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label htmlFor="authorizationUsername">
                    Username
                  </Form.Label>
                  <Form.Control
                    name="authorizationUsername"
                    disabled={edit}
                    id="authorizationUsername"
                    placeholder="Enter text here..."
                    onChange={formik.handleChange}
                    value={formik.values.authorizationUsername}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label htmlFor="authorizationPassword">
                    Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="authorizationPassword"
                    disabled={edit}
                    id="authorizationPassword"
                    placeholder="Enter text here..."
                    onChange={formik.handleChange}
                    value={formik.values.authorizationPassword}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className=" mb-3">
            <Form.Group>
              <Form.Label htmlFor="token">Webhook Token</Form.Label>
              <Form.Control
                name="token"
                id="token"
                disabled={edit}
                placeholder=" Enter webhook token"
                onChange={formik.handleChange}
                value={formik.values.token}
              />
            </Form.Group>
          </div>

          <hr />

          <div className="mb-3">
            <Form.Group>
              <Form.Label htmlFor="locationID ">Location ID</Form.Label>
              <Form.Control
                name="locationID"
                id="locationID"
                disabled={edit}
                placeholder=" Enter Location ID"
                onChange={formik.handleChange}
                value={formik.values.locationID}
              />
            </Form.Group>
          </div>

          <hr />

          <div className="row mb-3">
            <Form.Group className="">
              <Form.Label>Payment method</Form.Label>
              <Select
                classNamePrefix="form-select"
                menuPlacement="top"
                isDisabled={edit}
                placeholder="Choose method"
                isSearchable={false}
                value={
                  paymentMethod.find(
                    (el) => el.value === formik.values?.PaymentType
                  ) || ""
                }
                options={paymentMethod}
                onChange={({ value }) => {
                  formik.setFieldValue("bankOnInvoice", "");
                  formik.setFieldValue("PaymentType", value);
                }}
                getOptionLabel={(el) => (
                  <div className="label-with-icon d-flex gap-2 align-items-center">
                    <span>{el.icon}</span>{" "}
                    <span className="fw-5">{el.label}</span>
                  </div>
                )}
              //  menuIsOpen={true}
              />
              {formik.touched.PaymentType && !!formik.errors.PaymentType ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.PaymentType}
                </span>
              ) : null}
            </Form.Group>
            {["Direct Bank Transfer", "Credit/Debit Card"].includes(
              formik.values.PaymentType
            ) && (
                <Form.Group className="mb-3 mt-2">
                  <Form.Label>Bank Account on Invoice</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Select"
                    isSearchable={false}
                    isDisabled={edit}
                    options={invoiceData.banks}
                    value={invoiceData.banks.find(
                      (el) => el.value === formik.values.bankOnInvoice
                    )}
                    onChange={(selected) =>
                      formik.setFieldValue("bankOnInvoice", selected?.value)
                    }
                    isClearable
                  />
                </Form.Group>
              )}
          </div>


        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            disabled={edit}
            variant="outline-primary"
            type="button"
            onClick={() => setEdit(true)}
          >
            Edit
          </Button> */}
          <Button type="submit" disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal >
  );
}
