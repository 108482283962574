import React, { useState } from 'react';
import { TreeSelect } from 'antd';
const { SHOW_PARENT } = TreeSelect;

const MentionInput = ({value, setValue, data, isLoading, error}) => {
  const onChange = (newValue) => {
    setValue(newValue);
  };
  const tProps = {
    treeData: data,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    loading: isLoading,
    dropdownStyle: { maxHeight: 400, overflow: 'auto' },
    showSearch: true,
    allowClear: true,
    placeholder: error ? "Project not created in Fexspace yet" :'Add staff to project',
    style: {
      width: '100%',
    },
  };
  return <TreeSelect {...tProps} />;
};
export default MentionInput;