import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, InputGroup, Button } from "react-bootstrap";
import ModalLoader from "../utils/ModalLoader";
import Select from "react-select";
import queryString from "query-string";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import CloseIcon from "mdi-react/CloseIcon";
import { fetchActionsUtil } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import MentionInput from "../keyboard/MentionInput";

const CreateTicket = ({ show, setShow, selectedId }) => {
  const { backendUrl, token } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: "",
    isRemoved: "Not Sold",
  });
  const [assets, setAssets] = useState([]);
  const [savedIssueType, setSaveIssueType] = useState(
    JSON.parse(localStorage.getItem("valueDatas"))?.issueType || []
  );
  const [loading, setLoading] = useState(false);
  const [projectTeams, setProjectTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [mentionError, setMentionError] = useState(false);

  useEffect(() => {
    console.log(selectedId);
    const fetchAssets = async (queryParams) => {
      // await waitFor(5000);
      let data = null;
      data = await fetchActionsUtil(
        `${backendUrl}/api/warehouse/fetch/all-assets?&${queryString.stringify(
          queryParams
        )}`,
        "GET"
      );

      return data?.data;
    };
    fetchAssets(queryParams).then(({ warehouses }) => {
      console.log(warehouses);
      setAssets(warehouses);
    });

    if (selectedId) {
      formik.setFieldValue("issueProject", selectedId.Bar_Code);
      handleProjectSelect(selectedId.Bar_Code);
    }
  }, []);

  const handleProjectSelect = async (value) => {
    try {
      setLoading(true);
      formik.setFieldValue("issueProject", value);
      // get all teams assign to the project
      console.log(value);

      // get all teams assign to the project
      const response = await fetch(
        `${backendUrl}/api/warehouse/project-teams/${value}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      console.log(response);
      if (!response.ok) {
        let result = await response.json();
        console.log(result);
        throw new Error(result.error);
      }
      const data = await response.json();
      console.log(data);
      setMentionError(false);
      setProjectTeams(() =>
        data.teams.map((staff, index) => {
          return { title: staff.name, value: staff.email, key: index };
        })
      );
    } catch (error) {
      setProjectTeams([]);
      setMentionError(true);
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      issueProject: selectedId?.Bar_Code,
      issueType: "",
      issueTitle: "",
      teamMember: [],
      issueDescription: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log(values);
        setLoading(true);
        let response = await fetch(
          `${backendUrl}/api/warehouse/create-ticket-to-fexspace`,
          {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(values),
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          response = await response.json();
          throw new Error(response.message);
        }
        const res = await response.json();
        console.log(res);
        if (res.success) {
          toast.success("Issue created successfully");
          setShow(false);
          resetForm();
          // save issueType to localStorage
          if (!savedIssueType.includes(values.issueType)) {
            savedIssueType.push(values.issueType);
            localStorage.setItem(
              "valueDatas",
              JSON.stringify({
                ...JSON.parse(localStorage.getItem("valueDatas")),
                issueType: [...savedIssueType, values.issueType],
              })
            );
            setSaveIssueType(savedIssueType);
          }
        } else {
          toast.error("Failed to create issue");
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
    validationSchema: yup.object().shape({
      issueProject: yup.string().required("required"),
      issueType: yup.string().required("required"),
      issueTitle: yup.string().required("required"),
      issueDescription: yup.string().required("required"),
    }),
  });

  useEffect(() => {
    // if (selectedAssetRef.current) {
    //   console.log(selectedAssetRef.current);
    //   selectedAssetRef.current.setValue(selectedId.Bar_Code);
    // }
    formik.setFieldValue("teamMember", selectedTeams);
  }, [selectedTeams]);
  return (
    <Modal
      onHide={() => setShow(false)}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Create Issue</h6>
              <div role="button" className="text-lg">
                <CloseIcon onClick={() => setShow(false)} />
              </div>
            </div>
            <p className="text-muted">
              Create a new ticket and assign asset to it
            </p>
          </div>
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            className="row new-item-form flex-column border rounded m-4"
            autoComplete="off"
          >
            <div className="w-auto col-md-6 p-4">
              <Form.Group className="col-12 mb-3 pb-2">
                <Form.Label className="mb-1">Project</Form.Label>
                <Select
                  placeholder="Select an Asset"
                  name="issueProject"
                  options={assets.map((el) => ({
                    label: el?.Item_Name,
                    value: el?.Bar_Code, //
                  }))}
                  defaultValue={{
                    label: selectedId?.Item_Name, // Ensure this matches an `options` object
                    value: selectedId?.Bar_Code,
                  }}
                  onChange={({ value }) => handleProjectSelect(value)}
                  onBlur={formik.handleBlur}
                />

                {formik.touched.issueProject && formik.errors.issueProject && (
                  <div className="text-danger small mt-1">
                    {formik.errors.issueProject}
                  </div>
                )}
              </Form.Group>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Issue Type</Form.Label>

                <CreatableSelect
                  className=""
                  placeholder="Enter issue type"
                  name="issueType"
                  options={savedIssueType.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  value={
                    formik.values.issueType
                      ? {
                        label: formik.values.issueType,
                        value: formik.values.issueType,
                      }
                      : null
                  }
                  onChange={({ value }) =>
                    formik.setFieldValue("issueType", value ? value : "")
                  }
                  onBlur={() => formik.setFieldTouched("issueType", true)}
                />
                {formik.touched.issueType && formik.errors.issueType && (
                  <div className="text-danger small mt-1">
                    {formik.errors.issueType}
                  </div>
                )}
              </Form.Group>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Issue Title</Form.Label>
                <InputGroup>
                  <Form.Control
                    className=""
                    type="text"
                    placeholder="Enter Issue title"
                    name="issueTitle"
                    value={formik.values.issueTitle}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </InputGroup>
                {formik.touched.issueTitle && formik.errors.issueTitle && (
                  <div className="text-danger small mt-1">
                    {formik.errors.issueTitle}
                  </div>
                )}
              </Form.Group>


              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Issue Description</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  as="textarea"
                  rows={5}
                  placeholder="Enter Issue description"
                  name="issueDescription"
                  value={formik.values.issueDescription}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                {formik.touched.issueDescription &&
                  formik.errors.issueDescription && (
                    <div className="text-danger small mt-1">
                      {formik.errors.issueDescription}
                    </div>
                  )}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Assign Members</Form.Label>
                <InputGroup>
                  <MentionInput
                    data={projectTeams}
                    setValue={setSelectedTeams}
                    value={selectedTeams}
                    isLoading={loading}
                    error={mentionError}
                  />
                </InputGroup>
                {formik.touched.dueDate && formik.errors.dueDate && (
                  <div className="text-danger small mt-1">
                    {formik.errors.dueDate}
                  </div>
                )}
              </Form.Group>
              <Button type="submit" variant="primary" disabled={loading}>
                {loading ? "Please Wait... " : "Submit"}
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>

      <ModalLoader />
    </Modal>
  );
};

export default CreateTicket;
