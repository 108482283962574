import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTaxOptions } from "../../utils/hooks";

export default function TaxesNav(props) {
  const taxOptions = useTaxOptions();

  return (
    <div className="dashboard-tabs innerpage-tabs tier-1 px-4">
      <ul className="nav nav-tabs">
        {taxOptions.filter(tax => tax.value !== 'None').map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.value}>
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
