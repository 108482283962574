import { Modal, Form, Button, InputGroup, } from "react-bootstrap";
import * as yup from "yup";
import { services } from "../config";
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { Fragment, useState } from "react";
import { confirmable, createConfirmation } from "../utils/confirm";
import Select from "react-select";
import { useEffect } from "react";
import { useBackendUrl } from "../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
import ConfirmDialog from "./ConfirmDialogue";
import {
  CashSelectIcon,
  ChequeSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  DropdownCloseIcon,
} from "./Icons";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { useAuth } from "../hooks/useAuth";
import { useQuery, useQueryClient } from "react-query";
import { queryActions } from "../utils/reactQueryActions";
import { cloneDeep, isEmpty } from "lodash";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import AddAccounts from "./modals/AddAccounts";
import { Popover } from "react-tiny-popover";

function PaymentAccount({
  proceed,
  show,
  payFromLabel = "From",
  payByLabel = "By",
  hasRemark = false,
  Trans_ID,
  amountRequired = 0,
  disableAccountSelect = false,
  hasCorrespondingAccount = false
}) {
  const backendUrl = useBackendUrl();
  const [showCreateNewAccountModal, setShowCreateNewAccountModal] = useState(
    false
  ); const [activeIndex, setActiveIndex] = useState(

  );


  const paymentMethod = [
    {
      icon: <CreditSelectIcon />,
      label: "Imprest",
      value: "Imprest",
    },
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },

    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },

    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
  ];

  const cashOptions = [
    {
      label: "Cash On Hand",
      value: "Cash on hand",
    },
  ];

  const initialValues = {
    accounts: [{ By: "", From: "", amount: amountRequired, showJournalPopover: false }],
    remark: "",
  };

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let [response, response2] = await Promise.all([
      fetch(`${backendUrl}/api/invoice/set-up`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }),
      ...(Trans_ID ? [fetch(`${backendUrl}/api/transaction/expense-from/${Trans_ID}`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      })] : []),
    ]);

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    if (Trans_ID) {
      if (!response2.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const expenseFromData = await response2.json();

      if (expenseFromData?.data) {
        if (!isEmpty(expenseFromData?.data?.expensedFrom)) {
          formik.setFieldValue(
            "accounts",
            expenseFromData?.data?.expensedFrom.map((el, index) => ({
              ...el,
              ...(index == 0 ? { amount: amountRequired } : {}),
            }))
          );
        }
      }
    }



    const { data } = await response.json();

    data.banks = data?.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    data.branch = [
      {
        value: "General",
        label: "General",
      },
    ];

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    return data;
  };

  const {
    data = { banks: [], vendors: [], branch: [], imprest: [] },
    isLoading,
    refetch,
  } = useQuery([queryActions.INVOICE_SETUP], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const schema = yup.object().shape({});

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      values = cloneDeep(values);
      let hasError = false;
      for (const [i, v] of values.accounts.entries()) {
        if (!v.amount) {
          formik.setFieldError(`accounts[${i}].amount`, "Required");
          hasError = true;
        }

        if (!v.By) {
          formik.setFieldError(`accounts[${i}].By`, "Required");
          hasError = true;
        }

        if (!v.From) {
          formik.setFieldError(`accounts[${i}].From`, "Required");
          hasError = true;
        }
      }
      if (hasError) {
        return;
      }
      proceed(values);
    },
  });

  const canClose = async () => {
    proceed(false);
  };

  const resolveFromOptions = (PaymentType) => {
    //  console.log(PaymentType, data.banks);
    return PaymentType === "Imprest"
      ? data.imprest
      : ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(
        PaymentType
      )
        ? data.banks
        : cashOptions;
  };

  const handleSelectedAccount = () => {

  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => canClose()}
        centered={true}
        animation={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">
            Select Account{" "}
            <button
              title="Refresh"
              onClick={() => refetch()}
              className="btn text-primary"
            >
              <CachedIcon />
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <FieldArray
                name="accounts"
                render={(arrayHelpers) => (
                  <>
                    {formik.values.accounts.map((el, index) => (
                      <Fragment key={index}>
                        <div className="d-flex justify-content-between">
                          <div />
                          <Button
                            title="Remove"
                            variant="outline-danger"
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            ✖
                          </Button>
                        </div>
                        <Form.Group className="mb-3">
                          <Form.Label>{payByLabel}</Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            menuPlacement="top"
                            placeholder="Choose method"
                            isSearchable={false}
                            value={paymentMethod.find(
                              (el) =>
                                el.value === formik.values.accounts[index].By
                            )}
                            options={paymentMethod}
                            onChange={({ value }) => {
                              formik.setFieldValue(
                                `accounts[${index}].By`,
                                value
                              );
                              formik.setFieldValue(`accounts[${index}].From`, "");
                            }}
                            getOptionLabel={(el) => (
                              <div className="label-with-icon d-flex gap-2 align-items-center">
                                <span>{el.icon}</span>{" "}
                                <span className="fw-5">{el.label}</span>
                              </div>
                            )}
                            isDisabled={disableAccountSelect}
                          />
                          <ErrorMessage
                            component="span"
                            className="text-danger mb-2"
                            name={`accounts[${index}].By`}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold ">
                            {payFromLabel}
                          </Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            value={resolveFromOptions(
                              formik.values.accounts[index].By
                            ).find(
                              (el) =>
                                el.value === formik.values.accounts[index].From
                            )}
                            options={resolveFromOptions(
                              formik.values.accounts[index].By
                            )}
                            onChange={({ value }) =>
                              formik.setFieldValue(
                                `accounts[${index}].From`,
                                value
                              )
                            }
                            key={formik.values.accounts[index].By}
                            isDisabled={disableAccountSelect}
                          />
                          <ErrorMessage
                            component="span"
                            className="text-danger mb-2"
                            name={`accounts[${index}].From`}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold ">Amount</Form.Label>
                          <CurrencyCustomInput
                            name={`accounts[${index}].amount`}
                            value={formik.values.accounts[index].amount}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                          />
                          <ErrorMessage
                            component="span"
                            className="text-danger mb-2"
                            name={`accounts[${index}].amount`}
                          />
                        </Form.Group>


                        {hasCorrespondingAccount && <Form.Group className="mb-3">
                          <Form.Label className="fw-bold ">Account Postings Posting</Form.Label>
                          <div className="d-flex gap-2 align-items-center">
                            <InputGroup hasValidation>
                              <Form.Control
                                placeholder="Select Account"
                                value={`${formik.values.accounts[index]?.correspondingAccountID || "..."} / ${formik.values.accounts[index]?.correspondingAccountDescription || "..."} / ${formik.values.accounts[index]?.correspondingAccountType || "..."}`}
                                onChange={() => { }}
                                readOnly
                              />

                              <Popover
                                isOpen={formik.values.accounts[index]?.showJournalPopover}
                                onClickOutside={() => {
                                  // false
                                  formik.setFieldValue(`accounts[${index}].showJournalPopover`, false)
                                }}
                                content={() => (
                                  <ExpensesSelector
                                    handleSelectedExpense={(account) => {

                                      formik.setFieldValue(`accounts[${index}].correspondingAccountID`, account.AccountID)
                                      formik.setFieldValue(`accounts[${index}].correspondingAccountType`, account.Type)
                                      formik.setFieldValue(`accounts[${index}].correspondingAccountDescription`, account.Description)
                                      formik.setFieldValue(`accounts[${index}].showJournalPopover`, false)

                                    }}
                                    usage={"chart of accounts"}
                                    Type=""
                                  />
                                )}
                                position="bottom"
                              >
                                <InputGroup.Text
                                  onClick={() => {
                                    // true
                                    formik.setFieldValue(`accounts[${index}].showJournalPopover`, true)
                                  }}
                                >
                                  <MagnifyIcon />
                                </InputGroup.Text>
                              </Popover>
                            </InputGroup>
                            <Button size="sm" variant="outline-primary"
                              onClick={() => {
                                setShowCreateNewAccountModal(true)
                                setActiveIndex(index)
                              }}
                              className="text-nowrap">+ Create New</Button>
                          </div>
                          <ErrorMessage
                            component="span"
                            className="text-danger mb-2"
                            name={`accounts[${index}].amount`}
                          />
                        </Form.Group>}

                        <hr />
                      </Fragment>
                    ))}

                    <div className="d-flex justify-content-start px-4 mb-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            By: "",
                            From: "",
                            amount: 0,
                            showJournalPopover: false
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </>
                )}
              />



              {hasRemark && (
                <Form.Group className="mb-3">
                  <Form.Label className="fw-bold">Remark</Form.Label>
                  <Form.Control
                    placeholder="Enter Remark"
                    name="remark"
                    value={formik.values.remark}
                    onChange={formik.handleChange}
                    as={"textarea"}
                    rows={2}
                  />
                </Form.Group>
              )}

              <Button variant="primary" className="w-100 p-2" type="submit">
                {"Continue"}
              </Button>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>

      {showCreateNewAccountModal && (
        <AddAccounts
          show={showCreateNewAccountModal}
          onHide={() => setShowCreateNewAccountModal(false)}
          refetch={() => refetch()}
          // type={'corresponding DEPRECIATION'}
          // usage="imprest"
          accountCreated={handleSelectedAccount}
        />
      )}
    </>
  );
}

export default function PaymentAccountMultiDialog(props) {
  return createConfirmation(confirmable(PaymentAccount))({ ...props });
}
