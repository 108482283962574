import React, { useEffect, useState } from "react";
import sole from "./assets/sole.png";
import company from "./assets/company.png";
import others from "./assets/others.png";
import trade from "./assets/trade.png";
import trust from "./assets/trust.png";
import partner from "./assets/partner.png";
import charity from "./assets/charity.png";

function BusinessType({ saveBusinessType, next }) {
  const [selected, setSelected] = useState("");
  const businessType = [
    {
      icon: sole,
      title: "Sole trader",
      value: "sole-trader",
    },
    {
      icon: company,
      title: "Company",
      value: "company",
    },
    {
      icon: trade,
      title: "Trade or coperative",
      value: "trade",
    },
    {
      icon: trust,
      title: "Trust",
      value: "trust",
    },
    {
      icon: partner,
      title: "Partnership",
      value: "partnership",
    },
    {
      icon: charity,
      title: "Charity or Association",
      value: "charity",
    },
    {
      icon: others,
      title: "Others",
      value: "others",
    },
  ];
  const handleSelected = (value) => {
    setSelected(value);
  };

  const submit = async () => {
    console.log("submitting business type...", selected);
    next();
  };
  useEffect(() => {
    saveBusinessType(submit);
  }, [selected]);
  return (
    <div className="contents">
      <h1>What kind of business is this?</h1>
      <p>
        Tell us about your business structure, we'll use this to help categorise
        your transaction
      </p>
      <div className="options">
        {businessType.map((item, index) => (
          <div className="card" onClick={() => handleSelected(item.value)}>
            <div className="left">
              <span className="icon">
                <img src={item.icon} alt={item.title} />
              </span>
              <span className="title">{item.title}</span>
            </div>
            <span className="selection">
              {selected === item.value && (
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="M480 128c0 8.188-3.125 16.38-9.375 22.62l-256 256C208.4 412.9 200.2 416 192 416s-16.38-3.125-22.62-9.375l-128-128C35.13 272.4 32 264.2 32 256c0-18.28 14.95-32 32-32c8.188 0 16.38 3.125 22.62 9.375L192 338.8l233.4-233.4C431.6 99.13 439.8 96 448 96C465.1 96 480 109.7 480 128z" />
                </svg>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BusinessType;
