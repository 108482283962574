import "../../assets/scss/signupProcess.scss";
import Industry from "./Industry";
import { useEffect, useState } from "react";
import BusinessType from "./BusinessType";
import CompanySettings from "./CompanySettings";
import Reciept from "./Reciept";
import Submitting from "./Submitting";
import { backendApis } from "../../config";
import { toast } from "react-toastify";
import Department from "./Department";
import Staffs from "./Staffs";
import CompanyInformationSettings from "./CompanyInformationSettings";
import LogUserIn from "./LogUserIn";
import FinalPage from "./FinalPage";
const backendUrl = process.env.REACT_APP_EB_BACKEND_URL;

function SignupProcess() {
  const PAGENUM = 8; // Number of pages
  const PAGEWIDTH = 74; // size of each page
  const FULLPAGEWIDTH = PAGEWIDTH * PAGENUM;
  const HRSLIDINGWIDTH = 100 / PAGENUM; // max width divided by number of pages

  const [slider, setSlider] = useState({
    hrValue: HRSLIDINGWIDTH,
    sliderValue: 0,
  }); // sliding of the page
  let [once, setOnce] = useState(true); // to execute the login function once

  const [userData, setUserData] = useState({}); // user's data to login
  const [userLogin, setUserLogin] = useState(null); // login function
  const [companyInfo, setCompanyInfo] = useState(null); // company information submittion function
  const [settingsFunction, setSettingsFunction] = useState(null); // company settings submittion function
  const [industryFunction, setIndustryFunction] = useState(null); // industry information submittion function
  const [businessType, setBusinessType] = useState(null); // business type submittion function
  const [recieptType, setRecieptType] = useState(null); // reciept submittion function
  const [departmentFunction, setDepartmentFunction] = useState(null); // department submittion function
  const [staffFunction, setStaffFunction] = useState(null); // staff submittion function
  const [currentPage, setCurrentPage] = useState(0); // current page submittion function

  const saveUserLoggedIn = (func) => {
    setUserLogin(() => func); // save the child's function
  };

  const saveCompanySettings = async (func) => {
    setSettingsFunction(() => func); // Save the child function
  };

  const saveCompanyInfo = (func) => {
    setCompanyInfo(() => func); // save the child's function
  };

  const saveIndustry = (func) => {
    setIndustryFunction(() => func); // Save the child's function
  };

  const saveBusinessType = (func) => {
    setBusinessType(() => func); // save the child's function
  };

  const saveRecieptType = (func) => {
    setRecieptType(() => func); // save the child's function
  };

  const saveDepartment = (func) => {
    setDepartmentFunction(() => func); // save the child's function
  };

  const saveStaff = (func) => {
    setStaffFunction(() => func); // save the child's function
  };

  const functions = [
    industryFunction,
    businessType,
    settingsFunction,
    recieptType,
    companyInfo,
    departmentFunction,
    staffFunction,
  ]; // array of submittion functions

  const handleSlider = async (direction) => {
    if (once) {
      userLogin(); // Invoke the login function to log the user in
      setOnce(false);
    }

    if (direction === "next") {
      let submit = functions[currentPage];
      if (submit) {
        if (currentPage === 6 || currentPage === 4) {
          await submit.requestSubmit();
        } else {
          await submit();
        }
      }
    }
    if (direction === "prev") {
      if (currentPage === 0) return;
      setSlider((prev) => {
        const hrValue = prev.hrValue - HRSLIDINGWIDTH;
        const sliderValue = prev.sliderValue + PAGEWIDTH;
        if (hrValue < HRSLIDINGWIDTH) {
          setCurrentPage(PAGENUM - 1);
          return {
            hrValue: 100,
            sliderValue: PAGEWIDTH - FULLPAGEWIDTH,
          };
        }
        setCurrentPage((prev) => --prev);
        return { hrValue, sliderValue };
      });
    }
  };

  const nextPage = () => {
    setSlider((prev) => {
      const hrValue = prev.hrValue + HRSLIDINGWIDTH;
      const sliderValue = prev.sliderValue - PAGEWIDTH;
      if (hrValue > 101) {
        setCurrentPage(0);
        return { hrValue: HRSLIDINGWIDTH, sliderValue: 0 };
      }
      setCurrentPage((prev) => ++prev);
      return { hrValue, sliderValue };
    });
  };

  useEffect(() => {
    let fetchedUserData = {};
    (async () => {
      try {
        let id = localStorage.getItem("newUserId");

        // get the signup data from EB server
        let response = await fetch(`${backendUrl}/api/users/get-first-staff`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ id }),
        });

        if (!response.ok) {
          response = await response.json();
          throw new Error(response.message);
        }
        response = await response.json();
        if (response.success) {
          fetchedUserData = response.data;
          setUserData(response.data);
          console.log("fetching user successful: ", response);
        }

        // creating the first user in their db
        response = await fetch(
          `${backendApis[0].url}/api/users/create-first-user`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(fetchedUserData),
            credentials: "include",
          }
        );

        if (!response.ok) {
          response = await response.json();
          throw new Error(response.message);
        }
        response = await response.json();
        if (response.success) {
          console.log("creation successful: ", response);
        }
      } catch (error) {
        console.error("Error creating first user: ", error.message);
        if (error.message !== "Email already exists") {
          toast.error("Error occurred during signup process");
        }
      }
    })();
  }, []); // handle getting user from EB, saving the user in the database and logging them in.

  return (
    <div className="pageContainer">
      <section className="main">
        <div className="progressBar">
          <hr className="base" />
          <hr className="progress" style={{ width: slider.hrValue + "%" }} />
        </div>
        <section className="slider">
          <div
            className="slideContainer"
            style={{
              left: slider.sliderValue + "vw",
            }}
          >
            {/* <BusinessName setData={setData}/> */}
            <LogUserIn
              userData={userData}
              saveUserLoggedIn={saveUserLoggedIn}
            />
            <Industry saveIndustry={saveIndustry} next={nextPage} />
            <BusinessType saveBusinessType={saveBusinessType} next={nextPage} />
            <CompanySettings submitForm={saveCompanySettings} next={nextPage} />
            <Reciept saveReciept={saveRecieptType} next={nextPage} />
            <CompanyInformationSettings
              setCompanyInfo={saveCompanyInfo}
              next={nextPage}
            />
            <Department
              saveDepartment={saveDepartment}
              email={userData.email}
              next={nextPage}
            />
            <Staffs next={nextPage} saveStaff={saveStaff} />
            <FinalPage />
            {/* <Submitting /> */}
          </div>
        </section>
        <hr id="divider" />
        <div className="buttons">
          <div className="navigation">
            <>
              <button
                onClick={() => handleSlider("prev")}
                type="submit"
                className="outline"
              >
                Prev
              </button>
              <button onClick={() => handleSlider("next")} type="submit">
                Save & Next
              </button>
            </>
          </div>
          {/* {setUpComplete && ( */}
          <div>
            <button
              type="button"
              className="primary"
              // disabled={isLoading}
              onClick={() => (currentPage === 7 ? "" : nextPage())}
            >
              {currentPage >= 6 ? "Finish" : "Skip"}
            </button>
          </div>
          {/* )} */}
        </div>
      </section>
    </div>
  );
}

export default SignupProcess;
